const generateObjectHtml = require('./generate_object_html');

module.exports = function createObject(callbackId, optionsParam, completion) {
  const options = optionsParam || {};

  const html = generateObjectHtml(callbackId, options);
  const doc = options.doc || global.document;

  doc.body.insertAdjacentHTML('beforeend', html);
  const object = doc.body.querySelector(`#${callbackId}_obj`);

  completion(undefined, object);
};

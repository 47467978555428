// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-void, prefer-rest-params,  no-shadow */

const AnalyticsHelper = require('../helpers/analytics');
const curryCallAsync = require('./curry_call_async.js');
const logging = require('../helpers/log')('AutoUpdater');
const meta = require('./meta.js');
const comparePluginVersions = require('compare-versions');

// TODO: fix this super duper evil circular dependency between this project and webrtc-js via global
// scope

const OT = global.OT;

const OTHelpers = require('../common-js-helpers/OTHelpers.js');
const PluginProxies = require('./plugin_proxies.js');

const analytics = new AnalyticsHelper();

let autoUpdaterController;

const AutoUpdater = function AutoUpdater() {
  let plugin;

  const getControllerCurry = function getControllerFirstCurry(fn) {
    return () => {
      if (plugin) {
        fn(void 0, arguments);
        return;
      }

      PluginProxies.create({
        mimeType: meta.getInstallerMimeType(),
        isVisible: false,
        windowless: false,
      }, (err, p) => {
        plugin = p;

        if (err) {
          logging.error(`Error while loading the AutoUpdater: ${err}`);
          return undefined;
        }

        return fn(...arguments);
      });
    };
  };

  // Returns true if the version of the plugin installed on this computer
  // matches the one expected by this version of OTPlugin.
  this.hasRequiredVersion = () => (
    comparePluginVersions(meta.version(), meta.installedVersion()) !== 1 &&
    comparePluginVersions(meta.installedVersion(), meta.maxPluginVersion()) === -1
  );

  this.autoUpdate = getControllerCurry(() => {
    let modal = OT.Dialogs.Plugin.updateInProgress();

    const payload = {
      ieVersion: OTHelpers.env.version,
      pluginOldVersion: meta.installedVersion(),
      pluginNewVersion: meta.version,
    };

    const success = curryCallAsync(() => {
      analytics.logEvent({
        action: 'OTPluginAutoUpdate',
        variation: 'Success',
        partnerId: OT.APIKEY,
        payload: JSON.stringify(payload),
      });

      plugin.destroy();

      modal.close();
      OT.Dialogs.Plugin.updateComplete().on('reload', () => {
        window.location.reload();
      });
    });

    const error = curryCallAsync((errorCode, errorMessage, systemErrorCode) => {
      payload.errorCode = errorCode;
      payload.systemErrorCode = systemErrorCode;

      analytics.logEvent({
        action: 'OTPluginAutoUpdate',
        variation: 'Failure',
        partnerId: OT.APIKEY,
        payload: JSON.stringify(payload),
      });

      plugin.destroy();

      modal.close();

      const updateMessage = `${errorMessage} (${errorCode}). Please restart `
        + 'your browser and try again.';

      modal = OT.Dialogs.Plugin.updateComplete(updateMessage).on('reload', modal.close());

      logging.error(
        `autoUpdate failed: ${errorMessage} (${errorCode}). Please restart your`
          + 'browser and try again.'
      );
      // TODO log client event
    });

    const progress = curryCallAsync((progress) => {
      modal.setUpdateProgress(progress.toFixed());
    });

    plugin._.updatePlugin(meta.pathToInstaller(), success, error, progress);
  });

  this.destroy = () => {
    if (plugin) {
      plugin.destroy();
    }
  };

  // Refresh the plugin list so that we'll hopefully detect newer versions
  if (navigator.plugins) {
    navigator.plugins.refresh(false);
  }
};

AutoUpdater.get = function get(completion) {
  if (!autoUpdaterController) {
    if (!this.isinstalled()) {
      completion.call(null, 'Plugin was not installed');
      return;
    }

    autoUpdaterController = new AutoUpdater();
  }

  completion.call(null, void 0, autoUpdaterController);
};

AutoUpdater.isinstalled = () => meta.getInstallerMimeType() !== null && !meta.hasBrokenUpdater();

AutoUpdater.installedVersion = () => meta.installedVersion();

module.exports = AutoUpdater;

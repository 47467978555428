const env = require('../../helpers/env');
const haveDisplayMedia = require('../../helpers/haveGetDisplayMedia').default;

const isSupportedInThisBrowser = () =>
  // Note: getDisplayMedia did not work in version < 17
  // when we do support Edge, the following should be changed to:
  // if (env.name === 'Edge' && env.version < 17) {

  // getDisplayMedia did not work properly in Edge 16
  env.name !== 'Edge' && haveDisplayMedia;

module.exports = {
  isSupportedInThisBrowser: isSupportedInThisBrowser(),
  autoRegisters: true,
  extensionRequired: false,
  getConstraintsShowsPermissionUI: false,
  sources: {
    screen: true,
    application: false,
    window: env.name !== 'Safari',
    browser: false,
  },
  register() {
    return {
      isInstalled(callback) {
        callback(true);
      },
      getConstraints(source, constraints, callback) {
        callback(undefined, {
          video: true,
        });
      },
    };
  },
};

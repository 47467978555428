// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-shadow */


const isReady = require('./readiness.js').isReady;
const PluginProxies = require('./plugin_proxies.js');
const registerReadyListner = require('./readiness.js').listen;

// Exposes a enumerateDevices method and emits a devicechange event
//
// http://w3c.github.io/mediacapture-main/#idl-def-MediaDevices
//
module.exports = function MediaDevices() {
  const Proto = function MediaDevices() {};
  const mediaDevices = new Proto();

  mediaDevices.enumerateDevices = function enumerateDevices(completion) {
    registerReadyListner((error) => {
      if (error) {
        completion(error);
      } else {
        PluginProxies.mediaCapturer.enumerateDevices(completion);
      }
    });
  };

  mediaDevices.addListener = function addListener(fn, context) {
    registerReadyListner((error) => {
      if (error) {
        // No error message here, ready failing would have
        // created a bunch elsewhere
        return;
      }

      PluginProxies.mediaCapturer.on('devicesChanged', fn, context);
    });
  };

  mediaDevices.removeListener = function removeListener(fn, context) {
    if (isReady()) {
      PluginProxies.mediaCapturer.off('devicesChanged', fn, context);
    }
  };

  return mediaDevices;
};

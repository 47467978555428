const IntervalRunner = require('../ot/interval_runner');

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

module.exports = class Watcher {
  constructor(predicate, callback, { frequency = 60 } = {}) {
    let currentValue = cloneDeep(predicate());

    const checkValues = () => {
      const newValue = predicate();
      if (!isEqual(newValue, currentValue)) {
        callback(newValue, currentValue);
      }
      currentValue = newValue;
    };

    Object.defineProperty(this, 'intervalRunner', {
      value: new IntervalRunner(checkValues, frequency),
    });
  }
  start() {
    this.intervalRunner.start();
  }
  stop() {
    this.intervalRunner.stop();
  }
};

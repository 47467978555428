/* eslint-disable global-require */
const Analytics = require('../helpers/analytics');

module.exports = ({
  analytics = new Analytics(),
  meta = require('./meta.js'),
  readiness = require('./readiness.js'),
}) => {
  readiness.listen((error) => {
    // This fires if the plugin is not installed with an installedVersion of -1
    // we don't want to log every single time a page is loaded and the person doesn't
    // have the plugin installed
    if (meta.installedVersion() !== -1) {
      const payload = {
        version: meta.installedVersion(),
      };

      if (error) {
        payload.message = error;
      }

      analytics.logEvent({
        action: 'OTPlugin-Load',
        variation: error ? 'Failure' : 'Success',
        payload,
      });
    }
  });
};

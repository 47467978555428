const logging = require('../helpers/log')('deprecation');

module.exports = function setDeprecatedProperty(object, key, {
  value: defaultValue,
  name = `${key}`,
  getWarning = '',
  setWarning = '',
  canSet = false,
  warnOnSet = false,
} = {}) {
  let value = defaultValue;
  let hasWarnedOnGet = false;
  let hasWarnedOnSet = !warnOnSet;

  const propertyDescriptor = {
    get() {
      if (!hasWarnedOnGet) {
        hasWarnedOnGet = true;
        logging.warn(`${name} is deprecated, and will be removed in the future. ${getWarning}`.trim());
      }
      return value;
    },
    set(newValue) {
      if (canSet) {
        value = newValue;
      }
      if (!hasWarnedOnSet) {
        hasWarnedOnSet = true;
        logging.warn(`${name} is deprecated, and will be removed in the future. ${setWarning}`.trim());
      }
    },
  };
  Object.defineProperty(object, key, propertyDescriptor);
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require, no-underscore-dangle, no-restricted-syntax */
/* eslint-disable no-prototype-builtins, no-param-reassign, prefer-rest-params, no-shadow */

const Analytics = require('../helpers/analytics.js');
const OTHelpers = require('../common-js-helpers/OTHelpers.js');
const startupAnalyticsLogger = require('./startupAnalyticsLogger.js');
const promisify = require('../helpers/promisify');

const analytics = new Analytics();
const OTPlugin = {};
module.exports = OTPlugin;
global.OTPlugin = OTPlugin;

// Establish the environment that we're running in
OTPlugin.isSupported = function () {
  return (
    OTHelpers.env.name === 'IE' &&
    OTHelpers.env.version >= 9
  );
};

// If this client isn't supported we still make sure that OTPlugin is defined
// and the basic API (isSupported() and isInstalled()) is created.
// TODO: ^^^ Is this really needed? It would be nice to not monkey around with this early exit
// thingy.
if (!OTPlugin.isSupported()) {
  OTPlugin.isInstalled = function isInstalled() { return false; };
} else {
  const AutoUpdater = require('./auto_updater.js');
  const logging = require('../helpers/log')('Plugin');
  const MediaConstraints = require('./media_constraints.js');
  const MediaDevices = require('./media_devices.js');
  const MediaStream = require('./media_stream.js');
  const meta = require('./meta.js');
  const createPeerConnection = require('./peer_connection/create_peer_connection.js');
  const PluginProxies = require('./plugin_proxies.js');
  const readiness = require('./readiness.js');
  const settings = require('./settings.js');
  const RTCIceCandidate = require('./rtc/rtc_ice_candidate.js');
  const RTCSessionDescription = require('./rtc/rtc_session_description.js');

  // TODO: Can we remove this? It's only here for possible external use.
  OTPlugin.isReady = readiness.isReady;

  OTPlugin.meta = meta;

  OTPlugin.isInstalled = function isInstalled() {
    if (!OTPlugin.isSupported()) {
      return false;
    }

    return AutoUpdater.isinstalled();
  };

  OTPlugin.settings = settings;

  OTPlugin.version = meta.version;
  OTPlugin.installedVersion = meta.installedVersion;

  // Returns a URI to the OTPlugin installer that is paired with
  // this version of OTPlugin.js.
  OTPlugin.pathToInstaller = meta.pathToInstaller;

  // Trigger +callback+ when the plugin is ready
  //
  // Most of the public API cannot be called until
  // the plugin is ready.
  OTPlugin.ready = function ready(callback) {
    readiness.listen(callback.bind(OTPlugin));
  };

  OTPlugin.getUserMedia = async function _getUserMedia(userConstraints) {
    const mediaConstraints = new MediaConstraints(userConstraints);
    const plugin = await promisify(::PluginProxies.createMediaPeer)({});
    return new Promise((resolve, reject) =>
      plugin._.getUserMedia(
        mediaConstraints.toHash(),
        settings.usePreviousDeviceSelection,
        streamJson => resolve(MediaStream.fromJson(streamJson, plugin)),
        reject
      )
    );
  };

  OTPlugin.enumerateDevices = function (completion) {
    readiness.listen((error) => {
      if (error) {
        completion(error);
      } else {
        PluginProxies.mediaCapturer.enumerateDevices(completion);
      }
    });
  };

  // Equivalent to: var pc = new window.RTCPeerConnection(iceServers, options);
  //
  // Except that it is async and takes a completion handler
  OTPlugin.initPeerConnection = async function initPeerConnection(
    rtcConfiguration,
    options,
    localStream
  ) {
    let plugin = null;

    // @fixme this is nasty and brittle. We need some way to use the same Object
    // for the PeerConnection that was used for the getUserMedia call (in the case
    // of publishers). We don't really have a way of implicitly associating them though.
    // Hence, publishers will have to pass through their localStream (if they have one)
    // and we will look up the original Object and use that. Otherwise we generate
    // a new one.
    if (localStream && localStream._.plugin) {
      plugin = localStream._.plugin;
    } else {
      plugin = await new Promise((resolve, reject) => {
        PluginProxies.createMediaPeer({}, (err, plug) => {
          if (err) {
            reject(err);
          } else {
            resolve(plug);
          }
        });
      });
    }

    logging.debug(`Got PeerConnection for ${plugin.id}`);

    const peerConnection = await createPeerConnection(rtcConfiguration, options, plugin);

    plugin._.getProxyInfo((proxyInfo) => {
      peerConnection.proxyInfo = proxyInfo;
    });

    return peerConnection;
  };

  OTPlugin.RTCSessionDescription = RTCSessionDescription;
  OTPlugin.RTCIceCandidate = RTCIceCandidate;

  OTPlugin.mediaDevices = new MediaDevices();

  OTPlugin.supportedVideoCodecs = ['H264', 'VP8', 'VP9'];

  startupAnalyticsLogger({ analytics, meta, readiness });

  module.exports = OTPlugin;
}

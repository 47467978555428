// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-restricted-syntax, no-prototype-builtins */


const RTCStatsReport = function RTCStatsReport(reports) {
  for (const id in reports) {
    if (reports.hasOwnProperty(id)) {
      this[id] = reports[id];
    }
  }
};

RTCStatsReport.prototype.forEach = function (callback, context) {
  for (const id in this) {
    if (this.hasOwnProperty(id)) {
      callback.call(context, this[id]);
    }
  }
};

module.exports = RTCStatsReport;

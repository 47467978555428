// Specialisation for the MediaPeer API surface
module.exports = function makeMediaPeerProxy(mediaPeer) {
  // eslint-disable-next-line no-param-reassign
  mediaPeer.setStream = (stream, completion) => {
    mediaPeer._.setStream(
      stream,
      // is mirrored
      mediaPeer._.msMatchesSelector('.OT_publisher.OT_mirrored object')
    );

    if (completion) {
      // TODO Investigate whether there is a good way to detect
      // when the media is flowing

      // This fires a little too soon.
      setTimeout(completion, 200);
    }

    return mediaPeer;
  };

  return mediaPeer;
};

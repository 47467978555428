const pick = require('lodash/fp/pick');
const assign = require('lodash/fp/assign');
const mapValues = require('lodash/fp/mapValues');

const isObject = require('lodash/isObject');

const frameRateTrackers = {};

function getFrameRate(stat) {
  return Number(stat.framerateMean || stat.googFrameRateSent || stat.googFrameRateReceived ||
    stat.googFrameRateInput || stat.googFrameRateOutput || 0);
}

function getFrames(stat) {
  return Number(stat.framesEncoded || stat.framesDecoded);
}

function calcFrameRate(stat, startTime) {
  if (getFrameRate(stat)) {
    return getFrameRate(stat);
  }

  if (!getFrames(stat)) {
    return undefined;
  }

  let frameRate = 0;

  if (frameRateTrackers[stat.id] !== undefined) {
    frameRate = (
      (getFrames(stat) - frameRateTrackers[stat.id].frames) /
      ((stat.timestamp - frameRateTrackers[stat.id].timestamp) / 1000)
    );
  } else {
    frameRate = getFrames(stat) / ((stat.timestamp - startTime) / 1000);
  }

  frameRateTrackers[stat.id] = {
    frames: getFrames(stat),
    timestamp: stat.timestamp,
  };

  return Math.round(frameRate * 100) / 100;
}

function getLinkedTrack(stat, allStats) {
  if (!allStats) { return {}; }
  const linkedTrack = allStats.filter(x => x.id === stat.mediaTrackId);
  return linkedTrack.length ? linkedTrack[0] : {};
}

const getStatsHelpers = {
  isVideoStat(stat, allStats) {
    const linkedTrack = getLinkedTrack(stat, allStats);
    return stat.mediaType === 'video' || // Chrome / Firefox
      'googFrameWidthReceived' in stat || // Old Chrome
      'googFrameWidthInput' in stat || // Old Chrome
      (stat.type === 'inbound-rtp' && stat.id.indexOf('Video') !== -1) || // Safari
      (stat.type === 'inboundrtp' && linkedTrack.frameWidth !== 0); // Edge
  },
  isVideoSenderStat(stat) {
    // Chrome implementation only has this property for RTP video stat
    return 'googFrameWidthSent' in stat ||
      stat.mediaType === 'video' ||
      (stat.id !== undefined && stat.id.toLowerCase().indexOf('video') !== -1);
  },
  isAudioStat(stat, allStats) {
    const linkedTrack = getLinkedTrack(stat, allStats);
    return stat.mediaType === 'audio' || // Chrome / Firefox
      'audioInputLevel' in stat || // Old Chrome
      'audioOutputLevel' in stat || // Old Chrome
      (stat.type === 'inbound-rtp' && stat.id.indexOf('Audio') !== -1) || // Safari
      (stat.type === 'inboundrtp' && linkedTrack.frameWidth === 0); // Edge;
  },
  isInboundStat(stat) {
    return 'bytesReceived' in stat || 'packetsReceived' in stat;
  },
  isOutboundStat(stat) {
    return 'bytesSent' in stat || 'packetsSent' in stat;
  },
  isVideoTrackStat(stat) {
    return stat.type === 'track' && (
      stat.frameHeight > 0 ||
      stat.frameWidth > 0 ||
      stat.framesCorrupted > 0 ||
      stat.framesDecoded > 0 ||
      stat.framesPerSecond > 0 ||
      stat.framesSent > 0 ||
      stat.framesReceived > 0
    );
  },
  parseStatCategory(stat) {
    const statCategory = {
      packetsLost: 0,
      packetsReceived: 0,
      bytesReceived: 0,
      // frameRate is only set for video stat
    };

    if ('packetsReceived' in stat) {
      statCategory.packetsReceived = parseInt(stat.packetsReceived, 10);
    }
    if ('packetsLost' in stat) {
      statCategory.packetsLost = parseInt(stat.packetsLost, 10);
    }
    if ('bytesReceived' in stat) {
      statCategory.bytesReceived = parseInt(stat.bytesReceived, 10);
    }

    if (this.isVideoStat(stat)) {
      if ('framerateMean' in stat) {
        statCategory.frameRate = Number(stat.framerateMean);
      } else if ('googFrameRateOutput' in stat) {
        statCategory.frameRate = Number(stat.googFrameRateOutput);
      } else if ('googFrameRateInput' in stat) {
        statCategory.frameRate = Number(stat.googFrameRateInput);
      }
    }

    return statCategory;
  },
  normalizeTimestamp(timestamp, now = Date.now()) {
    if (isObject(timestamp) && 'getTime' in timestamp) {
      // Chrome as of 39 delivers a "kind of Date" object for timestamps
      // we duck check it and get the timestamp
      return timestamp.getTime();
    }

    if (Math.abs((timestamp / (1000 * now)) - 1) < 0.05) {
      // If the timestamp is within 5% of 1000x now, we assume its unit is microseconds and
      // divide by 1000 to correct for this.
      return timestamp / 1000;
    }

    return timestamp;
  },
  normalizeStats(stats, inbound = true, startTime) {
    const video = stats
      .filter(stat => getStatsHelpers.isVideoStat(stat, stats))
      .filter(stat => getStatsHelpers.isInboundStat(stat) === inbound)[0];

    const audio = stats
      .filter(stat => getStatsHelpers.isAudioStat(stat, stats))
      .filter(stat => getStatsHelpers.isInboundStat(stat) === inbound)[0];

    if (!audio && !video) {
      throw new Error('could not find stats for either audio or video');
    }

    const otStats = {
      timestamp: getStatsHelpers.normalizeTimestamp(video ? video.timestamp : audio.timestamp),
    };

    const getOutboundStats = pick(['packetsSent', 'packetsLost', 'bytesSent']);
    const getInboundStats = pick(['packetsReceived', 'packetsLost', 'bytesReceived']);
    const getCommonStats = inbound ? getInboundStats : getOutboundStats;

    if (video) {
      otStats.video = assign(
        mapValues(Number, getCommonStats(video)),
        { frameRate: calcFrameRate(video, startTime) }
      );
    }

    if (audio) {
      otStats.audio = mapValues(Number, getCommonStats(audio));
    }

    return otStats;
  },
};

module.exports = getStatsHelpers;

const OTHelpers = require('../common-js-helpers/OTHelpers.js');
const promiseDelay = require('../helpers/promiseDelay.js');
const uuid = require('uuid');

// local modules
const empiricDelay = require('./empiric_delay.js');
const eventing = require('../helpers/eventing');

const logging = require('../helpers/log')('proxyExtras');
const RefCounter = require('./ref_count_behaviour.js');

const waitOnGlobalCallback = require('./wait_on_global_callback');
const createObject = require('./create_object');

const generateCallbackID = function generateCallbackID() {
  return `OTPlugin_loaded_${uuid().replace(/-+/g, '')}`;
};

// Reference counted wrapper for a plugin object
module.exports = (options, completion) => {
  const Proto = function PluginProxy() {};
  const pluginProxy = new Proto();

  pluginProxy.ready = {};

  pluginProxy.ready.promise = new Promise((resolve, reject) => {
    pluginProxy.ready.resolve = resolve;
    pluginProxy.ready.reject = reject;
  });

  eventing(pluginProxy);

  // Calling this will bind a listener to the devicesChanged events that
  // the plugin emits and then rebroadcast them.
  pluginProxy.listenForDeviceChanges = () => {
    pluginProxy.ready.promise.then(() => promiseDelay(empiricDelay)).then(() => {
      pluginProxy._.registerXCallback('devicesChanged', () => {
        const args = Array.prototype.slice.call(arguments);
        logging.debug(args);
        pluginProxy.emit('devicesChanged', ...args);
      });
    });
  };

  pluginProxy.refCounter = new RefCounter();

  pluginProxy.refCounter.on('remove', () => {
    if (pluginProxy.refCounter.count === 0) {
      pluginProxy.cleanup();
    }
  });

  // Assign +plugin+ to this object and setup all the public
  // accessors that relate to the DOM Object.
  //
  const setPlugin = function setPlugin(plugin) {
    if (plugin) {
      pluginProxy._ = plugin;
      pluginProxy.parentElement = plugin.parentElement;
      pluginProxy.OTHelpers = OTHelpers(plugin);
    } else {
      pluginProxy._ = null;
      pluginProxy.parentElement = null;
      pluginProxy.OTHelpers = OTHelpers();
    }
  };

  pluginProxy.uuid = generateCallbackID();

  pluginProxy.isValid = () => pluginProxy._.valid;

  pluginProxy.cleanup = () => {
    setPlugin(null);

    // Let listeners know that they should do any final book keeping
    // that relates to us.
    pluginProxy.emit('destroy');
  };

  pluginProxy.destroy = () => {
    if (pluginProxy.refCounter.count === 0) {
      pluginProxy.cleanup();
    }
    pluginProxy.refCounter.forEach((ref) => {
      ref.destroy();
    });
  };

  pluginProxy.enumerateDevices = function (callback) {
    pluginProxy._.enumerateDevices(callback);
  };

  // Initialise

  // The next statement creates the raw plugin object accessor on the Proxy.
  // This is null until we actually have created the Object.
  setPlugin(null);

  waitOnGlobalCallback(pluginProxy.uuid, (err) => {
    if (err) {
      completion(`The plugin with the mimeType of ${
        options.mimeType} timed out while loading: ${err}`);

      pluginProxy.destroy();
      return;
    }

    pluginProxy._.setAttribute('id', `tb_plugin_${pluginProxy._.uuid}`);
    pluginProxy._.removeAttribute('tb_callback_id');
    pluginProxy.uuid = pluginProxy._.uuid;
    pluginProxy.id = pluginProxy._.id;

    // TODO: This guard shouldn't be necessary, and if it is we should throw an error if it's not
    // there.
    if (pluginProxy._.on) {
      // If the plugin supports custom events we'll use them
      pluginProxy._.on(-1, {
        customEvent(eventName, ...args) {
          pluginProxy.emit(eventName, ...args);
        },
      });
    }

    const internalReadyCallback = (error) => {
      // It's not safe to do most plugin operations until the plugin
      // is ready for us to do so. We use ready as a guard in.

      if (error) {
        logging.error(`Error while starting up plugin ${pluginProxy.uuid}: ${error}`);
        pluginProxy.ready.reject(error);
        completion(error);
        pluginProxy.destroy();
        return;
      }

      pluginProxy.ready.resolve();
      logging.debug(`Plugin ${pluginProxy.id} is loaded`);
      completion(undefined, pluginProxy);
    };

    // Only the main plugin has an initialise method
    if (pluginProxy._.initialise) {
      pluginProxy.on('ready', internalReadyCallback);
      pluginProxy._.initialise();
    } else {
      internalReadyCallback();
    }
  });

  createObject(pluginProxy.uuid, options, (err, plugin) => {
    if (err) {
      logging.error(err); // TODO: would it be better to throw?
      return;
    }

    setPlugin(plugin);
  });

  // TODO: Why is there both a completion handler and a return value?
  return pluginProxy;
};

const { isMediaStreamTrack } = require('./isMediaStreamTrack');
const clone = require('lodash/clone');
const env = require('../../helpers/env');

const normaliseOptions = (originalOptions, logPrefix, logging) => {
  const options = originalOptions && typeof originalOptions === 'object' ?
    clone(originalOptions) : {};
  if (options.audioSource === false) {
    options.audioSource = null;
  }
  if (options.videoSource === false) {
    options.videoSource = null;
  }
  // Ignore minVideoBitrate if it's defined but not a positive number
  if (options.minVideoBitrate !== undefined &&
    !(typeof options.minVideoBitrate === 'number' &&
      options.minVideoBitrate > 0)) {
    logging.warn(`${logPrefix}: Invalid minVideoBitrate in publisher properties. minVideoBitrate must ` +
      'be a positive number');
    delete options.minVideoBitrate;
  }
  // Ignore audioBitrate if it's defined but not between 6kb and 510kb
  if (options.audioBitrate !== undefined &&
    !(options.audioBitrate >= 6 * 1000 &&
      options.audioBitrate <= 510 * 1000)) {
    logging.warn(`${logPrefix}: Invalid audioBitrate in publisher properties. audioBitrate must ` +
      `be a number between ${6 * 1000} and ${510 * 1000} bits per second`);
    delete options.audioBitrate;
  }
  // Crashes in FF45-46 and fails to publish in FF47-48
  if (env.name === 'IE' || (env.name === 'Firefox' && env.version < 49)) {
    const label = env.name === 'IE' ?
      'Internet Explorer' :
      'Firefox 48 and below';
    if (isMediaStreamTrack(options.audioSource)) {
      options.audioSource = null;
      logging.warn(`${logPrefix}: Cannot use custom tracks with ${label}. Setting audioSource to null`);
    }
    if (isMediaStreamTrack(options.videoSource)) {
      options.videoSource = null;
      logging.warn(`${logPrefix}: Cannot use custom tracks with ${label}. Setting videoSource to null`);
    }
  }
  return options;
};
exports.normaliseOptions = normaliseOptions;

const eventNames = require('../../helpers/eventNames');
const now = require('../../helpers/now');
const RafRunner = require('../RafRunner');
const Events = require('../events')();

module.exports = ({ publisher, widgetView }) => {
  let loudness = 0;
  let loudnessLastAccess;

  const audioLevelRunner = new RafRunner(() => {
    const listenerCount = publisher.listenerCount(eventNames.AUDIO_LEVEL_UPDATED);
    const shouldStopWatchingAudio = () => now() - loudnessLastAccess > 10000 && listenerCount === 0;

    if (shouldStopWatchingAudio()) {
      audioLevelRunner.stop();
      return;
    }

    const video = widgetView && widgetView.video();

    if (video) {
      const audioInputLevel = video.getAudioInputLevel();
      loudness = audioInputLevel;
      if (listenerCount > 0) {
        // dispatch this old event if someone is listening...
        publisher.dispatchEvent(new Events.AudioLevelUpdatedEvent(audioInputLevel));
      }
    }
  });

  Object.defineProperty(publisher, 'loudness', {
    get() {
      audioLevelRunner.start();
      loudnessLastAccess = now();

      return loudness;
    },
    set() {
      throw new TypeError('Cannot set readonly property loudness');
    },
  });

  if (publisher.listenerCount(eventNames.AUDIO_LEVEL_UPDATED)) {
    audioLevelRunner.start();
  }

  publisher.on('audioLevelUpdated:added', () => { audioLevelRunner.start(); });
  publisher.once(eventNames.PUBLISHER_DESTROYED, () => {
    audioLevelRunner.stop();
  });
};

const assert = require('assert');
const promiseFinally = require('promise-finally').default;

// Takes a function of the form function() => promise (no args and returns a promise) and returns
// a function of the same form which prevents concurrent requests by returning the pending result
// when additional requests are made before the first is fulfilled.

module.exports = function (request) {
  let responsePromise;

  return function () {
    assert(arguments.length === 0);

    if (responsePromise) {
      return responsePromise;
    }

    responsePromise = promiseFinally(request(), () => {
      responsePromise = undefined;
    });

    return responsePromise;
  };
};

/* eslint-disable no-underscore-dangle */

/*
 * An <code>AudioContext</code> based audio level sampler. It returns the maximum value in the
 * last 1024 samples.
 *
 * It is worth noting that the remote <code>MediaStream</code> audio analysis is currently only
 * available in FF.
 *
 * This implementation gracefully handles the case where the <code>MediaStream</code> has not
 * been set yet by returning a <code>null</code> value until the stream is set. It is up to the
 * call site to decide what to do with this value (most likely ignore it and retry later).
 *
 * @constructor
 * @param {AudioContext} audioContext an audio context instance to get an analyser node
 */

module.exports = class WebAudioAudioLevelSampler {
  _analyser = null;
  _timeDomainData = null;
  _sourceNode = null;

  constructor(audioContext) {
    this._audioContext = audioContext;
  }
  webRTCStream(mediaStream) {
    if (this._sourceNode) {
      this._sourceNode.disconnect(this._analyser);
    }
    this._analyser = this._audioContext.createAnalyser();
    this._sourceNode = this._audioContext.createMediaStreamSource(mediaStream);
    this._sourceNode.connect(this._analyser);
    this._timeDomainData = new Uint8Array(this._analyser.frequencyBinCount);
  }
  destroy() {
    if (this._sourceNode) {
      this._sourceNode.disconnect(this._analyser);
    }
    this._timeDomainData = null;
  }
  sample(done) {
    if (typeof done === 'function') {
      throw new Error('sample no longer takes a callback');
    }
    if (this._analyser && this._timeDomainData) {
      this._analyser.getByteTimeDomainData(this._timeDomainData);
      // varies from 0 to 255
      let max = 0;

      for (let idx = this._timeDomainData.length - 1; idx >= 0; idx -= 1) {
        max = Math.max(max, Math.abs(this._timeDomainData[idx] - 128));
      }

      // normalize the collected level to match the range delivered by
      // the getStats' audioOutputLevel
      return max / 128;
    }
    return null;
  }
};



// A resolver for lodash/memoize (its second argument, see lodash docs) that assigns a consistent
// number to any object/value passed to it by storing an array of seen objects and looking up the
// index of the of the current object/value.

module.exports = function () {
  const seenObjects = [];

  return function (obj) {
    let seenIndex = seenObjects.indexOf(obj);

    if (seenIndex === -1) {
      seenIndex = seenObjects.length;
      seenObjects.push(obj);
    }

    return String(seenIndex);
  };
};

const OTPlugin = require('../../otplugin/otplugin.js');

const pluginCanSupportVideo = (sdp) => {
  if (sdp.indexOf('m=video') > -1 && !OTPlugin.supportedVideoCodecs.some(codec => sdp.indexOf(`${codec}/`) > -1)) {
    // None of IE's supported codecs are being offered
    return false;
  }
  return true;
};

const testSupportVideo = (remoteDesc, p2p, env, RTCPeerConnection, RTCSessionDescription) => {
  if (!RTCPeerConnection) {
    if (OTPlugin.isInstalled() && !pluginCanSupportVideo(remoteDesc.sdp)) {
      return Promise.reject(new Error('No supported video codec for IE'));
    }
    return Promise.resolve(true);
  }

  const pcConfig = {
    iceServers: [],
  };
  if (env.name === 'Chrome' && env.version > 71) {
    pcConfig.sdpSemantics = 'plan-b';
  }
  const testPc = new RTCPeerConnection(pcConfig);

  const cleanup = () => {
    try { testPc.close(); } catch (e) {} // eslint-disable-line no-empty
  };

  return testPc.setRemoteDescription(new RTCSessionDescription(remoteDesc))
    .then(() => {
      cleanup();
    })
    .catch((err) => {
      cleanup();
      throw err;
    });
};

module.exports = testSupportVideo;

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void */


const OTHelpers = require('../../common-js-helpers/OTHelpers.js');

module.exports = {
  isSupportedInThisBrowser: OTHelpers.env.name === 'Firefox',
  autoRegisters: true,
  extensionRequired: false,
  extensionInstalled: false,
  getConstraintsShowsPermissionUI: false,
  sources: {
    screen: true,
    application: OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 34,
    window: OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 34,
    browser: OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 38,
  },
  register() {
    return {
      isInstalled(callback) {
        // The extension sets widow.OTScreenSharing in pages loaded from a matching domain
        callback(typeof OTScreenSharing === 'object');
      },
      getConstraints(source, constraints, callback) {
        constraints.video = {
          mediaSource: source,
        };

        // copy constraints under the video object and removed them from the root constraint object
        if (constraints.browserWindow) {
          constraints.video.browserWindow = constraints.browserWindow;
          delete constraints.browserWindow;
        }
        if (typeof constraints.scrollWithPage !== 'undefined') {
          constraints.video.scrollWithPage = constraints.scrollWithPage;
          delete constraints.scrollWithPage;
        }

        callback(void 0, constraints);
      },
    };
  },
};

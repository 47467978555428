const env = require('../helpers/env');

module.exports = function generateObjectHtml(callbackId, optionsParam) {
  const options = optionsParam || {};
  const objBits = [];

  const attrs = [
    `type="${options.mimeType}"`,
    `id="${callbackId}_obj"`,
    `tb_callback_id="${callbackId}"`,
    'width="0" height="0"',
  ];

  const params = {
    userAgent: env.userAgent.toLowerCase(),
    windowless: options.windowless,
    onload: callbackId,
  };

  if (options.isVisible !== true) {
    attrs.push('visibility="hidden"');
  }

  objBits.push(`<object ${attrs.join(' ')}>`);

  Object.keys(params).forEach((name) => {
    objBits.push(`<param name="${name}" value="${params[name]}" />`);
  });

  objBits.push('</object>');
  return objBits.join('');
};

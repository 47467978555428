const Modal = require('./modal.js');

const promptToInstallHTML = require('../../html/installPlugin.html');
const updateInProgressHTML = require('../../html/updateProgress.html');
const updateCompleteHTML = require('../../html/updateComplete.html');

let isFontInstalled = false;

const installFont = () => {
  if (!isFontInstalled) {
    isFontInstalled = true;
    return new Promise((resolve) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.media = 'screen';
      link.href = '//fonts.googleapis.com/css?family=Didact+Gothic';
      link.onload = resolve;
      document.head.appendChild(link);
    });
  }
  return Promise.resolve();
};

const pluginDialogs = {
  promptToInstall() {
    const modal = new Modal(promptToInstallHTML);

    const checkboxEl = modal.el('[role=OT_acceptEULA]');
    const downloadBtnEl = modal.el('[role=OT_download]');
    const downloadSectionEl = modal.el('[role=OT_downloadSection]');
    const refreshSectionEl = modal.el('[role=OT_refreshSection]');
    const refreshBtnEl = modal.el('[role=OT_refresh]');

    checkboxEl.addEventListener('change', () => {
      downloadBtnEl.disabled = !checkboxEl.checked;
    });

    downloadBtnEl.addEventListener('click', () => {
      modal.emit('download');
      setTimeout(() => {
        modal.el('.OT_dialog-messages-main').innerHTML
          = 'To enable audio & video chat on this website, run the installer and then refresh ' +
          'your browser window.';

        downloadSectionEl.classList.add('OT_dialog-hidden');
        refreshSectionEl.classList.remove('OT_dialog-hidden');
      }, 3000);
    });

    refreshBtnEl.addEventListener('click', () => modal.emit('refresh'));
    installFont().then(() => modal.open());
    return modal;
  },
  updateInProgress() {
    const modal = new Modal(updateInProgressHTML);

    const progressTextEl = modal.el('[role=OT_progressText');
    const progressBarEl = modal.el('[role=OT_progressBar]');

    modal.setUpdateProgress = (newProgress) => {
      const progress = Math.max(0, Math.min(Math.round(newProgress), 100));
      progressBarEl.style.width = `${progress}%`;
      progressTextEl.innerText = `${progress}%`;
    };

    installFont().then(() => modal.open());
    return modal;
  },
  updateComplete(error) {
    const modal = new Modal(updateCompleteHTML);

    modal.el('[role=OT_refresh]').addEventListener('click', () => modal.emit('reload'));

    if (error) {
      modal.el('.OT_dialog-messages-main').innerText = 'Update failed.';
      modal.el('.OT_dialog-messages-minor').innerText = error || 'NO ERROR';
    }

    installFont().then(() => modal.open());
    return modal;
  },
};

module.exports = {
  Plugin: pluginDialogs,
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */

const find = require('lodash/find');

module.exports = function screenSharingFactory(deps = {}) {
  const chromeExtensionHelper = deps.chromeExtensionHelper || require('./chrome_extension_helper.js')();
  const electronExtensionHelper = deps.electronExtensionHelper || require('./electron_extension_helper.js')();
  const Errors = deps.Errors || require('../Errors.js');
  const firefoxExtensionHelper = deps.firefoxExtensionHelper || require('./firefox_extension_helper.js');
  const getDisplayMediaExtensionHelper = deps.getDisplayMediaExtensionHelper || require('./getDisplayMedia_extension_helper.js');
  const ieExtensionHelper = deps.ieExtensionHelper || require('./ie_extension_helper.js');
  const otError = deps.otError || require('../../helpers/otError.js')();
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');

  const screenSharing = {};

  screenSharing.extensionByKind = {};
  screenSharing.extensionClasses = {};

  screenSharing.registerExtensionHelper = (kind, helper) => {
    screenSharing.extensionClasses[kind] = helper;
    if (helper.autoRegisters && helper.isSupportedInThisBrowser) {
      screenSharing.registerExtension(kind);
    }
  };

  /**
   * Register a Chrome or Opera extension for screen-sharing support.
   * <p>
   * <b>Note:</b> A screen-sharing extension is not required in Chrome 72+. In Chrome 72+,
   * the end user is prompted to grant access to the screen, just as they would be when granting
   * access to a camera. You only need a screen-sharing extension for Opera and Chrome versions
   * older than Chrome 72.
   * <p>
   * Use the <code>OT.checkScreenSharingCapability()</code> method to check if an extension is
   * required, registered, and installed.
   * <p>
   * The OpenTok
   * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a>
   * repo includes code for creating an extension for screen-sharing support.
   *
   * @param {String} kind Set this parameter to <code>"chrome"</code>. Currently, you can only
   * register a screen-sharing extension for Chrome or Opera.
   *
   * @param {String} id The ID for your screen-sharing extension. You can find this ID at
   * chrome://extensions.
   *
   * @param {Number} version The version of the screen-sharing extension from the
   * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a> repo.
   * Set this if you are using version 2 or later. For example, if you are using version 2, set this
   * to 2. With version 2, the client can use the extension immediately after installing it, without
   * reloading the page.
   *
   * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
   * @see <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
   * @method OT.registerScreenSharingExtension
   * @memberof OT
   */

  screenSharing.registerExtension = (kind, ...initArgs) => {
    if (screenSharing.extensionClasses[kind] == null) {
      throw new Error('Unsupported kind passed to OT.registerScreenSharingExtension');
    }

    const x = screenSharing
      .extensionClasses[kind]
      .register(...initArgs);

    screenSharing.extensionByKind[kind] = x;
  };

  const screenSharingPickHelper = () => {
    const foundClass = find(
      Object.keys(screenSharing.extensionClasses),
      cls => screenSharing.extensionClasses[cls].isSupportedInThisBrowser
    );

    if (foundClass === undefined) {
      return {};
    }

    return {
      name: foundClass,
      proto: screenSharing.extensionClasses[foundClass],
      instance: screenSharing.extensionByKind[foundClass],
    };
  };

  screenSharing.pickHelper = () => screenSharingPickHelper();

  /**
   * Checks for support for publishing screen-sharing streams on the client browser. The object
   * passed to the callback function defines whether screen sharing is supported, as well as
   * which screen-sharing sources (application, screen, or window) are supported. It also indicates
   * whether an extension is required, installed, and registered (if needed).
   * <p>
   * <pre>
   * OT.checkScreenSharingCapability(function(response) {
   *   if (!response.supported || response.extensionRegistered === false) {
   *     // This browser does not support screen sharing
   *   } else if (response.extensionInstalled === false) {
   *     // Prompt to install the extension
   *   } else {
   *     // Screen sharing is available.
   *   }
   * });
   * </pre>
   * <p>
   * Chrome 72+, Firefox 52+, and the OpenTok plugin for Internet Explorer have screen-sharing
   * support built-in, with no extension required. To publish a screen-sharing video in Opera
   * or older versions of Chrome, the client adds an extension that enables publishing
   * a screen-sharing video stream on your domain. The OpenTok
   * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a>
   * sample includes code for creating an extension for screen-sharing support in
   * Opera or older versions of Chrome.
   * <p>
   * For more information, see the <a href="https://tokbox.com/developer/guides/screen-sharing/js/">
   * OpenTok Screen Sharing</a> developer guide.
   *
   * @param {function} callback The callback invoked with the support options object passed as
   * the parameter. This object has the following properties that indicate support for publishing
   * screen-sharing streams in the client:
   * <p>
   * <ul>
   *   <li>
   *     <code>extensionInstalled</code> (Boolean) &mdash;  In Opera and older versions of Chrome,
   *     this is set to <code>true</code> if the extension is installed and registered.
   *     In Chrome 72+, Firefox 52+, and Internet Explorer, this property is undefined.
   *   </li>
   *   <li>
   *     <code>supported</code> (Boolean) &mdash; Set to <code>true</code> if screen sharing
   *     is supported in the browser. Check the <code>extensionRequired</code> property
   *     to see if the browser requires an extension for screen sharing.
   *   </li>
   *   <li>
   *     <code>supportedSources</code> (Object) &mdash; An object with the following properties:
   *     <code>application</code>, <code>screen</code>, and <code>window</code>. Each property is
   *     a Boolean value indicating support. In Firefox, each of these properties is set to
   *     <code>true</code>. In Firefox, you can set a specify the type of screen-sharing source
   *     by setting the <code>videoSource</code> property of the options passed into the
   *     <a href="#initPublisher">OT.initPublisher()</a> method. Set the property to
   *     <code>"application"</code>, <code>"screen"</code>, or <code>"window"</code>.
   *     In other browsers, setting the <code>videoSource</code> property to any of these values
   *     results in a prompt that asks the user to determine the screen-sharing source.
   *     In Chrome and Internet Explorer (using the OpenTok plugin), only
   *     <code>screen</code> and <code>window</code> are set to <code>true</code>.
   *     In Opera, only the <code>screen</code> property is set to <code>true</code>.
   *   </li>
   * </ul>
   * <p> The options parameter also includes the following properties, which apply to screen-sharing
   * support in Opera and older versions of Chrome (in all other browsers these properties are
   * undefined):
   * <ul>
   *   <li>
   *     <code>extensionRequired</code> (String) &mdash; Set to <code>"chrome"</code> in Opera
   *     and older versions of Chrome, which require a screen-sharing extension
   *     to be installed. This property is undefined in other browsers.
   *   </li>
   *   <li>
   *     <code>extensionRegistered</code> (Boolean) &mdash; In Opera and older versions of Chrome,
   *     this property is set to <code>true</code> if a screen-sharing extension is registered;
   *     otherwise it is set to <code>false</code>. In other browsers (which do not require
   *     an extension), this property is undefined. Use the
   *     <code>OT.registerScreenSharingExtension()</code> method to register a screen-sharing
   *     extension in Opera or an older version of Chrome.
   *   </li>
   * </ul>
   *
   * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
   * @see <a href="OT.html#registerScreenSharingExtension">OT.registerScreenSharingExtension()</a>
   * @method OT.checkScreenSharingCapability
   * @memberof OT
   */
  screenSharing.checkCapability = (callback) => {
    const response = {
      supported: false,
      extensionRequired: undefined,
      extensionRegistered: undefined,
      extensionInstalled: undefined,
      supportedSources: {},
    };

    // find a supported browser

    const helper = screenSharingPickHelper();

    if (helper.name === undefined) {
      setTimeout(callback.bind(null, response));
      return;
    }

    response.supported = true;
    response.extensionRequired = helper.proto.extensionRequired ? helper.name : undefined;

    response.supportedSources = {
      screen: helper.proto.sources.screen,
      application: helper.proto.sources.application,
      window: helper.proto.sources.window,
      browser: helper.proto.sources.browser,
    };

    if (!helper.instance) {
      response.extensionRegistered = false;
      if (response.extensionRequired) {
        response.extensionInstalled = false;
      }
      setTimeout(callback.bind(null, response));
      return;
    }

    response.extensionRegistered = response.extensionRequired ? true : undefined;
    helper.instance.isInstalled((installed) => {
      response.extensionInstalled = (response.extensionRequired || OTHelpers.env.name === 'Firefox') ?
        installed : undefined;
      callback(response);
    });
  };

  screenSharing.registerExtensionHelper('electron', electronExtensionHelper);
  screenSharing.registerExtensionHelper('getDisplayMedia', getDisplayMediaExtensionHelper);
  screenSharing.registerExtensionHelper('chrome', chromeExtensionHelper);
  screenSharing.registerExtensionHelper('firefox', firefoxExtensionHelper);
  screenSharing.registerExtensionHelper('OpenTokPlugin', ieExtensionHelper);

  const noop = () => {};

  screenSharing.getConstraints = (opt) => {
    const {
      onAccessDialogOpened = noop,
      onAccessDialogClosed = noop,
      videoSource,
      constraints,
    } = opt;

    return new Promise((resolve, reject) => {
      screenSharing.checkCapability((response) => {
        if (!response.supported) {
          let errorMessage = 'Screen Sharing is not supported in this browser';
          if (OTHelpers.env.name === 'Chrome' && OTHelpers.env.version > 73 && global.location.protocol !== 'https:') {
            errorMessage = 'https:// is required for screen sharing';
          }
          reject(otError(
            Errors.SCREEN_SHARING_NOT_SUPPORTED,
            new Error(errorMessage)
          ));
        } else if (response.extensionRegistered === false) {
          reject(otError(
            Errors.SCREEN_SHARING_EXTENSION_NOT_REGISTERED,
            new Error('Screen Sharing support in this browser requires an extension, but ' +
              'one has not been registered.')
          ));
        } else if (response.extensionRequired && response.extensionInstalled === false) {
          reject(otError(
            Errors.SCREEN_SHARING_EXTENSION_NOT_INSTALLED,
            new Error('Screen Sharing support in this browser requires an extension, but ' +
              'the extension is not installed.')
          ));
        } else {
          const helper = screenSharing.pickHelper();

          if (helper.proto.getConstraintsShowsPermissionUI) {
            onAccessDialogOpened();
          }

          helper.instance.getConstraints(videoSource, constraints,
            (err, helperConstraints) => {
              if (helper.proto.getConstraintsShowsPermissionUI) {
                onAccessDialogClosed();
              }
              if (err) {
                reject(err);
              } else {
                resolve(helperConstraints);
              }
            });
        }
      });
    });
  };

  return screenSharing;
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-void, no-underscore-dangle, max-len */


/* global ActiveXObject */

const logging = require('../helpers/log')('meta');
const OTHelpers = require('../common-js-helpers/OTHelpers.js');
const { pluginVersion, maxPluginVersion } = require('./pluginVersion');
const staticConfig = require('../helpers/StaticConfig.js')().onlyLocal();
const versionGreaterThan = require('./version_greater_than.js');

const meta = {};
module.exports = meta;

const ie64 = (
  OTHelpers.env.name === 'IE' &&
  OTHelpers.env.userAgent.indexOf('Win64') !== -1
);

const archSuffix = (ie64 ? '_x64' : '');

let updaterMimeType, // <- cached version, use getInstallerMimeType instead
  installedVersion = -1; // <- cached version, use getInstallerMimeType instead

// Work out the full mimeType (including the currently installed version)
// of the installer.
const findMimeTypeAndVersion = () => {
  if (updaterMimeType !== void 0) {
    return;
  }

  const activeXControlId = `TokBox.OpenTokPluginInstaller${archSuffix}`;
  const installPluginName = 'OpenTokPluginInstaller';
  const unversionedMimeType = `application/x-opentokplugininstaller${archSuffix}`;
  let plugin = (
    global.navigator.plugins[activeXControlId] ||
    global.navigator.plugins[installPluginName]
  );

  installedVersion = -1;

  if (plugin) {
    // Look through the supported mime-types for the version
    // There should only be one mime-type in our use case, and
    // if there's more than one they should all have the same
    // version.
    const numMimeTypes = plugin.length;

    const extractVersion = new RegExp(
      `${unversionedMimeType.replace('-', '\\-')},version=([0-9a-zA-Z-_.]+)`, 'i'
    );

    let mimeType,
      bits;

    for (let i = 0; i < numMimeTypes; ++i) {
      mimeType = plugin[i];

      // Look through the supported mimeTypes and find
      // the one we require.
      if (mimeType && mimeType.enabledPlugin &&
          (mimeType.enabledPlugin.name === plugin.name) &&
          mimeType.type.indexOf(unversionedMimeType) !== -1) {
        bits = extractVersion.exec(mimeType.type);

        if (bits !== null) {
          installedVersion = bits[1];
          if (installedVersion === pluginVersion) {
            // If we have found the version we want then stop searching
            break;
          }
        }
      }
    }
  } else if (OTHelpers.env.name === 'IE') {
    // This may mean that the installer plugin is not installed.
    // Although it could also mean that we're on IE 9 and below,
    // which does not support navigator.plugins. Fallback to
    // using 'ActiveXObject' instead.
    try {
      plugin = new ActiveXObject(activeXControlId);
      installedVersion = plugin.getMasterVersion();
    } catch (e) {
      // Just logging this as info because it's not necessarily an error
      // it just means that the plugin is not installed
      logging.info(e);
    }
  }

  updaterMimeType = installedVersion !== -1 ?
    `${unversionedMimeType},version=${installedVersion}` :
    null;
};

meta.getInstallerMimeType = () => {
  if (updaterMimeType === void 0) {
    findMimeTypeAndVersion();
  }

  return updaterMimeType;
};

meta.installedVersion = () => {
  findMimeTypeAndVersion();

  return installedVersion;
};

installedVersion = meta.installedVersion();

meta.mimeType = `application/x-opentokplugin${archSuffix},version=${installedVersion}`;
meta.activeXName = `TokBox.OpenTokPlugin${archSuffix}.${installedVersion}`;

meta.version = () => pluginVersion;

meta.maxPluginVersion = () => maxPluginVersion;

// Version 0.4.0.4 autoupdate was broken. We want to prompt
// for install on 0.4.0.4 or earlier. We're also including
// earlier versions just in case. Version 0.4.0.10 also
// had a broken updater, we'll treat that version the same
// way.
meta.hasBrokenUpdater = () => {
  const _broken = meta.installedVersion() === '0.4.0.9' ||
                !versionGreaterThan(meta.installedVersion(), '0.4.0.4');

  meta.hasBrokenUpdater = () => _broken;
  return _broken;
};

// Returns a URI to the OTPlugin installer that is paired with
// this version of OTPlugin.js.
meta.pathToInstaller = (callback) => {
  const installerPath = `${staticConfig.assetUrl}/plugin/` +
    `OpenTokPluginMain_x${ie64 ? '64' : '32'}.msi`;

  // Backwards compatibility here. No possibility of this being async anymore.
  if (callback) {
    callback(installerPath);
  }

  return installerPath;
};

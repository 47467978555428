/* eslint-disable no-underscore-dangle */
const logging = require('../../helpers/log')('PluginVideoElementWrapper');
const listenForTracksEnded = require('./listenForTracksEnded.js')();
const Watcher = require('../../helpers/Watcher');
const canBeOrientatedMixin = require('./can_be_oriented_mixin.js');
const eventing = require('../../helpers/eventing');
const IntervalRunner = require('../../ot/interval_runner');

/**
 * PluginVideoElementWrapper
 *
 * @package
 * @class
 * @param {Object} options
 * @param {String} options.fitMode
 * @return {PluginVideoElementWrapper}
 */
class PluginVideoElementWrapper {
  _pluginVideoContainer;
  /** @type {HTMLElement|undefined} */
  _parentDomElement;
  _fitMode;

  constructor({ fitMode }) {
    this._fitMode = fitMode;
    this.timeUpdateInterval = new IntervalRunner(() => {
      this.trigger('timeupdate');
    }, 30);
    eventing(this);
    canBeOrientatedMixin(this, () => this._pluginVideoContainer.domElement);
    const watchForResize = new Watcher(() => ({
      width: this.videoWidth(), height: this.videoHeight(),
    }), (res, oldRes) => {
      this.trigger('videoDimensionsChanged', oldRes, res);
    });
    this.on('_bind', () => watchForResize.start());
    this.on('_unbind', () => watchForResize.stop());
  }
  /**
   * Get the underlying DOM element
   * @return {HTMLElement}
   */
  domElement() {
    return this._pluginVideoContainer ? this._pluginVideoContainer.domElement : undefined;
  }
  videoWidth() {
    return this._pluginVideoContainer ?
      Number(this._pluginVideoContainer[`video${this.isRotated() ? 'Height' : 'Width'}`]()) : 0;
  }
  videoHeight() {
    return this._pluginVideoContainer ?
      Number(this._pluginVideoContainer[`video${this.isRotated() ? 'Width' : 'Height'}`]()) : 0;
  }
  imgData() {
    return this._pluginVideoContainer ? this._pluginVideoContainer.getImgData() : null;
  }
  // eslint-disable-next-line class-methods-use-this
  isAudioBlocked() {
    return false;
  }
  appendTo(newParentDomElement) {
    this._parentDomElement = newParentDomElement;
    return this;
  }
  // rebind() is a noop in the plugin
  // eslint-disable-next-line class-methods-use-this
  rebind() { }
  // Bind a stream to the video element.
  bindToStream(webRtcStream) {
    const promise = new Promise((resolve, reject) => {
      if (!this._parentDomElement || !document.body.contains(this._parentDomElement)) {
        logging.error('The VideoElement must be attached to a DOM node before a stream can be bound');
      }
      this._pluginVideoContainer = webRtcStream._.render();
      this.trigger('videoElementCreated', this.domElement());
      this._pluginVideoContainer.setFitMode(this._fitMode);
      this._pluginVideoContainer.appendTo(this._parentDomElement);
      this._pluginVideoContainer.show((error) => {
        if (error) {
          reject(error);
        }
        resolve();
      });

      const onAllEnded = () => {
        if (this._mediaStoppedListener) {
          this._mediaStoppedListener.stop();
        }
        this.trigger('mediaStopped');
      };
      const onSingleEnded = (track) => {
        this.trigger('mediaStopped', track);
      };

      this._mediaStoppedListener = listenForTracksEnded(webRtcStream, onAllEnded, onSingleEnded);
      this.trigger('_bind');
    });

    promise.then(() => {
      setTimeout(() => {
        this.trigger('loadedmetadata');
        this.timeUpdateInterval.start();
      }, 10);
    });

    return promise;
  }
  // Unbind the currently bound stream from the video element.
  unbindStream() {
    // TODO: some way to tell OTPlugin to release that stream and controller
    this.timeUpdateInterval.stop();
    if (this._mediaStoppedListener) {
      this._mediaStoppedListener.stop();
    }
    this.trigger('_unbind');
    if (this._pluginVideoContainer) {
      this._pluginVideoContainer.destroy();
      if (this.domElement() && this.domElement().parentNode) {
        this.domElement().parentNode.removeChild(this.domElement());
      }
      this._parentDomElement = null;
      this._pluginVideoContainer = null;
    }
    return this;
  }
  destroy() {
    this.unbindStream();
    this.trigger('destroyed');
    return undefined;
  }
  setAudioVolume(value) {
    this._pluginVideoContainer.setAudioVolume(parseFloat(value) / 100);
  }
  getAudioVolume() {
    return this._pluginVideoContainer.getAudioVolume() * 100;
  }
  refreshTracks() {
    if (this._mediaStoppedListener) {
      this._mediaStoppedListener.refresh();
    }
  }

  // see https://wiki.mozilla.org/WebAPI/AudioChannels
  // The audioChannelType is not currently supported in the plugin.
  // eslint-disable-next-line class-methods-use-this
  audioChannelType() {
    return 'unknown';
  }
  getAudioInputLevel() {
    return Promise.resolve(this._pluginVideoContainer.getAudioInputLevel());
  }
  // eslint-disable-next-line class-methods-use-this
  whenTimeIncrements(callback, context) {
    // exists for compatibility with NativeVideoElement
    setTimeout(callback.bind(context));
  }
}

module.exports = PluginVideoElementWrapper;

/* eslint-disable no-underscore-dangle */
const isFunction = require('lodash/isFunction');
const uuid = require('uuid');

const createGetStatsAdaptor = require('./create_get_stats_adaptor/create_get_stats_adaptor.js');
const logging = require('../../helpers/log')('PluginPeerConnection');
const MediaStream = require('../media_stream');
const RTCIceCandidate = require('../rtc/rtc_ice_candidate.js');
const RTCSessionDescription = require('../rtc/rtc_session_description.js');
const eventing = require('../../helpers/eventing');

// Wait a second after plugin is ready before polling for events
const eventPollingDelay = 1000;

// Poll for events one per second
const eventPollingPeriod = 1000;

// Stop polling for ice candidates 10 seconds from start or last candidate
const iceCandidatePollingPeriod = 10000;

// Our RTCPeerConnection shim, it should look like a normal PeerConnection
// from the outside, but it actually delegates to our plugin.

const canAddCandidate = peerConnection =>
  peerConnection.localDescription && peerConnection.remoteDescription;

class PeerConnection {
  onaddstream = null;
  onremovestream = null;
  onicecandidate = null;
  onsignalingstatechange = null;
  oniceconnectionstatechange = null;
  _candidateQueue = [];
  _knownCandidates = [];
  _iceCandidatePollingCutoff = null;
  _closed = false;

  constructor({ iceServers }, options, plugin) {
    eventing(this);
    this.id = uuid();
    this._adaptedGetStats = createGetStatsAdaptor(plugin._, this.id);

    // Both username and credential must exist, otherwise the plugin throws an error
    this._iceServers = iceServers.map(iceServer => ({
      ...iceServer,
      username: iceServer.username || '',
      credential: iceServer.credential || '',
    }));

    plugin.refCounter.add(this);

    logging.debug('register global sharedWindowClosed callback');
    plugin._.registerXCallback('sharedWindowClosed', () => {
      // When a screen shared window is closed we stop the corresponding video track
      const localStreams = this.getLocalStreams();
      if (localStreams && localStreams.length > 0 && localStreams[0].getVideoTracks()) {
        localStreams[0].getVideoTracks().forEach((videoTrack) => {
          videoTrack.stop();
        });
      } else {
        logging.warn('received sharedWindowClosed for a stream with no video tracks');
      }
    });

    this._plugin = plugin;

    this._whenPluginReady = new Promise((resolve, reject) => {
      plugin._.initPeerConnection(
        this.id,
        { iceServers: this._iceServers },
        options,
        () => {
          logging.debug('instantiated');
          resolve();
        },
        (err) => {
          logging.error('error instantiating', err);
          reject(err);
        }
      );
    });

    this._bindEvents();

    this._whenStreamAdded = new Promise((resolve) => {
      if (plugin._.getLocalStreams(this.id).length > 0) {
        resolve();
      } else {
        this.addEventListener('addstream', () => {
          setTimeout(() => {
            resolve();
          }, 200);
        });
      }
    });
  }
  async _bindEvents() {
    await this._whenPluginReady;

    logging.debug('binding events');
    this._plugin._.on(this.id, {
      addStream: (streamJson) => {
        const stream = MediaStream.fromJson(streamJson, this._plugin);
        const event = { stream, target: this };
        this._triggerEvent('addstream', event);
      },
      removeStream: (streamJson) => {
        const stream = MediaStream.getOrCreate(streamJson, this._plugin);
        const event = { stream, target: this };
        this._triggerEvent('removestream', event);
      },
      iceCandidate: (...args) => {
        if (this._plugin._.getIceCandidates) {
          logging.debug('Ignoring deprecated ice candidate source:', args);
          return;
        }
        this._iceCandidate(...args);
      },
      signalingStateChange: (state) => {
        this.signalingState = state;
        const event = { state, target: this };
        this._triggerEvent('signalingstatechange', event);
      },
      iceConnectionChange: (state) => {
        this.iceConnectionState = state;
        const event = { state, target: this };
        this._triggerEvent('iceconnectionstatechange', event);
      },
    });
  }
  _resetIceCandidatePollingCutoff() {
    this._iceCandidatePollingCutoff = Date.now() + iceCandidatePollingPeriod;
  }
  async _pollForIceCandidates() {
    logging.debug('Polling for ice candidates');
    this._resetIceCandidatePollingCutoff();
    while (
      !this._closed &&
      Date.now() < this._iceCandidatePollingCutoff
    ) {
      this._plugin._.registerXCallback('iceCandidate', this._onCumulativeIceCandidates.bind(this));
      this._plugin._.getIceCandidates();
      await new Promise(resolve => setTimeout(resolve, eventPollingPeriod));
    }
    logging.debug('Finished polling for ice candidates');
  }
  async createOffer(constraints = {}) {
    await this._whenPluginReady;

    const [type, sdp] = await new Promise((resolve, reject) => this._plugin._.createOffer(
      this.id, (...args) => resolve(args), reject, constraints
    ));

    return new RTCSessionDescription({ type, sdp });
  }
  async createAnswer(constraints = {}) {
    await this._whenPluginReady;

    const [type, sdp] = await new Promise((resolve, reject) => this._plugin._.createAnswer(
      this.id, (...args) => resolve(args), reject, constraints
    ));

    return new RTCSessionDescription({ type, sdp });
  }
  async setLocalDescription(description) {
    await this._whenPluginReady;

    logging.debug('setLocalDescription');
    await new Promise((resolve, reject) => this._plugin._.setLocalDescription(
      this.id, description, resolve, reject
    ));
    this.localDescription = description;
    this._maybeProcessPendingCandidates();

    if (this._plugin._.getIceCandidates) {
      await new Promise(resolve => setTimeout(resolve, eventPollingDelay));
      this._pollForIceCandidates();
    }
  }
  async setRemoteDescription(description) {
    await this._whenPluginReady;

    logging.debug('setRemoteDescription');
    await new Promise((resolve, reject) => this._plugin._.setRemoteDescription(
      this.id, description, resolve, reject
    ));
    this.remoteDescription = description;
    this._maybeProcessPendingCandidates();
  }
  async addIceCandidate(candidate) {
    // @todo this violates the addIceCandidate contract when it's using the queue.
    // Perhaps the queue logic is wrong... should try it without it.
    await this._whenPluginReady;

    logging.debug('addIceCandidate');

    if (!canAddCandidate(this)) {
      this._candidateQueue.push(candidate);
      return undefined;
    }

    return new Promise((resolve, reject) => this._plugin._.addIceCandidate(
      this.id,
      candidate,
      resolve,
      reject
    ));
  }
  async addStream(stream) {
    await this._whenPluginReady;
    this._plugin._.addStream(this.id, stream, {});
  }
  async removeStream(stream) {
    await this._whenPluginReady;
    this._plugin._.removeStream(this.id, stream);
  }
  getRemoteStreams() {
    return (this._plugin._.getRemoteStreams(this.id) || []).map(
      stream => MediaStream.getOrCreate(stream, this._plugin)
    );
  }
  getLocalStreams() {
    return this._plugin._.getLocalStreams(this.id).map(
      stream => MediaStream.getOrCreate(stream, this._plugin)
    );
  }
  getStreamById(streamId) {
    return MediaStream.getOrCreate(this._plugin._.getStreamById(this.id, streamId), this._plugin);
  }
  async getStats(mediaStreamTrack) {
    await this._whenPluginReady;
    await this._whenStreamAdded;

    return this._adaptedGetStats(mediaStreamTrack);
  }
  async close() {
    await this._whenPluginReady;
    this._plugin._.destroyPeerConnection(this.id);
    this._plugin.refCounter.remove(this);
    this._closed = true;
  }
  destroy() {
    this.close();
  }
  _maybeProcessPendingCandidates() {
    if (canAddCandidate(this)) {
      this._candidateQueue.forEach((candidate) => {
        this._plugin._.addIceCandidate(this.id, candidate, () => {}, (err) => {
          logging.error('Failed to process candidate');
          logging.error(err);
        });
      });
      this._candidateQueue.splice(0, this._candidateQueue.length);
    }
  }
  _triggerEvent(name, event) {
    logging.debug('triggerEvent', name, event);
    if (isFunction(this[`on${name}`])) {
      this[`on${name}`](event);
    }
    this.emit(name, event);
  }
  _iceCandidate(candidateSdp, sdpMid, sdpMLineIndex) {
    this._resetIceCandidatePollingCutoff();
    const candidate = new RTCIceCandidate({
      candidate: candidateSdp,
      sdpMid,
      sdpMLineIndex,
    });
    this._lastIceCandidateReceivedTime = Date.now();
    const event = { candidate, target: this };
    this._triggerEvent('icecandidate', event);
  }
  _onCumulativeIceCandidates(candidates) {
    const newCandidates = candidates.slice(this._knownCandidates.length);
    newCandidates.forEach(candidate => this._iceCandidate(...candidate));
    this._knownCandidates = candidates;
  }
}

module.exports = async function createPeerConnection(rtcConfiguration, options, plugin) {
  const peerConnection = new PeerConnection(rtcConfiguration, options, plugin);
  await peerConnection._whenPluginReady;
  return peerConnection;
};

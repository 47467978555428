// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-void, one-var, prefer-const, no-shadow, vars-on-top, no-var */
/* eslint-disable no-mixed-operators */

const assign = require('lodash/assign');
const find = require('lodash/find');
const findIndex = require('lodash/findIndex');
const logging = require('../../helpers/log')('SDPHelpers');

const START_MEDIA_SSRC = 10000;
const START_RTX_SSRC = 20000;

// Here are the structure of the rtpmap attribute and the media line, most of the
// complex Regular Expressions in this code are matching against one of these two
// formats:
// * a=rtpmap:<payload type> <encoding name>/<clock rate> [/<encoding parameters>]
// * m=<media> <port>/<number of ports> <proto> <fmts>
//
// References:
// * https://tools.ietf.org/html/rfc4566
// * http://en.wikipedia.org/wiki/Session_Description_Protocol
//
//

const SDPHelpers = {
  getSections(sdp) {
    return sdp.split(/\r\n|\r|\n/).reduce((accum, line) => {
      const match = line.match(/^m=(\w+) \d+/);
      if (match) {
        accum.sections[accum.section = match[1]] = []; // eslint-disable-line no-param-reassign
      }
      accum.sections[accum.section].push(line);
      return accum;
    }, { sections: { header: [] }, section: 'header' }).sections;
  },
  getCodecsAndCodecMap(sdp, mediaType) {
    const section = SDPHelpers.getSections(sdp)[mediaType];

    if (!section) {
      throw new Error(`no mediaType ${mediaType}`);
    }

    const codecs = section[0].match(/m=\w+ \d+ [A-Z/]+ ([\d ]+)$/)[1].split(' ');

    const codecMap = assign(...section
      .filter(line => line.match(/^a=rtpmap:\d+/))
      .map(line => line.match(/^a=rtpmap:(\d+) ([\w-]+)/).splice(1))
      .map(([num, codec]) => ({ [num]: codec })));

    return { codecs, codecMap };
  },
  getCodecs(sdp, mediaType) {
    const codecsAndCodecMap = SDPHelpers.getCodecsAndCodecMap(sdp, mediaType);
    return codecsAndCodecMap.codecs.map(num => codecsAndCodecMap.codecMap[num] || 'Unknown codec');
  },
};

module.exports = SDPHelpers;

// Search through sdpLines to find the Media Line of type +mediaType+.
SDPHelpers.getMLineIndex = function getMLineIndex(sdpLines, mediaType) {
  const targetMLine = `m=${mediaType}`;

  // Find the index of the media line for +type+
  return findIndex(sdpLines, (line) => {
    if (line.indexOf(targetMLine) !== -1) {
      return true;
    }

    return false;
  });
};

// Grab a M line of a particular +mediaType+ from sdpLines.
SDPHelpers.getMLine = function getMLine(sdpLines, mediaType) {
  const mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  return mLineIndex > -1 ? sdpLines[mLineIndex] : void 0;
};

SDPHelpers.hasMediaType = (sdp, mediaType) => {
  const mLineRegex = new RegExp(`^m=${mediaType}`);
  const sdpLines = sdp.split('\r\n');
  return findIndex(sdpLines, line => mLineRegex.test(line)) >= 0;
};

SDPHelpers.hasMLinePayloadType = function hasMLinePayloadType(sdpLines, mediaType, payloadType) {
  const mLine = SDPHelpers.getMLine(sdpLines, mediaType);
  const payloadTypes = SDPHelpers.getMLinePayloadTypes(mLine, mediaType);

  return payloadTypes.indexOf(payloadType) > -1;
};

// Extract the payload types for a give Media Line.
//
SDPHelpers.getMLinePayloadTypes = function getMLinePayloadTypes(mediaLine, mediaType) {
  const mLineSelector = new RegExp(`^m=${mediaType
  } \\d+(/\\d+)? [a-zA-Z0-9/]+(( [a-zA-Z0-9/]+)+)$`, 'i');

  // Get all payload types that the line supports
  const payloadTypes = mediaLine.match(mLineSelector);
  if (!payloadTypes || payloadTypes.length < 2) {
    // Error, invalid M line?
    return [];
  }

  return payloadTypes[2].trim().split(' ');
};

SDPHelpers.removeTypesFromMLine = function removeTypesFromMLine(mediaLine, payloadTypes) {
  const typesSuffix = /[0-9 ]*$/.exec(mediaLine)[0];

  const newTypes = typesSuffix.split(' ').filter(type => type !== '' && payloadTypes.indexOf(type) === -1);

  return mediaLine.replace(typesSuffix, ` ${newTypes.join(' ')}`);
};

// Remove all references to a particular encodingName from a particular media type
//
SDPHelpers.removeMediaEncoding = function removeMediaEncoding(sdp, mediaType, encodingName) {
  let payloadTypes,
    i,
    j,
    parts;
  let sdpLines = sdp.split('\r\n');
  const mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  const mLine = mLineIndex > -1 ? sdpLines[mLineIndex] : void 0;
  const typesToRemove = [];

  if (mLineIndex === -1) {
    // Error, missing M line
    return sdpLines.join('\r\n');
  }

  // Get all payload types that the line supports
  payloadTypes = SDPHelpers.getMLinePayloadTypes(mLine, mediaType);
  if (payloadTypes.length === 0) {
    // Error, invalid M line?
    return sdpLines.join('\r\n');
  }

  // Find the payloadTypes of the codecs.
  // Allows multiple matches e.g. for CN.
  for (i = mLineIndex; i < sdpLines.length; i++) {
    const codecRegex = new RegExp(encodingName, 'i');
    if (sdpLines[i].indexOf('a=rtpmap:') === 0) {
      parts = sdpLines[i].split(' ');
      if (parts.length === 2 && codecRegex.test(parts[1])) {
        typesToRemove.push(parts[0].substr(9));
      }
    }
  }

  if (!typesToRemove.length) {
    // Not found.
    return sdpLines.join('\r\n');
  }

  // Also find any rtx which reference the removed codec.
  for (i = mLineIndex; i < sdpLines.length; i++) {
    if (sdpLines[i].indexOf('a=fmtp:') === 0) {
      parts = sdpLines[i].split(' ');
      for (j = 0; j < typesToRemove.length; j++) {
        if (parts.length === 2 && parts[1] === `apt=${typesToRemove[j]}`) {
          typesToRemove.push(parts[0].substr(7));
        }
      }
    }
  }

  // Remove any rtpmap, fmtp or rtcp-fb.
  sdpLines = sdpLines.filter((line) => {
    for (let i = 0; i < typesToRemove.length; i++) {
      if (line.indexOf(`a=rtpmap:${typesToRemove[i]} `) === 0 ||
          line.indexOf(`a=fmtp:${typesToRemove[i]} `) === 0 ||
          line.indexOf(`a=rtcp-fb:${typesToRemove[i]} `) === 0) {
        return false;
      }
    }
    return true;
  });

  if (typesToRemove.length > 0 && mLineIndex > -1) {
    // Remove all the payload types and we've removed from the media line
    sdpLines[mLineIndex] = SDPHelpers.removeTypesFromMLine(mLine, typesToRemove);
  }

  return sdpLines.join('\r\n');
};

SDPHelpers.disableMediaType = function disableMediaType(sdp, mediaType) {
  const lines = sdp.split('\r\n');

  const blocks = [];
  let block;

  // Separating SDP into blocks. This usually follows the form:
  // Header block:
  //   v=0
  //   ...
  // Audio block:
  //   m=audio
  //   ...
  // Video block:
  //   m=video
  //   ...

  lines.forEach((lineParam) => {
    let line = lineParam;

    if (/^m=/.test(line)) {
      block = undefined;
    }

    if (!block) {
      block = [];
      blocks.push(block);
    }

    block.push(line);
  });

  // Now disable the block for the specified media type

  const mLineRegex = new RegExp(`^m=${mediaType} \\d+ ([^ ]+) [0-9 ]+$`);

  const fixedBlocks = blocks.map((block) => {
    const match = block[0].match(mLineRegex);

    if (match) {
      return [
        `m=${mediaType} 0 ${match[1]} 0`,
        'a=inactive',
        ...block.filter(line =>
          /^c=/.test(line) ||
          /^a=mid:/.test(line) ||
          line === '' // This preserves the trailing newline
        ),
      ];
    }

    return block;
  });

  return [].concat(...fixedBlocks).join('\r\n');
};

SDPHelpers.removeVideoCodec = function removeVideoCodec(sdp, codec) {
  return SDPHelpers.removeMediaEncoding(sdp, 'video', codec);
};

// Used to identify whether Video media (for a given set of SDP) supports
// retransmissions.
//
// The algorithm to do could be summarised as:
//
// IF ssrc-group:FID exists AND IT HAS AT LEAST TWO IDS THEN
//    we are using RTX
// ELSE IF "a=rtpmap: (\\d+):rtxPayloadId(/\\d+)? rtx/90000"
//          AND SDPHelpers.hasMLinePayloadType(sdpLines, 'Video', rtxPayloadId)
//    we are using RTX
// ELSE
//    we are not using RTX
//
// The ELSE IF clause basically covers the case where ssrc-group:FID
// is probably malformed or missing. In that case we verify whether
// we want RTX by looking at whether it's mentioned in the video
// media line instead.
//
const isUsingRTX = function isUsingRTX(sdpLines, videoAttrs) {
  let groupFID = videoAttrs.filterByName('ssrc-group:FID');
  const missingFID = groupFID.length === 0;

  if (!missingFID) {
    groupFID = groupFID[0].value.split(' ');
  } else {
    groupFID = [];
  }

  switch (groupFID.length) {
    case 0:
    case 1:
      // possibly no RTX, double check for the RTX payload type and that
      // the Video Media line contains that payload type
      //
      // Details: Look for a rtpmap line for rtx/90000
      //  If there is one, grab the payload ID for rtx
      //    Look to see if that payload ID is listed under the payload types for the m=Video line
      //      If it is: RTX
      //  else: No RTX for you

      var rtxAttr = videoAttrs.find(attr => attr.name.indexOf('rtpmap:') === 0 &&
               attr.value.indexOf('rtx/90000') > -1);

      if (!rtxAttr) {
        return false;
      }

      var rtxPayloadId = rtxAttr.name.split(':')[1];
      if (rtxPayloadId.indexOf('/') > -1) { rtxPayloadId = rtxPayloadId.split('/')[0]; }
      return SDPHelpers.hasMLinePayloadType(sdpLines, 'video', rtxPayloadId);

    default:
      // two or more: definitely RTX
      logging.debug('SDP Helpers: There are more than two FIDs, RTX is definitely enabled');
      return true;
  }
};

// This returns an Array, which is decorated with several
// SDP specific helper methods.
//
SDPHelpers.getAttributesForMediaType = function getAttributesForMediaType(sdpLines, mediaType) {
  let ssrcStartIndex,
    ssrcEndIndex,
    regResult,
    ssrc,
    ssrcGroup,
    msidMatch,
    msid,
    mid,
    midIndex;
  const mLineIndex = SDPHelpers.getMLineIndex(sdpLines, mediaType);
  const matchOtherMLines = new RegExp(`m=(?!${mediaType}).+ `, 'i');
  const matchSSRCLines = new RegExp('a=ssrc:\\d+ .*', 'i');
  const matchSSRCGroup = new RegExp('a=ssrc-group:FID (\\d+).*?', 'i');
  const matchAttrLine = new RegExp('a=([a-z0-9:/-]+) (.*)', 'i');
  const attrs = [];

  for (let i = mLineIndex + 1; i < sdpLines.length; i++) {
    if (matchOtherMLines.test(sdpLines[i])) {
      break;
    }

    // Get the ssrc
    ssrcGroup = sdpLines[i].match(matchSSRCGroup);
    if (ssrcGroup) {
      ssrcStartIndex = i;
      ssrc = ssrcGroup[1];
    }

    // Get the msid
    msidMatch = sdpLines[i].match(`a=ssrc:${ssrc} msid:(.+)`);
    if (msidMatch) {
      msid = msidMatch[1];
    }

    // find where the ssrc lines end
    const isSSRCLine = matchSSRCLines.test(sdpLines[i]);
    if (ssrcStartIndex !== undefined && ssrcEndIndex === undefined && !isSSRCLine ||
      i === sdpLines.length - 1) {
      ssrcEndIndex = i;
    }

    const midMatch = sdpLines[i].match(/a=mid:(.+)/);
    if (midMatch) {
      mid = midMatch[1];
      midIndex = i;
    }

    regResult = matchAttrLine.exec(sdpLines[i]);
    if (regResult && regResult.length === 3) {
      attrs.push({
        lineIndex: i,
        name: regResult[1],
        value: regResult[2],
      });
    }
  }

  // / The next section decorates the attributes array
  // / with some useful helpers.

  // Store references to the start and end indices
  // of the media section for this mediaType
  attrs.ssrcStartIndex = ssrcStartIndex;
  attrs.ssrcEndIndex = ssrcEndIndex;
  attrs.msid = msid;

  attrs.mid = mid;
  attrs.midIndex = midIndex;

  // Add .find to the array because IE11 - REMOVE-IE11
  if (!Array.prototype.find) {
    attrs.find = find.bind(undefined, attrs);
  }

  attrs.isUsingRTX = isUsingRTX.bind(null, sdpLines, attrs);

  attrs.filterByName = function (name) {
    return this.filter(attr => attr.name === name);
  };

  attrs.getRtpNumber = (mediaEncoding) => {
    const namePattern = new RegExp('rtpmap:(.+)');

    return find(attrs.map((attr) => {
      const nameMatch = attr.name.match(namePattern);
      if (nameMatch && attr.value.indexOf(mediaEncoding) >= 0) {
        return nameMatch[1];
      }
      return null;
    }), attr => attr !== null);
  };

  return attrs;
};

const modifyStereo = (type, sdp, enable) => {
  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'audio')) {
    logging.debug('No audio m-line, not enabling stereo.');
    return sdp;
  }
  const audioAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'audio');

  const rtpNumber = audioAttrs.getRtpNumber('opus');
  if (!rtpNumber) {
    logging.debug('Could not find rtp number for opus, not enabling stereo.');
    return sdp;
  }

  const fmtpAttr = audioAttrs.find(attr => attr.name === `fmtp:${rtpNumber}`);

  if (!fmtpAttr) {
    logging.debug('Could not find a=fmtp line for opus, not enabling stereo.');
    return sdp;
  }

  let line = sdpLines[fmtpAttr.lineIndex];
  let pattern;

  switch (type) {
    case 'send':
      pattern = /sprop-stereo=\d+(\s*;?\s*)/;
      if (pattern.test(fmtpAttr.value)) {
        line = line.replace(pattern, enable ? 'sprop-stereo=1$1' : '');
      } else if (enable) {
        line += '; sprop-stereo=1';
      }
      break;

    case 'receive':
      pattern = /([^-])stereo=\d+(\s*;?\s*)/;
      if (pattern.test(fmtpAttr.value)) {
        line = line.replace(pattern, enable ? '$1stereo=1$2' : '$1');
      } else if (enable) {
        line += '; stereo=1';
      }
      break;

    default:
      throw new Error(`Invalid type ${type} passed into enableStereo`);
  }

  // Trim any trailing whitespace and semicolons
  line = line.replace(/[;\s]*$/, '');

  sdpLines[fmtpAttr.lineIndex] = line;

  return sdpLines.join('\r\n');
};

SDPHelpers.modifySendStereo = modifyStereo.bind(null, 'send');
SDPHelpers.modifyReceiveStereo = modifyStereo.bind(null, 'receive');

SDPHelpers.setAudioBitrate = (sdp, audioBitrate) => {
  const existingValue = SDPHelpers.getAudioBitrate(sdp);
  if (existingValue !== undefined) {
    logging.debug(`Audio bitrate already set to ${existingValue}, not setting audio bitrate`);
    return sdp;
  }
  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'audio')) {
    logging.debug('No audio m-line, not setting audio bitrate.');
    return sdp;
  }
  const audioAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'audio');

  if (!audioAttrs.midIndex) {
    logging.debug('No audio mid line, not setting audio bitrate.');
    return sdp;
  }

  // SDP expects audio bitrate in kbit/s
  const audioBitrateKbps = Math.floor(audioBitrate / 1000);
  sdpLines.splice(audioAttrs.midIndex + 1, 0, `b=AS:${audioBitrateKbps}`);

  return sdpLines.join('\r\n');
};

SDPHelpers.hasSendStereo = sdp => /[\s;]sprop-stereo=1/.test(sdp);
SDPHelpers.getAudioBitrate = (sdp) => {
  const result = sdp.match(/\sb=AS:(\d+)/);
  if (result) {
    return Number(result[1]) * 1000;
  }
  return undefined;
};

// Modifies +sdp+ to enable Simulcast for +numberOfStreams+.
//
// Ok, here's the plan:
//  - add the 'a=ssrc-group:SIM' line, it will have numberOfStreams ssrcs
//  - if RTX then add one 'a=ssrc-group:FID', we need to add numberOfStreams lines
//  - add numberOfStreams 'a=ssrc:...' lines for the media ssrc
//  - if RTX then add numberOfStreams 'a=ssrc:...' lines for the RTX ssrc
//
// Re: media and rtx ssrcs:
// We just generate these. The Mantis folk would like us to use sequential numbers
// here for ease of debugging. We can use the same starting number each time as well.
// We should confirm with Oscar/Jose that whether we need to verify that the numbers
// that we choose don't clash with any other ones in the SDP.
//
// I think we do need to check but I can't remember.
//
// Re: The format of the 'a=ssrc:' lines
// Just use the following pattern:
//   a=ssrc:<Media or RTX SSRC> cname:localCname
//   a=ssrc:<Media or RTX SSRC> msid:<MSID>
//
// It doesn't matter that they are all the same and are static.
//
//
SDPHelpers.enableSimulcast = function enableSimulcast(sdp, numberOfStreams) {
  let linesToAdd,
    i;
  const sdpLines = sdp.split('\r\n');
  if (!SDPHelpers.getMLine(sdpLines, 'video')) {
    logging.debug('No video m-line, not enabling simulcast.');
    return sdp;
  }
  const videoAttrs = SDPHelpers.getAttributesForMediaType(sdpLines, 'video');

  if (videoAttrs.filterByName('ssrc-group:SIM').length > 0) {
    logging.debug('Simulcast is already enabled in this SDP, not attempting to enable again.');
    return sdp;
  }

  if (!videoAttrs.msid) {
    logging.debug('No local stream attached, not enabling simulcast.');
    return sdp;
  }

  const usingRTX = videoAttrs.isUsingRTX();
  const mediaSSRC = [];
  const rtxSSRC = [];

  // generate new media (and rtx if needed) ssrcs
  for (i = 0; i < numberOfStreams; ++i) {
    mediaSSRC.push(START_MEDIA_SSRC + i);
    if (usingRTX) { rtxSSRC.push(START_RTX_SSRC + i); }
  }

  linesToAdd = [
    `a=ssrc-group:SIM ${mediaSSRC.join(' ')}`,
  ];

  if (usingRTX) {
    for (i = 0; i < numberOfStreams; ++i) {
      linesToAdd.push(`a=ssrc-group:FID ${mediaSSRC[i]} ${rtxSSRC[i]}`);
    }
  }

  for (i = 0; i < numberOfStreams; ++i) {
    linesToAdd.push(`a=ssrc:${mediaSSRC[i]} cname:localCname`,
      `a=ssrc:${mediaSSRC[i]} msid:${videoAttrs.msid}`);
  }

  if (usingRTX) {
    for (i = 0; i < numberOfStreams; ++i) {
      linesToAdd.push(`a=ssrc:${rtxSSRC[i]} cname:localCname`,
        `a=ssrc:${rtxSSRC[i]} msid:${videoAttrs.msid}`);
    }
  }

  // Replace the previous video ssrc section with our new video ssrc section by
  // deleting the old ssrcs section and inserting the new lines
  linesToAdd.unshift(videoAttrs.ssrcStartIndex, videoAttrs.ssrcEndIndex -
    videoAttrs.ssrcStartIndex);
  sdpLines.splice(...linesToAdd);

  return sdpLines.join('\r\n');
};

SDPHelpers.reprioritizeVideoCodec = function reprioritizeVideoCodec(sdp, codec, location) {
  const lines = sdp.split('\r\n');

  const mLineIndex = SDPHelpers.getMLineIndex(lines, 'video');

  if (mLineIndex === -1) {
    return sdp;
  }

  const payloadTypes = SDPHelpers.getMLinePayloadTypes(lines[mLineIndex], 'video');

  const regex = new RegExp(`^a=rtpmap:(\\d+).* ${codec}`, 'i');
  const codecMatches = lines.map(line => line.match(regex)).filter(match => match !== null);

  if (codecMatches.length === 0) {
    return sdp;
  }

  const codecTypeCodes = codecMatches.map(match => match[1]);

  let newPayloadTypes = payloadTypes.filter(t => codecTypeCodes.indexOf(t) === -1);

  if (location === 'top') {
    newPayloadTypes.unshift(...codecTypeCodes);
  } else if (location === 'bottom') {
    newPayloadTypes.push(...codecTypeCodes);
  } else {
    logging.error(`Unexpected location param: ${location}; not changing ${codec} priority`);
    newPayloadTypes = payloadTypes;
  }

  const newMLine = lines[mLineIndex].replace(payloadTypes.join(' '), newPayloadTypes.join(' '));
  lines[mLineIndex] = newMLine;

  return lines.join('\r\n');
};

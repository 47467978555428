// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-prototype-builtins, no-restricted-syntax, one-var, no-void */

const assign = require('lodash/assign');
const meta = require('./meta.js');
const createPluginProxy = require('./create_plugin_proxy');
const makeMediaPeerProxy = require('./make_media_peer_proxy');
const makeMediaCapturerProxy = require('./make_media_capturer_proxy');

const Proto = function PluginProxies() {};
const pluginProxies = new Proto();
const proxies = {};

// Private API

// This is called whenever a Proxy's destroy event fires.
const cleanupProxyOnDestroy = function cleanupProxyOnDestroy(object) {
  if (pluginProxies.mediaCapturer && pluginProxies.mediaCapturer.id === object.id) {
    pluginProxies.mediaCapturer = null;
  } else if (proxies.hasOwnProperty(object.id)) {
    delete proxies[object.id];
  }

  if (object.OTHelpers) {
    object.OTHelpers.remove();
  }
};

// Public API

// Public accessor for the MediaCapturer
pluginProxies.mediaCapturer = null;

pluginProxies.removeAll = function removeAll() {
  for (const id in proxies) {
    if (proxies.hasOwnProperty(id)) {
      proxies[id].destroy();
    }
  }

  if (pluginProxies.mediaCapturer) {
    pluginProxies.mediaCapturer.destroy();
  }
};

pluginProxies.create = function create(options, completion) {
  const proxy = createPluginProxy(options, completion);

  proxies[proxy.uuid] = proxy;

  // Clean up after this Proxy when it's destroyed.
  proxy.on('destroy', () => {
    cleanupProxyOnDestroy(proxy);
  });

  return proxy;
};

/**
 * Create peer connection in plugin
 *
 * @param {object} options
 * @param {function} completion
 */
pluginProxies.createMediaPeer = function createMediaPeer(options = {}, completion) {
  const mediaPeer = pluginProxies.create(assign(options || {}, {
    mimeType: meta.mimeType,
    isVisible: true,
    windowless: true,
  }), (err) => {
    if (err) {
      completion.call(void 0, err);
      return;
    }

    proxies[mediaPeer.id] = mediaPeer;
    completion.call(void 0, void 0, mediaPeer);
  });

  makeMediaPeerProxy(mediaPeer);
};

pluginProxies.createMediaCapturer = function createMediaCapturer(completion) {
  // TODO: This violates the always sync or always async idiom.

  if (pluginProxies.mediaCapturer) {
    completion.call(void 0, void 0, pluginProxies.mediaCapturer);
    return;
  }

  pluginProxies.mediaCapturer = pluginProxies.create({
    mimeType: meta.mimeType,
    isVisible: false,
    windowless: false,
  }, (err) => {
    completion.call(void 0, err, pluginProxies.mediaCapturer);
  });

  makeMediaCapturerProxy(pluginProxies.mediaCapturer);
};

module.exports = pluginProxies;

const DefaultNativeVideoElementWrapperFactory = require('./NativeVideoElementWrapper/NativeVideoElementWrapper');
const DefaultPluginVideoElementWrapper = require('./plugin_video_element_wrapper');
const DefaultOTPlugin = require('../../otplugin/otplugin');

/**
 * VideoElementFacadeFactory DI Container for VideoElementFacade
 *
 * @package
 * @param {Object} [dependencies]
 * @param {typeof NativeVideoElementWrapper} [dependencies.NativeVideoElementWrapper]
 * @param {typeof PluginVideoElementWrapper} [dependencies.PluginVideoElementWrapper]
 * @param {Object} [dependencies.OTPlugin]
 * @param {Object} [dependencies.logging]
 * @return {typeof VideoElementFacade}
 */
function VideoElementFacadeFactory({
  NativeVideoElementWrapper = DefaultNativeVideoElementWrapperFactory(),
  PluginVideoElementWrapper = DefaultPluginVideoElementWrapper,
  OTPlugin = DefaultOTPlugin,
} = {}) {
  const VideoElementWrapper = OTPlugin.isInstalled() ?
    PluginVideoElementWrapper : NativeVideoElementWrapper;
  const defaultAudioVolume = 50;

  /**
   * Create a new VideoElementFacade
   *
   * Used to simplify the strategy between Plugin and Native
   *
   * Note: Could VideoElementFacade not exist? Could it just be an expected interface
   * for VideoElementWrapper which could be either PluginVideoElementWrapper or
   * NativeVideoElementWrapper
   *
   * @package
   * @class
   * @param {Object} [config]
   * @param {String} [config.fallbackText] displayed when WebRTC is not supported
   * @param {Boolean} [config.muted] initial mute setting
   * @param {String} [config.fitMode] fitMode a parameter passed to VideoElementWrapper
   * @param {Object} [config._inject] @todo use the DI system
   * @param {Function} [errorHandler=() => {}] error callback function
   */
  function VideoElementFacade({
    fallbackText = 'Sorry, Web RTC is not available in your browser',
    fitMode,
    muted, // used if it's a publisher
    _inject,
  } = {}) {
    return new VideoElementWrapper({
      fallbackText,
      fitMode,
      muted,
      defaultAudioVolume,
      _inject,
    });
  }
  return VideoElementFacade;
}

module.exports = VideoElementFacadeFactory;

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-confusing-arrow */


const assert = require('assert');

const capitalize = require('lodash/capitalize');

const defaultLogging = require('../../helpers/log')('normalizeConstraintInput');

const validResolutions = {
  '320x240': { width: 320, height: 240 },
  '320x180': { width: 320, height: 180 },
  '640x480': { width: 640, height: 480 },
  '640x360': { width: 640, height: 360 },
  '1280x720': { width: 1280, height: 720 },
  '1280x960': { width: 1280, height: 960 },
};

const validFrameRates = [1, 7, 15, 30];

function getLogging(opt) {
  return (opt._inject && opt._inject.logging) || defaultLogging;
}

function lookupParam(name, param, lookup, possibilities, logging) {
  if (param === undefined) {
    return undefined;
  }

  const result = lookup(param);

  if (!result) {
    logging.warn(
      `Invalid ${name
      } passed to the Publisher. Got: ${param
      }, expecting one of ${possibilities}`
    );
  }

  return result;
}

function normalizeMediaParams(mediaType, opt) {
  const logging = getLogging(opt);

  const publish = (() => {
    const param = opt[`publish${capitalize(mediaType)}`];

    if (param !== undefined) {
      return Boolean(param);
    }

    return true;
  })();

  let source = (() => {
    const param = opt[`${mediaType}Source`];

    if (param === undefined) {
      return true;
    }

    if (param === null || param === false) {
      return false;
    }

    if (typeof param === 'object') {
      if (opt[`isCustom${capitalize(mediaType)}Track`]) {
        return false;
      }
      if (typeof param.deviceId !== 'string') {
        logging.warn(
          `Invalid ${mediaType}Source passed to Publisher. Expected either a device ID or ` +
          'device.'
        );

        return true;
      }

      return param.deviceId;
    }

    if (typeof param !== 'string') {
      logging.warn(`Ignoring invalid ${mediaType}Source parameter`);
      return true;
    }

    return param;
  })();

  if (mediaType === 'audio' && opt.isScreenSharing && source !== false) {
    if (opt.audioSource) {
      getLogging(opt).warn(
        'Invalid audioSource passed to Publisher - when using screen sharing no audioSource may be ' +
        'used'
      );
    }

    source = false;
  }

  return { source, publish };
}

function fixChromeBug707118(env, frameRate) {
  // OPENTOK-32592: 1fps with facetime camera causes chrome to lock up intermittently. Workaround
  // is just to use 2fps instead for now.
  // https://bugs.chromium.org/p/chromium/issues/detail?id=707118
  if (env.name === 'Chrome' && env.userAgent.indexOf('Mac OS X') !== -1 && frameRate === 1) {
    return 2;
  }

  return frameRate;
}

const validFacingModes = ['user', 'environment', 'left', 'right'];
const normaliseFacingMode = (facingMode, opt) => {
  if (facingMode === undefined || validFacingModes.indexOf(facingMode) >= 0) {
    return facingMode;
  }

  const logging = getLogging(opt);

  // Ignore facingMode if its defined but an invalid value
  logging.warn(
    `Invalid facingMode passed to Publisher. Expected one of: ${validFacingModes.join(', ')}`
  );
  return undefined;
};

module.exports = function normalizeConstraintInput(opt) {
  const logging = getLogging(opt);

  const env = opt.env || {};

  const { isScreenSharing, isCustomAudioTrack, isCustomVideoTrack } = opt;

  if (isScreenSharing) {
    assert(
      opt.usingOptionalMandatoryStyle,
      'Screensharing only uses optional-mandatory constraints right now'
    );
  }

  let prefixedAudioConstraints;
  if (opt.disableAudioProcessing && env.name === 'Firefox') {
    prefixedAudioConstraints = {
      mozAutoGainControl: false,
      mozNoiseSupression: false,
    };
  }

  return {
    isScreenSharing,
    isCustomAudioTrack,
    isCustomVideoTrack,

    // These will be of the form { source: string | boolean, publish: boolean }
    audio: normalizeMediaParams('audio', opt),
    video: normalizeMediaParams('video', opt),

    usingOptionalMandatoryStyle: opt.usingOptionalMandatoryStyle,
    enableRenegotiation: opt.enableRenegotiation,
    enableStereo: opt.enableStereo,
    disableAudioProcessing: opt.disableAudioProcessing,
    prefixedAudioConstraints,

    videoDimensions: lookupParam(
      'resolution',
      opt.resolution,
      param => validResolutions[param],
      `"${Object.keys(validResolutions).join('", "')}"`,
      logging
    ),

    frameRate: fixChromeBug707118(env, lookupParam(
      'frameRate',
      opt.frameRate,
      param => validFrameRates.indexOf(param) !== -1 ? param : undefined,
      validFrameRates.join(','),
      logging
    )),

    maxResolution: (function () {
      if (opt.maxResolution === undefined) {
        return isScreenSharing ? { width: 1920, height: 1920 } : undefined;
      }

      const result = {
        width: opt.maxResolution.width,
        height: opt.maxResolution.height,
      };

      Object.keys(result).forEach((dimensionName) => {
        const dim = result[dimensionName];

        if (typeof dim !== 'number' || dim > 1920) {
          logging.warn(
            `Invalid maxResolution passed to the Publisher. maxResolution.${dimensionName
            } must be less than or equal to 1920`
          );

          result[dimensionName] = 1920;
        }
      });

      return result;
    }()),

    facingMode: normaliseFacingMode(opt.facingMode, opt),
  };
};

/* eslint-disable no-underscore-dangle */

const logging = require('../helpers/log')('VideoContainer');
const OTHelpers = require('../common-js-helpers/OTHelpers.js');

module.exports = class VideoContainer {
  constructor(plugin, stream) {
    this._audioInputLevelMonitoringEnabled = false;

    this.plugin = plugin;
    this.domElement = plugin._;
    this.OTHelpers = OTHelpers(plugin._);
    this.parentElement = plugin._.parentNode;
    this.stream = stream;

    plugin.refCounter.add(this);
  }
  appendTo(parentDomElement) {
    if (parentDomElement && this.domElement.parentNode !== parentDomElement) {
      logging.debug('VideoContainer appendTo', parentDomElement);
      parentDomElement.appendChild(this.domElement);
      this.parentElement = parentDomElement;
    }
  }
  show(completion) {
    logging.debug('VideoContainer show');
    this.domElement.removeAttribute('width');
    this.domElement.removeAttribute('height');
    this.plugin.setStream(this.stream, completion);
    OTHelpers.show(this.domElement);
    return this;
  }
  setSize(width, height) {
    this.domElement.setAttribute('width', width);
    this.domElement.setAttribute('height', height);
    return this.domElement;
  }
  getAudioInputLevel() {
    // we don't need to expose a mechanism to enable / disable the monitoring
    // so we use this opportunity the keep the API simple
    if (!this._audioInputLevelMonitoringEnabled) {
      this.domElement.enableVolumeLevelMonitoring(true);
      this._audioInputLevelMonitoringEnabled = true;
    }
    return this.domElement.getInputLevel();
  }
  getAudioVolume = () => {
    const track = this.stream.getAudioTracks()[0];
    if (track) {
      return track.getAudioVolume();
    }
    return 0;
  }
  setAudioVolume(value) {
    this.stream.getAudioTracks().forEach((track) => {
      track.setAudioVolume(value);
    });
    return this;
  }
  setFitMode(fitMode) {
    this.domElement.fitMode(fitMode);
  }
  width(newWidth) {
    if (newWidth !== undefined) {
      logging.debug(`VideoContainer set width to ${newWidth}`);
      this.domElement.setAttribute('width', newWidth);
    }

    return this.domElement.getAttribute('width');
  }
  height(newHeight) {
    if (newHeight !== undefined) {
      logging.debug(`VideoContainer set height to ${newHeight}`);
      this.domElement.setAttribute('height', newHeight);
    }

    return this.domElement.getAttribute('height');
  }
  getImgData() {
    return this.domElement.getImgData('image/png');
  }
  videoWidth() {
    return this.domElement.videoWidth;
  }
  videoHeight() {
    return this.domElement.videoHeight;
  }
  destroy() {
    this.domElement.setStream(null);
    this.plugin.refCounter.remove(this);
  }
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-void, no-cond-assign, no-shadow */

const isFunction = require('lodash/isFunction');
const AutoUpdater = require('./auto_updater.js');
const logging = require('../helpers/log')('readiness');
const PluginProxies = require('./plugin_proxies.js');
const domState = require('../helpers/domState.js');

let isReady = false;

const readyCallbacks = [];

// This stores the error from the load attempt. We use
// this if registerReadyListener gets called after a load
// attempt fails.
let loadError;

const destroy = function destroy() {
  PluginProxies.removeAll();
};

const registerReadyListener = function registerReadyListener(callback) {
  if (!isFunction(callback)) {
    logging.warn('registerReadyListener was called with something that was not a function.');
    return;
  }

  if (isReady) {
    callback.call(void 0, loadError);
  } else {
    readyCallbacks.push(callback);
  }
};

const notifyReadyListeners = function notifyReadyListeners() {
  isReady = true;

  let callback;

  while ((callback = readyCallbacks.pop()) && isFunction(callback)) {
    callback.call(void 0, loadError);
  }
};

const onDomReady = function onDomReady() {
  AutoUpdater.get((err, updater) => {
    if (err) {
      loadError = `Error while loading the AutoUpdater: ${err}`;
      notifyReadyListeners();
      return;
    }

    // If the plugin is out of date then we kick off the
    // auto update process and then bail out.
    if (!updater.hasRequiredVersion()) {
      updater.autoUpdate();
      return;
    }

    // Inject the controller object into the page, wait for it to load or timeout...
    PluginProxies.createMediaCapturer((err) => {
      loadError = err;

      if (!loadError && (!PluginProxies.mediaCapturer ||
            !PluginProxies.mediaCapturer.isValid())) {
        loadError = 'The TB Plugin failed to load properly';
      }

      notifyReadyListeners();
    });
  });
};

domState.on('unload', destroy);
domState.whenLoaded.then(onDomReady);

module.exports = {
  listen: registerReadyListener,
  isReady() { return isReady; },
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable prefer-rest-params */


module.exports = function curryCallAsync(fn) {
  return function () {
    const args = arguments;

    setTimeout(() => {
      fn(...args);
    });
  };
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void */


const OTPlugin = require('../../otplugin/otplugin.js');

module.exports = {
  isSupportedInThisBrowser: OTPlugin.isSupported(),
  autoRegisters: true,
  extensionRequired: true,
  getConstraintsShowsPermissionUI: false,
  sources: {
    screen: true,
    application: false,
    window: true,
    browser: false,
  },
  register() {
    return {
      isInstalled(callback) {
        callback(OTPlugin.isInstalled());
      },
      getConstraints(source, constraints, callback) {
        constraints.video.mandatory.chromeMediaSource = source;

        callback(void 0, constraints);
      },
    };
  },
};

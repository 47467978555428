/* eslint-disable no-underscore-dangle */

const EventEmitter = require('events');
const without = require('lodash/without');
const difference = require('lodash/difference');

module.exports = class RefCounter extends EventEmitter {
  constructor() {
    super();
    this._liveObjects = [];
  }
  get count() {
    return this._liveObjects.length;
  }
  forEach(fn) {
    return this._liveObjects.forEach(fn);
  }
  add(ref) {
    this._liveObjects.push(ref);
    this.emit('add', ref);
    return this;
  }
  remove(ref) {
    const liveObjectsWithoutRef = without(this._liveObjects, ref);
    const removedReferences = difference(this._liveObjects, liveObjectsWithoutRef);
    this._liveObjects = liveObjectsWithoutRef;
    removedReferences.forEach(removedRef => this.emit('remove', removedRef));
    return this;
  }
  removeAll() {
    this.forEach(obj => this.remove(obj));
  }
};

const objectTimeouts = {};
const PROXY_LOAD_TIMEOUT = 5000;

function clearGlobalCallback(callbackId) {
  if (!callbackId) {
    // TODO: Is this really a valid call? Would it be better to throw?
  }

  if (objectTimeouts[callbackId]) {
    clearTimeout(objectTimeouts[callbackId]);
    objectTimeouts[callbackId] = null;
  }

  if (global[callbackId]) {
    try {
      delete global[callbackId];
    } catch (err) {
      global[callbackId] = undefined;
    }
  }
}

function waitOnGlobalCallback(callbackId, completion) {
  objectTimeouts[callbackId] = setTimeout(() => {
    clearGlobalCallback(callbackId);
    completion('The object timed out while loading.');
  }, PROXY_LOAD_TIMEOUT);

  global[callbackId] = (...args) => {
    clearGlobalCallback(callbackId);
    args.unshift(null);
    completion(...args);
  };
}

module.exports = waitOnGlobalCallback;

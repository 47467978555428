// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var */

const assert = require('assert');
const promiseFinally = require('promise-finally').default;

// Takes a function of the form function(input?) => promise (one optional argument and returns a
// promise) and returns a function of the same form which prevents concurrent requests by
// serializing them. That is, when there is a pending result and a new request is made, the
// underlying request function is not called until the pending result has been fulfilled.

module.exports = function (request) {
  const queue = [];
  let responsePromise,
    tryNext;

  const serializedRequest = function (input) {
    assert(arguments.length <= 1);

    return new Promise((resolve) => {
      queue.push({
        input,
        resolve,
      });

      tryNext();
    });
  };

  tryNext = function () {
    if (queue.length === 0 || responsePromise) {
      return;
    }

    const job = queue.shift();

    responsePromise = promiseFinally(request(job.input), () => {
      responsePromise = undefined;
      tryNext();
    });

    job.resolve(responsePromise);
  };

  return serializedRequest;
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable import/newline-after-import, prefer-const, no-restricted-syntax */
/* eslint-disable no-prototype-builtins, no-param-reassign, one-var, no-void */


const env = require('../../../helpers/env');
const getCompStyle = global.getComputedStyle;

const VENDOR_PREFIXES = {
  Firefox: 'moz',
  Opera: 'O',
  IE: 'ms',
  Chrome: 'Webkit',
  Safari: 'Webkit',
};


function vendorPrefix() {
  return VENDOR_PREFIXES[env.name];
}

module.exports = function (ElementCollection, findElementWithDisplayNone) {
  const displayStateCache = {};
  const defaultDisplays = {};

  const defaultDisplayValueForElement = function (element) {
    if (defaultDisplays[element.ownerDocument] &&
      defaultDisplays[element.ownerDocument][element.nodeName]) {
      return defaultDisplays[element.ownerDocument][element.nodeName];
    }

    if (!defaultDisplays[element.ownerDocument]) {
      defaultDisplays[element.ownerDocument] = {};
    }

    // We need to know what display value to use for this node. The easiest way
    // is to actually create a node and read it out.
    let testNode = element.ownerDocument.createElement(element.nodeName);
    let defaultDisplay;

    element.ownerDocument.body.appendChild(testNode);
    defaultDisplay = new ElementCollection(testNode).css('display');
    defaultDisplays[element.ownerDocument][element.nodeName] = defaultDisplay;

    new ElementCollection(testNode).remove();
    testNode = null;

    return defaultDisplay;
  };

  const isHidden = function (element) {
    const computedStyle = getCompStyle(element);
    return computedStyle.getPropertyValue('display') === 'none';
  };

  const getNormalisedPropName = function (element, name) {
    if (name in element.style) {
      return name;
    }

    // Try vendored names next
    const prefix = vendorPrefix();
    const capitalName = prefix + name[0].toUpperCase() + name.slice(1);

    if (capitalName in element.style) {
      return capitalName;
    }

    return name;
  };

  const setCssProperties = function (element, hash) {
    const style = element.style;

    for (const cssName in hash) {
      if (hash.hasOwnProperty(cssName)) {
        style[getNormalisedPropName(element, cssName)] = hash[cssName];
      }
    }
  };

  const setCssProperty = function (element, name, value) {
    element.style[getNormalisedPropName(element, name)] = value;
  };

  const getCssProperty = function (element, unnormalisedName) {
    const name = getNormalisedPropName(element, unnormalisedName);
    const computedStyle = getCompStyle(element);
    let currentValue = computedStyle.getPropertyValue(name);

    if (currentValue === '') {
      currentValue = element.style[name];
    }

    return currentValue;
  };

  const applyCSS = function (element, styles, callback) {
    const $element = new ElementCollection(element);
    const oldStyles = {};
    let name,
      ret;

    // Backup the old styles
    for (name in styles) {
      if (styles.hasOwnProperty(name)) {
        // We intentionally read out of style here, instead of using the css
        // helper. This is because the css helper uses querySelector and we
        // only want to pull values out of the style (domeElement.style) hash.
        oldStyles[name] = element.style[name];

        $element.css(name, styles[name]);
      }
    }

    ret = callback(element);

    // Restore the old styles
    for (name in styles) {
      if (styles.hasOwnProperty(name)) {
        $element.css(name, oldStyles[name] || '');
      }
    }

    return ret;
  };

  ElementCollection.prototype.show = function () {
    return this.forEach((element) => {
      const display = element.style.display;

      if (display === '' || display === 'none') {
        element.style.display = displayStateCache[element] || '';
        delete displayStateCache[element];
      }

      if (isHidden(element)) {
        // It's still hidden so there's probably a stylesheet that declares this
        // element as display:none;
        displayStateCache[element] = 'none';

        element.style.display = defaultDisplayValueForElement(element);
      }
    });
  };

  ElementCollection.prototype.hide = function () {
    return this.forEach((element) => {
      if (element.style.display === 'none') {
        return;
      }

      displayStateCache[element] = element.style.display;
      element.style.display = 'none';
    });
  };

  ElementCollection.prototype.css = function (nameOrHash, value) {
    if (this.length === 0) {
      // FIXME: I made this `return undefined` to preserve behaviour, but `return this` is probably
      // more consistent.
      return undefined;
    }

    if (typeof (nameOrHash) !== 'string') {
      return this.forEach((element) => {
        setCssProperties(element, nameOrHash);
      });
    }

    if (value !== undefined) {
      return this.forEach((element) => {
        setCssProperty(element, nameOrHash, value);
      });
    }

    return getCssProperty(this.first, nameOrHash, value);
  };

  // Make +element+ visible while executing +callback+.
  ElementCollection.prototype.makeVisibleAndYield = function (callback) {
    const hiddenVisually = {
      display: 'block',
      visibility: 'hidden',
    };
    const results = [];

    this.forEach((element) => {
      // find whether it's the element or an ancestor that's display none and
      // then apply to whichever it is
      const targetElement = findElementWithDisplayNone(element);
      if (!targetElement) {
        results.push(void 0);
      } else {
        results.push(
          applyCSS(targetElement, hiddenVisually, callback)
        );
      }
    });

    return results;
  };
};

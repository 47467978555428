/* eslint-disable no-underscore-dangle */

const guidStorage = require('./guid_storage.js');
const Analytics = require('../common-js-helpers/analytics');

const SessionInfo = require('../ot/session/SessionInfo');
const StaticConfig = require('../helpers/StaticConfig')();

const eventing = require('./eventing');

const { prependProxyToUrlIfNeeded } = require('../helpers/proxyUrlHelper.js');

const LOG_VERSION = '2';

class AnalyticsHelper {
  constructor({
    staticConfig = StaticConfig.onlyLocal(),
    sessionInfo = new SessionInfo(),
    ajax,
    queue,
    proxyUrl,
  } = {}) {
    this.proxyUrl = proxyUrl;
    this.ajax = ajax;
    this.queue = queue;
    this.sessionInfo = sessionInfo;
    this.staticConfig = staticConfig;
  }

  get staticConfig() {
    return this._staticConfig;
  }

  set staticConfig(staticConfig) {
    this._staticConfig = staticConfig;
    this._analytics = new Analytics({
      loggingUrl: prependProxyToUrlIfNeeded(this.staticConfig.loggingUrl, this.proxyUrl),
      ajax: this.ajax,
      queue: this.queue,
    });
  }

  _getCommon() {
    return {
      clientVersion: this.staticConfig.clientVersion,
      buildHash: this.staticConfig.buildHash,
      source: global.location && global.location.href,
      logVersion: LOG_VERSION,
      apiServer: this.staticConfig.apiUrl,
      clientSystemTime: new Date().getTime(),
      sessionId: this.sessionInfo.sessionId,
      mediaServerName: this.sessionInfo.mediaServerName,
      p2p: this.sessionInfo.p2pEnabled,
      messagingServer: this.sessionInfo.messagingServer,
      messagingUrl: this.sessionInfo.messagingURL,
      version: this.staticConfig.version,
      partnerId: this.sessionInfo.partnerId,
    };
  }

  logError(code, type, message, details, options = {}) {
    guidStorage.get((error, guid) => {
      if (error) {
        // @todo
        return;
      }
      const args = [code, type, message, details, { guid, ...this.combineWithCommon(options) }];
      AnalyticsHelper.emit('logError', ...args);
      this._analytics.logError(...args);
    });
  }

  combineWithCommon(options) {
    return {
      ...this._getCommon(),
      ...options,
    };
  }

  logEvent(options = {}, throttle, completionHandler) {
    guidStorage.get((error, guid) => {
      if (error) {
        // @todo
        return;
      }

      const logData = { guid, ...this.combineWithCommon(options) };
      AnalyticsHelper.emit('logEvent', logData);

      this._analytics.logEvent(
        logData,
        false,
        throttle,
        completionHandler
      );
    });
  }

  logQOS(options = {}) {
    guidStorage.get((error, guid) => {
      if (error) {
        // @todo
        return;
      }
      const qosData = { guid, duration: 0, ...this.combineWithCommon(options) };
      AnalyticsHelper.emit('logQOS', qosData);
      this._analytics.logQOS(qosData);
    });
  }
}

eventing(AnalyticsHelper);

module.exports = AnalyticsHelper;

const Events = require('events');
const isFunction = require('lodash/isFunction');
const logging = require('../helpers/log')('Events');
const assign = require('lodash/assign');
const weakMemoizeBind = require('../helpers/weakMemoizeBind');

/**
 * This base class defines the <code>on</code>, <code>once</code>, and <code>off</code>
 * methods of objects that can dispatch events.
 *
 * @class EventDispatcher
 */
module.exports = function eventing(self) {
  const ee = new Events();

  ee.on('newListener', (eventName) => {
    ee.emit(`${eventName}:added`);
  });

  ee.on('removeListener', (eventName) => {
    ee.emit(`${eventName}:removed`);
  });

  const eventingMixin = {
    /**
     * Adds an event handler function for one or more events.
     *
     * <p>
     * The following code adds an event handler for one event:
     * </p>
     *
     * <pre>
     * obj.on("eventName", function (event) {
     *     // This is the event handler.
     * });
     * </pre>
     *
     * <p>If you pass in multiple event names and a handler method, the handler is
     * registered for each of those events:</p>
     *
     * <pre>
     * obj.on("eventName1 eventName2",
     *        function (event) {
     *            // This is the event handler.
     *        });
     * </pre>
     *
     * <p>You can also pass in a third <code>context</code> parameter (which is optional) to
     * define the value of <code>this</code> in the handler method:</p>
     *
     * <pre>obj.on("eventName",
     *        function (event) {
     *            // This is the event handler.
     *        },
     *        obj);
     * </pre>
     *
     * <p>
     * The method also supports an alternate syntax, in which the first parameter is an object
     * that is a hash map of event names and handler functions and the second parameter (optional)
     * is the context for this in each handler:
     * </p>
     * <pre>
     * obj.on(
     *    {
     *       eventName1: function (event) {
     *               // This is the handler for eventName1.
     *           },
     *       eventName2:  function (event) {
     *               // This is the handler for eventName2.
     *           }
     *    },
     *    obj);
     * </pre>
     *
     * <p>
     * If you do not add a handler for an event, the event is ignored locally.
     * </p>
     *
     * @param {String} type The string identifying the type of event. You can specify multiple event
     * names in this string, separating them with a space. The event handler will process each of
     * the events.
     * @param {Function} handler The handler function to process the event. This function takes
     * the event object as a parameter.
     * @param {Object} context (Optional) Defines the value of <code>this</code> in the event
     * handler function.
     *
     * @returns {EventDispatcher} The EventDispatcher object.
     *
     * @memberOf EventDispatcher
     * @method #on
     * @see <a href="#off">off()</a>
     * @see <a href="#once">once()</a>
     * @see <a href="#events">Events</a>
     */

    on(eventNames, handlerOrContext, context) {
      if (typeof eventNames === 'string' && handlerOrContext) {
        eventNames.split(' ').forEach((eventName) => {
          ee.on(eventName, weakMemoizeBind(handlerOrContext, context));
        });
      } else {
        Object.keys(eventNames)
          .forEach((eventName) => {
            ee.on(eventName, weakMemoizeBind(eventNames[eventName], handlerOrContext));
          });
      }
      return this;
    },

    /**
     * Removes an event handler or handlers.
     *
     * <p>If you pass in one event name and a handler method, the handler is removed for that
     * event:</p>
     *
     * <pre>obj.off("eventName", eventHandler);</pre>
     *
     * <p>If you pass in multiple event names and a handler method, the handler is removed for
     * those events:</p>
     *
     * <pre>obj.off("eventName1 eventName2", eventHandler);</pre>
     *
     * <p>If you pass in an event name (or names) and <i>no</i> handler method, all handlers are
     * removed for those events:</p>
     *
     * <pre>obj.off("event1Name event2Name");</pre>
     *
     * <p>If you pass in no arguments, <i>all</i> event handlers are removed for all events
     * dispatched by the object:</p>
     *
     * <pre>obj.off();</pre>
     *
     * <p>
     * The method also supports an alternate syntax, in which the first parameter is an object that
     * is a hash map of event names and handler functions and the second parameter (optional) is
     * the context for this in each handler:
     * </p>
     * <pre>
     * obj.off(
     *    {
     *       eventName1: event1Handler,
     *       eventName2: event2Handler
     *    });
     * </pre>
     *
     * @param {String} type (Optional) The string identifying the type of event. You can
     * use a space to specify multiple events, as in "accessAllowed accessDenied
     * accessDialogClosed". If you pass in no <code>type</code> value (or other arguments),
     * all event handlers are removed for the object.
     * @param {Function} handler (Optional) The event handler function to remove. The handler
     * must be the same function object as was passed into <code>on()</code>. Be careful with
     * helpers like <code>bind()</code> that return a new function when called. If you pass in
     * no <code>handler</code>, all event handlers are removed for the specified event
     * <code>type</code>.
     * @param {Object} context (Optional) If you specify a <code>context</code>, the event handler
     * is removed for all specified events and handlers that use the specified context. (The
     * context must match the context passed into <code>on()</code>.)
     *
     * @returns {Object} The object that dispatched the event.
     *
     * @memberOf EventDispatcher
     * @method #off
     * @see <a href="#on">on()</a>
     * @see <a href="#once">once()</a>
     * @see <a href="#events">Events</a>
     */
    off(eventNames, handlerOrContext, context) {
      if (typeof eventNames === 'string') {
        this.off(eventNames.split(' '), handlerOrContext, context);
      } else if (Array.isArray(eventNames)) {
        eventNames.forEach((eventName) => {
          if (handlerOrContext && isFunction(handlerOrContext)) {
            ee.removeListener(eventName, weakMemoizeBind(handlerOrContext, context));
          } else {
            ee.removeAllListeners(eventName);
          }
        });
      } else if (!eventNames) {
        ee.removeAllListeners();
      } else {
        Object.keys(eventNames)
          .forEach((eventName) => {
            // @todo was {foo: undefined} expected to remove all foo events?
            ee.removeListener(eventName, weakMemoizeBind(eventNames[eventName], handlerOrContext));
          });
      }

      return this;
    },

    /**
     * Adds an event handler function for one or more events. Once the handler is called,
     * the specified handler method is removed as a handler for this event. (When you use
     * the <code>on()</code> method to add an event handler, the handler is <i>not</i>
     * removed when it is called.) The <code>once()</code> method is the equivilent of
     * calling the <code>on()</code>
     * method and calling <code>off()</code> the first time the handler is invoked.
     *
     * <p>
     * The following code adds a one-time event handler for one event:
     * </p>
     *
     * <pre>
     * obj.once("eventName", function (event) {
     *    // This is the event handler.
     * });
     * </pre>
     *
     * <p>If you pass in multiple event names and a handler method, the handler is registered
     * for each of those events:</p>
     *
     * <pre>obj.once("eventName1 eventName2"
     *          function (event) {
     *              // This is the event handler.
     *          });
     * </pre>
     *
     * <p>You can also pass in a third <code>context</code> parameter (which is optional) to define
     * the value of
     * <code>this</code> in the handler method:</p>
     *
     * <pre>obj.once("eventName",
     *          function (event) {
     *              // This is the event handler.
     *          },
     *          obj);
     * </pre>
     *
     * <p>
     * The method also supports an alternate syntax, in which the first parameter is an object that
     * is a hash map of event names and handler functions and the second parameter (optional) is the
     * context for this in each handler:
     * </p>
     * <pre>
     * obj.once(
     *    {
     *       eventName1: function (event) {
     *                  // This is the event handler for eventName1.
     *           },
     *       eventName2:  function (event) {
     *                  // This is the event handler for eventName1.
     *           }
     *    },
     *    obj);
     * </pre>
     *
     * @param {String} type The string identifying the type of event. You can specify multiple
     * event names in this string, separating them with a space. The event handler will process
     * the first occurence of the events. After the first event, the handler is removed (for
     * all specified events).
     * @param {Function} handler The handler function to process the event. This function takes
     * the event object as a parameter.
     * @param {Object} context (Optional) Defines the value of <code>this</code> in the event
     * handler function.
     *
     * @returns {Object} The object that dispatched the event.
     *
     * @memberOf EventDispatcher
     * @method #once
     * @see <a href="#on">on()</a>
     * @see <a href="#off">off()</a>
     * @see <a href="#events">Events</a>
     */
    once(eventNames, handlerOrContext, context) {
      if (typeof eventNames === 'string' && handlerOrContext) {
        eventNames.split(' ').forEach((eventName) => {
          ee.once(eventName, weakMemoizeBind(handlerOrContext, context));
        });
      } else {
        Object.keys(eventNames)
          .forEach((eventName) => {
            ee.once(eventName, weakMemoizeBind(eventNames[eventName], handlerOrContext));
          });
      }
      return this;
    },

    /**
     * Execute any listeners bound to the +event+ Event.
     *
     * Each handler will be executed.
     *
     * @param [Event] event An Event object.
     * @return this
    */
    dispatchEvent(event) {
      if (!event.type) {
        throw new Error('dispatchEvent: Event has no type');
      }

      if (!event.target) {
        event.target = this; // eslint-disable-line no-param-reassign
      }

      this.trigger(event.type, event);

      return this;
    },

    /**
     * Execute each handler for the event called +name+.
     *
     * Each handler will be executed, and any exceptions that they throw will
     * be caught and logged
     *
     * @example
     * foo.on('bar', function(name, message) {
     *   alert("Hello " + name + ": " + message);
     * });
     *
     * foo.trigger('OpenTok', 'asdf'); // -> Hello OpenTok: asdf
     *
     * @param {String} eventName The name of this event.
     * @param {Array} arguments The arguments handlers will be called with
     *
     * @return this
     */
    trigger(eventName, ...args) {
      try {
        ee.emit(eventName, ...args);
      } catch (e) {
        console.error('An error occurred in an event handler', e);
      }
      return this;
    },

    // Alias of trigger for easier node compatibility
    emit(...args) {
      return self.trigger(...args);
    },

    /**
     * @deprecated use <a href="#on">on()</a> or <a href="#once">once()</a> instead.
     * <p>
     * This method registers a method as an event listener for a specific event.
     * <p>
     *
     * <p>
     *   If a handler is not registered for an event, the event is ignored locally. If the
     *   event listener function does not exist, the event is ignored locally.
     * </p>
     * <p>
     *   Throws an exception if the <code>listener</code> name is invalid.
     * </p>
     *
     * @param {String} type The string identifying the type of event.
     *
     * @param {Function} listener The function to be invoked when the object dispatches the event.
     *
     * @param {Object} context (Optional) Defines the value of <code>this</code> in the event
     * handler function.
     *
     * @memberOf EventDispatcher
     * @method #addEventListener
     * @see <a href="#on">on()</a>
     * @see <a href="#once">once()</a>
     * @see <a href="#events">Events</a>
    */

    addEventListener(eventName, handler, context) {
      // @todo this shows a warning in IE because the tracks use this event system to communicate
      // the ended event and possibly others.
      logging.warn('The addEventListener() method is deprecated. Use on() or once() instead.');
      return self.on(eventName, handler, context);
    },

    /**
     * @deprecated use <a href="#off">off()</a> instead.
     * <p>
     * Removes an event listener for a specific event.
     * <p>
     *
     * <p>
     *   Throws an exception if the <code>listener</code> name is invalid.
     * </p>
     *
     * @param {String} type The string identifying the type of event.
     *
     * @param {Function} listener The event listener function to remove.
     *
     * @param {Object} context (Optional) If you specify a <code>context</code>, the event
     * handler is removed for all specified events and event listeners that use the specified
     context. (The context must match the context passed into
     * <code>addEventListener()</code>.)
     *
     * @memberOf EventDispatcher
     * @method #removeEventListener
     * @see <a href="#off">off()</a>
     * @see <a href="#events">Events</a>
     */
    // See 'off' for usage.
    // @depreciated will become a private helper function in the future.
    removeEventListener(eventName, handler, context) {
      // @todo this shows a warning in IE because the tracks use this event system to communicate
      // the ended event and possibly others.

      logging.warn('The removeEventListener() method is deprecated. Use off() instead.');
      return self.off(eventName, handler, context);
    },

    listenerCount(eventName) {
      return ee.listenerCount(eventName);
    },
  };

  return assign(self, eventingMixin);
};

/* eslint-disable global-require, func-names */
/* eslint-disable no-use-before-define, no-prototype-builtins, no-underscore-dangle */
/* global MediaStreamTrack */

// @todo need to ensure logging for peer disconnected, and peer failures is intact

const get = require('lodash/get');
const assert = require('assert');
const assign = require('lodash/assign');
const cloneDeep = require('lodash/cloneDeep');
const find = require('lodash/find');
const pick = require('lodash/pick');
const once = require('lodash/once');
const uuid = require('uuid');
const capitalize = require('lodash/capitalize');
const WeakMap = require('es6-weak-map');
const { CancellationError, default: Cancellation } = require('cancel');
const env = require('../../helpers/env');
const setEncodersActiveStateDefault = require('./setEncodersActiveState');

const promisify = require('../../helpers/promisify');
const getStatsHelpers = require('../peer_connection/get_stats_helpers');
const eventNames = require('../../helpers/eventNames');
const eventing = require('../../helpers/eventing');
const Event = require('../../helpers/Event');
const AnalyticsHelperDefault = require('../../helpers/analytics');
const IntervalRunnerDefault = require('../interval_runner.js');
const createCleanupJobs = require('../../helpers/createCleanupJobs.js');
const whitelistPublisherProperties = require('./whitelistPublisherProperties');
const defaultWidgetView = require('../../helpers/widget_view')();
const audioLevelBehaviour = require('./audioLevelBehaviour');
const blockCallsUntilComplete = require('../../helpers/blockCallsUntilComplete');
const unblockAudio = require('../unblockAudio');
const { getMediaDevices } = require('../../helpers/device_helpers')();

module.exports = function PublisherFactory({
  FORCE_DISCONNECT_OLD_PEER_CONNECTIONS_DELAY = 30000,
  ...deps
} = {}) {
  ['processPubOptions'].forEach((key) => {
    assert(deps[key], `${key} dependency must be injected into Publisher`);
  });

  const AnalyticsHelper = deps.AnalyticsHelper || AnalyticsHelperDefault;
  const calculateCapableSimulcastStreams = deps.calculateCapableSimulcastStreams || require('./calculateCapableSimulcastStreams.js');
  const createChromeMixin = deps.createChromeMixin || require('./createChromeMixin.js')();
  const deviceHelpers = deps.deviceHelpers || require('../../helpers/device_helpers.js')();
  const EnvironmentLoader = deps.EnvironmentLoader || require('../environment_loader.js');
  const Errors = deps.Errors || require('../Errors.js');
  const Events = deps.Events || require('../events.js')();
  const ExceptionCodes = deps.ExceptionCodes || require('../exception_codes.js');
  const interpretPeerConnectionError = deps.interpretPeerConnectionError || require('../interpretPeerConnectionError.js')();
  const IntervalRunner = deps.IntervalRunner || IntervalRunnerDefault;
  const logging = deps.logging || require('../../helpers/log')('Publisher');
  const Microphone = deps.Microphone || require('./microphone.js')();
  const otError = deps.otError || require('../../helpers/otError.js')();
  const OTErrorClass = deps.OTErrorClass || require('../ot_error_class.js');
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const parseIceServers = deps.parseIceServers || require('../../RaptorSession/raptor/parseIceServers.js').parseIceServers;
  const PUBLISH_MAX_DELAY = deps.PUBLISH_MAX_DELAY || require('./max_delay.js');
  const PublisherPeerConnection = deps.PublisherPeerConnection || require('../peer_connection/publisher_peer_connection.js')();
  const PublishingState = deps.PublishingState || require('./state.js')();
  const StreamChannel = deps.StreamChannel || require('../stream_channel.js');
  const systemRequirements = deps.systemRequirements || require('../system_requirements.js');
  const VideoOrientation = deps.VideoOrientation || require('../../helpers/video_orientation.js')();
  const WidgetView = deps.WidgetView || defaultWidgetView;
  const windowMock = deps.global || global;
  const createSendMethod = deps.createSendMethod || require('../session/createSendMethod');
  const setEncodersActiveState = deps.setEncodersActiveState || setEncodersActiveStateDefault;

  const { processPubOptions } = deps;

  /**
   * The Publisher object  provides the mechanism through which control of the
   * published stream is accomplished. Calling the <code>OT.initPublisher()</code> method
   * creates a Publisher object. </p>
   *
   *  <p>The following code instantiates a session, and publishes an audio-video stream
   *  upon connection to the session: </p>
   *
   *  <pre>
   *  var apiKey = ''; // Replace with your API key. See https://tokbox.com/account
   *  var sessionID = ''; // Replace with your own session ID.
   *                      // See https://tokbox.com/developer/guides/create-session/.
   *  var token = ''; // Replace with a generated token that has been assigned the moderator role.
   *                  // See https://tokbox.com/developer/guides/create-token/.
   *
   *  var session = OT.initSession(apiKey, sessionID);
   *  session.connect(token, function(error) {
   *    if (error) {
   *      console.log(error.message);
   *    } else {
   *      // This example assumes that a DOM element with the ID 'publisherElement' exists
   *      var publisherProperties = {width: 400, height:300, name:"Bob's stream"};
   *      publisher = OT.initPublisher('publisherElement', publisherProperties);
   *      session.publish(publisher);
   *    }
   *  });
   *  </pre>
   *
   *      <p>This example creates a Publisher object and adds its video to a DOM element
   *      with the ID <code>publisherElement</code> by calling the <code>OT.initPublisher()</code>
   *      method. It then publishes a stream to the session by calling
   *      the <code>publish()</code> method of the Session object.</p>
   *
   * @property {Boolean} accessAllowed Whether the user has granted access to the camera
   * and microphone. The Publisher object dispatches an <code>accessAllowed</code> event when
   * the user grants access. The Publisher object dispatches an <code>accessDenied</code> event
   * when the user denies access.
   * @property {Element} element The HTML DOM element containing the Publisher. (<i>Note:</i>
   * when you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to
   * <a href="OT.html#initPublisher">OT.initPublisher</a>, the <code>element</code> property
   * is undefined.)
   * @property {String} id The DOM ID of the Publisher.
   * @property {Stream} stream The {@link Stream} object corresponding the stream of
   * the Publisher.
   * @property {Session} session The {@link Session} to which the Publisher belongs.
   *
   * @see <a href="OT.html#initPublisher">OT.initPublisher</a>
   * @see <a href="Session.html#publish">Session.publish()</a>
   *
   * @class Publisher
   * @augments EventDispatcher
   */
  const Publisher = function Publisher(options = {}) {
    let privateEvents = eventing({});

    const peerConnectionMetaMap = new WeakMap();

    /**
     * @typedef {Object} peerConnectionMeta
     * @property {String} remoteConnectionId The connection id of the remote side
     * @property {String} remoteSubscriberId The subscriber id of the remote side
     * @property {Number} peerPriority The priority for this peer connection
     * @property {String} peerId The peerId of this peer connection
     * @property {String} peerConnectionId Our local identifier for this peer connection
     */

    /**
     * Retrieve meta information for this peer connection
     * @param {PublisherPeerConnection} peerConnection
     * @returns {peerConnectionMeta} meta data regarding this peer connection
     */
    const getPeerConnectionMeta = peerConnection => peerConnectionMetaMap.get(peerConnection);
    const setPeerConnectionMeta = (peerConnection, value) =>
      peerConnectionMetaMap.set(peerConnection, value);

    eventing(this);

    const streamCleanupJobs = createCleanupJobs();

    /** @type AnalyticsHelperDefault */
    let analytics = new AnalyticsHelper();

    // Check that the client meets the minimum requirements, if they don't the upgrade
    // flow will be triggered.
    if (!systemRequirements.check()) {
      systemRequirements.upgrade();
    }
    /** @type {WidgetView|null} */
    let widgetView;
    let lastRequestedStreamId;
    let webRTCStream;
    let publishStartTime;
    let microphone;
    let state;
    let rumorIceServers;
    let attemptStartTime;
    let audioDevices;
    let videoDevices;
    let selectedVideoInputDeviceId;
    let selectedAudioInputDeviceId;
    let didPublishComplete = false;
    let currentPeerPriority = 0;

    /** @type IntervalRunnerDefault | undefined */
    let connectivityAttemptPinger;

    // previousSession mimics the publisher.session variable except it's never set to null
    // this allows analytics to refer to it in cases where we disconnect/destroy
    // and go to log analytics and publisher.session has been set to null
    let previousSession;

    const getLastSession = () =>
      this.session || previousSession || { isConnected() { return false; } };


    this.once('publishComplete', (err) => {
      if (!err) {
        didPublishComplete = true;
      }
    });

    this.on('audioAcquisitionProblem', ({ method }) => {
      logAnalyticsEvent('publisher:audioAcquisitionProblem', 'Event', { didPublishComplete, method });
    });

    function getCommonAnalyticsFields() {
      return {
        connectionId: getLastSession().isConnected() ?
          getLastSession().connection.connectionId : null,
        streamId: lastRequestedStreamId,
        widgetType: 'Publisher',
      };
    }

    const onStreamAvailableError = (plainError) => {
      const names = Object.keys(Errors).map(shortName => Errors[shortName]);
      const error = otError(
        names.indexOf(plainError.name) > -1 ?
          plainError.name : Errors.MEDIA_ERR_ABORTED,
        plainError,
        ExceptionCodes.UNABLE_TO_PUBLISH
      );
      logging.error(`onStreamAvailableError ${error.name}: ${error.message}`);

      state.set('Failed');

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }

      const logOptions = {
        failureReason: 'GetUserMedia',
        failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
        failureMessage: `OT.Publisher failed to access camera/mic: ${error.message}`,
      };

      logConnectivityEvent('Failure', {}, logOptions);

      OTErrorClass.handleJsException({
        error,
        errorMsg: logOptions.failureReason,
        code: logOptions.failureCode,
        target: this,
        analytics,
      });

      this.trigger('publishComplete', error);
    };

    const onScreenSharingError = (errorParam) => {
      const error = cloneDeep(errorParam);
      error.code = ExceptionCodes.UNABLE_TO_PUBLISH;

      logging.error(`OT.Publisher.onScreenSharingError ${error.message}`);
      state.set('Failed');

      error.message = `Screensharing: ${error.message}`;

      this.trigger('publishComplete', error);

      logConnectivityEvent('Failure', {}, {
        failureReason: 'ScreenSharing',
        failureMessage: error.message,
      });

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }
    };

    // The user has clicked the 'deny' button in the allow access dialog, or it's
    // set to always deny, or the access was denied due to HTTP restrictions;
    const onAccessDenied = (errorParam) => {
      const error = cloneDeep(errorParam);

      let isIframe;

      try {
        isIframe = window.self !== window.top;
      } catch (err) {
        // ignore errors, (some browsers throw a security error when accessing cross domain)
      }

      if (global.location.protocol !== 'https:') {
        if (isScreenSharing) {
          /*
           * in http:// the browser will deny permission without asking the
           * user. There is also no way to tell if it was denied by the
           * user, or prevented from the browser.
           */
          error.message += ' Note: https:// is required for screen sharing.';
        } else if (OTHelpers.env.name === 'Chrome' && OTHelpers.env.hostName !== 'localhost') {
          error.message += ' Note: Chrome requires HTTPS for camera and microphone access.';
        }
      }

      if (isIframe && !isScreenSharing) {
        error.message += ' Note: Check that the iframe has the allow attribute for camera and microphone';
      }

      logging.error(error.message);

      state.set('Failed');

      // Note: The substring 'Publisher Access Denied:' is part of our api contract for now.
      // https://tokbox.com/developer/guides/publish-stream/js/#troubleshooting
      error.message = `OT.Publisher Access Denied: Permission Denied: ${error.message}`;
      error.code = ExceptionCodes.UNABLE_TO_PUBLISH;

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }

      logConnectivityEvent('Cancel', { reason: 'AccessDenied' });
      this.trigger('publishComplete', error);
      this.dispatchEvent(new Event(eventNames.ACCESS_DENIED));
    };

    const userMediaError = (error) => {
      const isPermissionError = error.name === Errors.USER_MEDIA_ACCESS_DENIED ||
        (error.name === Errors.NOT_SUPPORTED &&
          error.originalMessage.match(/Only secure origins/)
        );

      if (isPermissionError) {
        onAccessDenied(error);
      } else if (processPubOptions.isScreenSharing) {
        onScreenSharingError(error);
      } else {
        onStreamAvailableError(error);
      }

      throw error;
    };

    const onAccessDialogOpened = () => {
      logAnalyticsEvent('accessDialog', 'Opened');

      this.dispatchEvent(new Event(eventNames.ACCESS_DIALOG_OPENED, true));
    };

    const onAccessDialogClosed = () => {
      logAnalyticsEvent('accessDialog', 'Closed');

      this.dispatchEvent(new Event(eventNames.ACCESS_DIALOG_CLOSED, false));
    };

    const guid = uuid();
    const peerConnectionsAsync = {};
    let loaded = false;
    let previousAnalyticsStats = {};
    let audioAcquisitionProblemDetected = false;

    let processedOptions = processPubOptions(
      options,
      'OT.Publisher',
      () => (state && state.isDestroyed())
    );
    processedOptions.on({
      accessDialogOpened: onAccessDialogOpened,
      accessDialogClosed: onAccessDialogClosed,
    });
    const {
      isScreenSharing,
      isCustomAudioTrack,
      isCustomVideoTrack,
      shouldAllowAudio,
      properties,
      getUserMedia,
    } = processedOptions;

    // start with undefined
    Object.defineProperty(
      this,
      'loudness',
      { writable: false, value: undefined, configurable: true }
    );

    function removeTrackListeners(trackListeners) {
      trackListeners.forEach(off => off());
    }

    const listenWithOff = (obj, event, listener) => {
      if (!obj.addEventListener) {
        // noop
        return () => {};
      }
      obj.addEventListener(event, listener);
      return () =>
        obj.removeEventListener(event, listener);
    };

    (function handleAudioEnded() {
      const trackListeners = [];

      privateEvents.on('streamDestroy', () => removeTrackListeners(trackListeners));

      privateEvents.on('streamChange', () => {
        removeTrackListeners(trackListeners);
        const newListeners = webRTCStream.getAudioTracks().map(track =>
          listenWithOff(track, 'ended', () => {
            // chrome audio acquisition issue
            audioAcquisitionProblemDetected = true;
            this.trigger('audioAcquisitionProblem', { method: 'trackEndedEvent' });
          })
        );

        trackListeners.splice(0, trackListeners.length, ...newListeners);
      });
    }).call(this);

    (function handleMuteTrack() {
      const trackListeners = [];
      privateEvents.on('streamDestroy', () => removeTrackListeners(trackListeners));

      privateEvents.on('streamChange', () => {
        removeTrackListeners(trackListeners);

        // Screensharing in Chrome sometimes triggers 'mute' and 'unmute'
        // repeatedly for now reason OPENTOK-37818
        // https://bugs.chromium.org/p/chromium/issues/detail?id=931033
        if (!isScreenSharing) {
          webRTCStream.getTracks().forEach((track) => {
            if (track.addEventListener) {
              trackListeners.push(listenWithOff(track, 'mute', refreshAudioVideoUI));
              trackListeners.push(listenWithOff(track, 'unmute', refreshAudioVideoUI));
            }
          });
        }
      });
    }());

    // / Private Methods
    const logAnalyticsEvent = options.logAnalyticsEvent || (
      (action, variation, payload, logOptions, throttle) => {
        let stats = assign(
          { action, variation, payload },
          getCommonAnalyticsFields(),
          logOptions
        );

        if (variation === 'Failure') {
          stats = assign(previousAnalyticsStats, stats);
        }

        previousAnalyticsStats = pick(stats, 'sessionId', 'connectionId', 'partnerId');

        analytics.logEvent(stats, throttle);
      }
    );

    const logConnectivityEvent = (variation, payload = {}, logOptions = {}) => {
      if (logOptions.failureReason === 'Non-fatal') {
        // we don't want to log because it was a non-fatal failure
        return;
      }

      if (variation === 'Attempt') {
        attemptStartTime = new Date().getTime();

        if (connectivityAttemptPinger) {
          connectivityAttemptPinger.stop();
          logging.error('_connectivityAttemptPinger should have been cleaned up');
        }

        connectivityAttemptPinger = new IntervalRunner(
          () => {
            logAnalyticsEvent('Publish', 'Attempting', payload, {
              ...getCommonAnalyticsFields(),
              ...logOptions,
            });
          },
          1 / 5,
          6
        );
      }

      if (variation === 'Failure' || variation === 'Success' || variation === 'Cancel') {
        if (connectivityAttemptPinger) {
          connectivityAttemptPinger.stop();
          connectivityAttemptPinger = undefined;
        } else {
          logging.warn(`Received connectivity event: "${variation}" without "Attempt"`);
        }

        logAnalyticsEvent(
          'Publish',
          variation,
          {
            videoInputDevices: videoDevices,
            audioInputDevices: audioDevices,
            videoInputDeviceCount: videoDevices ? videoDevices.length : undefined,
            audioInputDeviceCount: audioDevices ? audioDevices.length : undefined,
            selectedVideoInputDeviceId,
            selectedAudioInputDeviceId,
            ...payload,
          },
          { attemptDuration: new Date().getTime() - attemptStartTime, ...logOptions }
        );
      } else {
        logAnalyticsEvent('Publish', variation, payload, logOptions);
      }
    };

    const logRepublish = (variation, payload) => {
      logAnalyticsEvent('ICERestart', variation, payload);
    };

    // logs an analytics event for getStats on the first call
    const notifyGetStatsCalled = once(() => {
      logAnalyticsEvent('GetStats', 'Called');
    });

    const recordQOS = ({
      parsedStats,
      simulcastEnabled,
      remoteConnectionId,
      peerPriority,
      peerId,
    }) => {
      const QoSBlob = {
        peerPriority,
        peerId,
        widgetType: 'Publisher',
        connectionId: this.session && this.session.isConnected() ?
          this.session.connection.connectionId : null,
        streamId: lastRequestedStreamId,
        width: widgetView.width,
        height: widgetView.height,
        audioTrack: webRTCStream && webRTCStream.getAudioTracks().length > 0,
        hasAudio: hasAudio(),
        publishAudio: properties.publishAudio,
        videoTrack: webRTCStream && webRTCStream.getVideoTracks().length > 0,
        hasVideo: hasVideo(),
        publishVideo: properties.publishVideo,
        audioSource: isCustomAudioTrack ? 'Custom' : undefined,
        videoSource: (
          (isScreenSharing && options.videoSource) ||
          (isCustomVideoTrack && 'Custom') ||
          (properties.constraints.video && 'Camera') ||
          null
        ),
        duration: publishStartTime ?
          Math.round((new Date().getTime() - publishStartTime.getTime()) / 1000) : 0,
        remoteConnectionId,
        scalableVideo: simulcastEnabled,
      };

      const videoDimensions = {
        videoWidth: this.videoWidth(),
        videoHeight: this.videoHeight(),
      };
      const videoParsedStats = assign(parsedStats, videoDimensions);

      analytics.logQOS(assign(QoSBlob, parsedStats));
      this.trigger('qos', videoParsedStats);
    };

    // Returns the video dimensions. Which could either be the ones that
    // the developer specific in the videoDimensions property, or just
    // whatever the video element reports.
    //
    // If all else fails then we'll just default to 640x480
    //
    const getVideoDimensions = () => {
      let streamWidth;
      let streamHeight;
      const video = widgetView && widgetView.video();

      // We set the streamWidth and streamHeight to be the minimum of the requested
      // resolution and the actual resolution.
      if (properties.videoDimensions) {
        streamWidth = Math.min(properties.videoDimensions.width,
          (video && video.videoWidth()) || 640);
        streamHeight = Math.min(properties.videoDimensions.height,
          (video && video.videoHeight()) || 480);
      } else {
        streamWidth = (video && video.videoWidth()) || 640;
        streamHeight = (video && video.videoHeight()) || 480;
      }

      return {
        width: streamWidth,
        height: streamHeight,
      };
    };

    // / Private Events

    const stateChangeFailed = (changeFailed) => {
      logging.error('OT.Publisher State Change Failed: ', changeFailed.message);
      logging.debug(changeFailed);
    };

    const onLoaded = () => {
      if (state.isDestroyed()) {
        // The publisher was destroyed before loading finished
        if (widgetView) {
          widgetView.destroyVideo();
        }
        return;
      }

      logging.debug(
        'OT.Publisher.onLoaded; resolution:',
        `${this.videoWidth()}x${this.videoHeight()}`
      );

      state.set('MediaBound');

      // Try unblock audio on all subscribers
      unblockAudio().catch(logging.error);

      // If we have a session and we haven't created the stream yet then
      // wait until that is complete before hiding the loading spinner
      widgetView.loading(this.session ? !this.stream : false);

      loaded = true;
    };

    const onLoadFailure = (plainError) => {
      // eslint-disable-next-line no-param-reassign
      const err = otError(Errors.CONNECT_FAILED, plainError, ExceptionCodes.P2P_CONNECTION_FAILED);

      err.message = `OT.Publisher PeerConnection Error: ${err.message}`;

      logConnectivityEvent('Failure', {}, {
        failureReason: 'PeerConnectionError',
        failureCode: err.code,
        failureMessage: err.message,
      });

      state.set('Failed');

      this.trigger('publishComplete', err);

      OTErrorClass.handleJsException({
        error: err,
        target: this,
        analytics,
      });
    };

    // Clean up our LocalMediaStream
    const cleanupLocalStream = () => {
      if (webRTCStream) {
        privateEvents.emit('streamDestroy');
        // Stop revokes our access cam and mic access for this instance
        // of localMediaStream.
        if (windowMock.MediaStreamTrack && windowMock.MediaStreamTrack.prototype.stop) {
          // Newer spec
          webRTCStream.getTracks().forEach(track => track.stop());
        } else {
          // Older spec
          webRTCStream.stop();
        }
      }
    };

    const bindVideo = async () => {
      const videoContainerOptions = {
        muted: true,
      };

      if (!widgetView) {
        throw new Error('Cannot bind video after widget view has been destroyed');
      }

      return widgetView.bindVideo(webRTCStream, videoContainerOptions);
    };

    const onStreamAvailable = (webOTStream) => {
      logging.debug('OT.Publisher.onStreamAvailable');

      state.set('BindingMedia');

      cleanupLocalStream();
      webRTCStream = webOTStream;
      privateEvents.emit('streamChange');

      const findSelectedDeviceId = (tracks, devices) => {
        // Store the device labels to log later
        let selectedDeviceId;
        tracks.forEach((track) => {
          if (Object.prototype.hasOwnProperty.call(track, 'deviceId')) {
            // IE adds a deviceId property to the track
            selectedDeviceId = track.deviceId.toString();
          } else if (track.label && devices) {
            const selectedDevice = find(devices, el => el.label === track.label);
            if (selectedDevice) {
              selectedDeviceId = selectedDevice.deviceId;
            }
          }
        });
        return selectedDeviceId;
      };

      selectedVideoInputDeviceId = findSelectedDeviceId(webRTCStream.getVideoTracks(),
        videoDevices);
      selectedAudioInputDeviceId = findSelectedDeviceId(webRTCStream.getAudioTracks(),
        audioDevices);

      microphone = new Microphone(webRTCStream, !properties.publishAudio);
      updateVideo();
      updateAudio();

      this.accessAllowed = true;

      this.dispatchEvent(new Event(eventNames.ACCESS_ALLOWED, false));
    };

    const onPublishingTimeout = (session) => {
      logging.error('OT.Publisher.onPublishingTimeout');

      let errorName;
      let errorMessage;

      if (audioAcquisitionProblemDetected) {
        errorName = Errors.CHROME_MICROPHONE_ACQUISITION_ERROR;
        errorMessage = 'Unable to publish because your browser failed to get access to your ' +
          'microphone. You may need to fully quit and restart your browser to get it to work. ' +
          'See https://bugs.chromium.org/p/webrtc/issues/detail?id=4799 for more details.';
      } else {
        errorName = Errors.TIMEOUT;
        errorMessage = 'Could not publish in a reasonable amount of time';
      }

      const logOptions = {
        failureReason: 'ICEWorkflow',
        failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
        failureMessage: 'OT.Publisher failed to publish in a reasonable amount of time (timeout)',
      };

      logConnectivityEvent('Failure', {}, logOptions);

      OTErrorClass.handleJsException({
        errorMsg: logOptions.failureReason,
        code: logOptions.failureCode,
        target: this,
        analytics,
      });

      if (session.isConnected() && this.streamId) {
        session._.streamDestroy(this.streamId);
      }

      // Disconnect immediately, rather than wait for the WebSocket to
      // reply to our destroyStream message.
      this.disconnect();

      this.session = null;

      // We're back to being a stand-alone publisher again.
      if (!state.isDestroyed()) { state.set('MediaBound'); }

      this.trigger(
        'publishComplete',
        otError(
          errorName,
          new Error(errorMessage),
          ExceptionCodes.UNABLE_TO_PUBLISH
        )
      );
    };

    const onVideoError = (plainErr) => {
      // eslint-disable-next-line no-param-reassign
      const err = otError(Errors.MEDIA_ERR_DECODE, plainErr, ExceptionCodes.UNABLE_TO_PUBLISH);
      err.message = `OT.Publisher while playing stream: ${err.message}`;

      logging.error('OT.Publisher.onVideoError:', err);
      logAnalyticsEvent('stream', null, { reason: err.message });

      // Check if attempting to publish *before* overwriting the state
      const isAttemptingToPublish = state.isAttemptingToPublish();
      state.set('Failed');

      if (isAttemptingToPublish) {
        this.trigger('publishComplete', err);
      } else {
        // FIXME: This emits a string instead of an error here for backwards compatibility despite
        // being undocumented. When possible we should remove access to this and other undocumented
        // events, and restore emitting actual errors here.
        this.trigger('error', err.message);
      }

      OTErrorClass.handleJsException({
        error: err,
        target: this,
        analytics,
      });
    };

    this._removePeerConnection = (peerConnection) => {
      const { peerConnectionId } = getPeerConnectionMeta(peerConnection);

      delete peerConnectionsAsync[peerConnectionId];

      peerConnection.destroy();
    };


    this._removeSubscriber = (subscriberId) => {
      getPeerConnectionsBySubscriber(subscriberId).then((peerConnections) => {
        peerConnections.forEach(pc => this._removePeerConnection(pc));
      });
    };

    const onPeerDisconnected = (peerConnection) => {
      logging.debug('Subscriber has been disconnected from the Publisher\'s PeerConnection');
      const { peerPriority, remoteSubscriberId } = getPeerConnectionMeta(peerConnection);

      const { peerConnectionId } = getPeerConnectionMeta(peerConnection);

      logAnalyticsEvent('disconnect', 'PeerConnection', { subscriberConnection: peerConnectionId });

      if (peerPriority < currentPeerPriority) {
        // cleanup lower-priority peer connection that is expected to go away
        this._removePeerConnection(peerConnection);
      } else {
        this._removeSubscriber(remoteSubscriberId);
      }
    };

    // @todo find out if we get onPeerDisconnected when a failure occurs.
    const onPeerConnectionFailure = async (peerConnection, { reason, prefix }) => {
      const sessionInfo = this.session && this.session.sessionInfo;

      if (prefix === 'ICEWorkflow' && sessionInfo && sessionInfo.reconnection && loaded) {
        // @todo not sure this is the right thing to do
        logging.debug('Ignoring peer connection failure due to possibility of reconnection.');
        return;
      }

      const { remoteConnectionId = '(not found)', peerConnectionId } = getPeerConnectionMeta(peerConnection) || {};

      const error = interpretPeerConnectionError(undefined, reason, prefix, remoteConnectionId, 'Publisher');

      const payload = {
        hasRelayCandidates: peerConnection && peerConnection.hasRelayCandidates(),
      };

      const logOptions = {
        failureReason: prefix || 'PeerConnectionError',
        failureCode: error.code,
        failureMessage: error.message,
      };

      if (state.isPublishing()) {
        // We're already publishing so this is a Non-fatal failure, must be p2p and one of our
        // peerconnections failed
        logOptions.reason = 'Non-fatal';
      } else {
        this.trigger('publishComplete', error);
      }

      logConnectivityEvent('Failure', payload, logOptions);

      OTErrorClass.handleJsException({
        errorMsg: `OT.Publisher PeerConnection Error: ${reason}`,
        code: error.code,
        target: this,
        analytics,
      });

      const pc = await peerConnectionsAsync[peerConnectionId];
      pc.destroy();
      delete peerConnectionsAsync[peerConnectionId];
    };

    const onIceRestartSuccess = (peerConnection) => {
      const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
      logRepublish('Success', { remoteConnectionId });
    };

    const onIceRestartFailure = (peerConnection) => {
      const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
      logRepublish('Failure', {
        reason: 'ICEWorkflow',
        message: 'OT.Publisher PeerConnection Error: ' +
          'The stream was unable to connect due to a network error.' +
          ' Make sure your connection isn\'t blocked by a firewall.',
        remoteConnectionId,
      });
    };

    // / Private Helpers

    // Assigns +stream+ to this publisher. The publisher listens for a bunch of events on the stream
    // so it can respond to changes.

    const assignStream = (stream) => {
      // the Publisher only expects a stream in the PublishingToSession state
      if (state.current !== 'PublishingToSession') {
        throw new Error('assignStream called when publisher is not successfully publishing');
      }

      streamCleanupJobs.releaseAll();
      this.stream = stream;
      this.stream.on('destroyed', this.disconnect, this);
      streamCleanupJobs.add(() => {
        if (this.stream) {
          this.stream.off('destroyed', this.disconnect, this);
        }
      });

      state.set('Publishing');
      widgetView.loading(!loaded);
      publishStartTime = new Date();

      this.dispatchEvent(new Events.StreamEvent('streamCreated', stream, null, false));

      logConnectivityEvent('Success');

      this.trigger('publishComplete', null, this);
    };

    /**
     * Provides the peer connection associated to the given peerConnectionId.
     *
     * It there is no PC associated it creates a new one and stores it so that the next call returns
     * the same instance.
     *
     * @param {Object} configuration
     * @param {string} configuration.peerConnectionId
     * @returns {Promise<Error, PublisherPeerConnection>}
     */
    const createPeerConnection = ({
      peerConnectionId,
      send,
      log,
      logQoS,
    }) => {
      if (getPeerConnectionById(peerConnectionId)) {
        return Promise.reject(new Error('PeerConnection already exists'));
      }

      // Calculate the number of streams to use. 1 for normal, >1 for Simulcast
      const capableSimulcastStreams = calculateCapableSimulcastStreams({
        browserName: OTHelpers.env.name,
        isScreenSharing,
        isCustomVideoTrack,
        sessionInfo: this.session.sessionInfo,
        constraints: properties.constraints,
        videoDimensions: getVideoDimensions(),
      });

      peerConnectionsAsync[peerConnectionId] = Promise
        .all([
          this.session._.getIceConfig(),
          this.session._.getVideoCodecsCompatible(webRTCStream),
        ])
        .then(([iceConfig, videoCodecsCompatible]) => {
          let pcStream = webRTCStream;
          if (!videoCodecsCompatible) {
            // If we don't support the videoCodec then remove the video track
            // from the PeerConnection's stream.
            pcStream = webRTCStream.clone();
            pcStream.getVideoTracks()[0].stop();
            pcStream.removeTrack(pcStream.getVideoTracks()[0]);
          }

          const peerConnection = new PublisherPeerConnection({
            iceConfig,
            sendMessage: (type, content) => {
              if (type === 'offer') {
                this.trigger('connected');
              }
              send(type, content);
            },
            webRTCStream: pcStream,
            channels: properties.channels,
            capableSimulcastStreams,
            overrideSimulcastEnabled: options._enableSimulcast,
            logAnalyticsEvent: log,
            offerOverrides: {
              enableStereo: properties.enableStereo,
              audioBitrate: properties.audioBitrate,
              priorityVideoCodec: properties._priorityVideoCodec ||
                this.session.sessionInfo.priorityVideoCodec,
              codecFlags: properties._codecFlags || this.session._.getCodecFlags(),
            },
            // FIXME - Remove answerOverrides once b=AS is supported by Mantis
            answerOverrides: (this.session.sessionInfo.p2pEnabled ? undefined : {
              audioBitrate: properties.audioBitrate,
            }),
          });

          peerConnection.on({
            disconnected: () => onPeerDisconnected(peerConnection),
            error: ({ reason, prefix }) =>
              onPeerConnectionFailure(peerConnection, { reason, prefix }),
            qos: logQoS,
            iceRestartSuccess: () => onIceRestartSuccess(peerConnection),
            iceRestartFailure: () => onIceRestartFailure(peerConnection),
            audioAcquisitionProblem: () => {
              // will be only triggered in Chrome
              audioAcquisitionProblemDetected = true;
              this.trigger('audioAcquisitionProblem', { method: 'getStats' });
            },
          });

          return new Promise((resolve, reject) => {
            const rejectOnError = (err) => {
              reject(err);
            };
            peerConnection.once('error', rejectOnError);
            peerConnection.init(rumorIceServers, (err) => {
              if (err) { return reject(err); }
              peerConnection.off('error', rejectOnError);
              resolve(peerConnection);
              return undefined;
            });
          });
        });

      return getPeerConnectionById(peerConnectionId);
    };

    const getAllPeerConnections = () =>
      Promise.all(Object.keys(peerConnectionsAsync).map(getPeerConnectionById));

    const getPeerConnectionsBySubscriber = subscriberId =>
      getAllPeerConnections().then(peerConnections =>
        peerConnections.filter(peerConnection =>
          getPeerConnectionMeta(peerConnection).remoteSubscriberId === subscriberId
        )
      );

    const getPeerConnectionsByPriority = priority =>
      getAllPeerConnections().then(peerConnections =>
        peerConnections.filter(peerConnection =>
          getPeerConnectionMeta(peerConnection).peerPriority === priority
        )
      );

    const getPeerConnectionById = id => peerConnectionsAsync[id];

    let chromeMixin = createChromeMixin(this, {
      name: properties.name,
      publishAudio: properties.publishAudio,
      publishVideo: properties.publishVideo,
      audioSource: properties.audioSource,
      showControls: properties.showControls,
      shouldAllowAudio,
      logAnalyticsEvent,
    });

    const reset = () => {
      this.off('publishComplete', refreshAudioVideoUI);
      if (chromeMixin) {
        chromeMixin.reset();
      }

      streamCleanupJobs.releaseAll();
      this.disconnect();

      microphone = null;

      cleanupLocalStream();
      webRTCStream = null;

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }

      if (this.session) {
        this._.unpublishFromSession(this.session, 'reset');
      }

      this.id = null;
      this.stream = null;
      loaded = false;

      this.session = null;

      if (!state.isDestroyed()) { state.set('NotPublishing'); }
    };

    const hasVideo = () => {
      if (!webRTCStream || webRTCStream.getVideoTracks().length === 0) {
        return false;
      }
      return webRTCStream.getVideoTracks().reduce(
        (isEnabled, track) => isEnabled && !track.muted && track.enabled && track.readyState !== 'ended',
        properties.publishVideo
      );
    };

    const hasAudio = () => {
      if (!webRTCStream || webRTCStream.getAudioTracks().length === 0) {
        return false;
      }
      return webRTCStream.getAudioTracks().length > 0 && webRTCStream.getAudioTracks().reduce(
        (isEnabled, track) => isEnabled && !track.muted && track.enabled && track.readyState !== 'ended',
        properties.publishAudio
      );
    };

    const refreshAudioVideoUI = () => {
      if (widgetView) {
        widgetView.audioOnly(!hasVideo());
        widgetView.showPoster(!hasVideo());
      }

      if (chromeMixin) {
        chromeMixin.setAudioOnly(!hasVideo() && hasAudio());
      }

      if (this.stream) {
        this.stream.setChannelActiveState('audio', hasAudio());
        this.stream.setChannelActiveState('video', hasVideo());
      } else {
        this.once('publishComplete', refreshAudioVideoUI);
      }
    };

    this.publish = (targetElement) => {
      logging.debug('OT.Publisher: publish');

      if (state.isAttemptingToPublish() || state.isPublishing()) {
        reset();
      }
      state.set('GetUserMedia');

      if (properties.style) {
        this.setStyle(properties.style, null, true);
      }

      properties.classNames = 'OT_root OT_publisher';

      // Defer actually creating the publisher DOM nodes until we know
      // the DOM is actually loaded.
      EnvironmentLoader.onLoad(() => {
        logging.debug('OT.Publisher: publish: environment loaded');
        // @note If ever replacing the widgetView with a new one elsewhere, you'll need to be
        // mindful that audioLevelBehaviour has a reference to this one, and it will need to be
        // updated accordingly.
        widgetView = new WidgetView(targetElement, properties);

        if (shouldAllowAudio) {
          audioLevelBehaviour({ publisher: this, widgetView });
        }

        widgetView.on('error', onVideoError);

        this.id = widgetView.domId();
        this.element = widgetView.domElement;

        if (this.element && chromeMixin) {
          // Only create the chrome if we have an element to insert it into
          // for insertDefautlUI:false we don't create the chrome
          chromeMixin.init(widgetView);
        }

        widgetView.on('videoDimensionsChanged', (oldValue, newValue) => {
          if (this.stream) {
            this.stream.setVideoDimensions(newValue.width, newValue.height);
          }
          this.dispatchEvent(
            new Events.VideoDimensionsChangedEvent(this, oldValue, newValue)
          );
        });

        widgetView.on('mediaStopped', (track) => {
          const event = new Events.MediaStoppedEvent(this, track);

          this.dispatchEvent(event);

          if (event.isDefaultPrevented()) {
            return;
          }

          if (track) {
            const kind = String(track.kind).toLowerCase();
            // If we are publishing this kind when the track stops then
            // make sure we start publishing again if we switch to a new track
            if (kind === 'audio') {
              updateAudio();
            } else if (kind === 'video') {
              updateVideo();
            } else {
              logging.warn(`Track with invalid kind has ended: ${track.kind}`);
            }
            return;
          }

          if (this.session) {
            this._.unpublishFromSession(this.session, 'mediaStopped');
          } else {
            this.destroy('mediaStopped');
          }
        });

        widgetView.on('videoElementCreated', (element) => {
          const event = new Events.VideoElementCreatedEvent(element);
          this.dispatchEvent(event);
        });

        getUserMedia()
          .catch(userMediaError)
          .then(
            (stream) => {
              // this comes from deviceHelpers.shouldAskForDevices in a round-about way
              audioDevices = processedOptions.audioDevices;
              videoDevices = processedOptions.videoDevices;
              onStreamAvailable(stream);

              return bindVideo()
                .catch((error) => {
                  if (error instanceof CancellationError) {
                  // If we get a CancellationError, it means something newer tried
                  // to bindVideo before the old one succeeded, perhaps they called
                  // switchTracks.. It should be rare, and they shouldn't be doing
                  // this before loaded, but we'll handle it anyway.
                    return undefined;
                  }
                  throw error;
                })
                .then(
                  () => {
                    onLoaded();

                    if (!state.isDestroyed()) {
                      this.trigger('initSuccess');
                      this.trigger('loaded', this);
                    }
                  }, (err) => {
                    logging.error(`OT.Publisher.publish failed to bind video: ${err}`);
                    onLoadFailure(err);
                  }
                );
            }
          );
      });

      return this;
    };

    const haveWorkingTracks = type => webRTCStream &&
      webRTCStream[`get${capitalize(type)}Tracks`]().length > 0 &&
      webRTCStream[`get${capitalize(type)}Tracks`]().every(track => track.readyState !== 'ended');

    const updateAudio = () => {
      const shouldSendAudio = haveWorkingTracks('audio') && properties.publishAudio;

      if (chromeMixin) {
        chromeMixin.setMuted(!shouldSendAudio);
      }

      if (microphone) {
        microphone.muted(!shouldSendAudio);
      }

      refreshAudioVideoUI();
    };

    /**
    * Starts publishing audio (if it is currently not being published)
    * when the <code>value</code> is <code>true</code>; stops publishing audio
    * (if it is currently being published) when the <code>value</code> is <code>false</code>.
    *
    * @param {Boolean} value Whether to start publishing audio (<code>true</code>)
    * or not (<code>false</code>).
    *
    * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
    * @see <a href="Stream.html#hasAudio">Stream.hasAudio</a>
    * @see StreamPropertyChangedEvent
    * @method #publishAudio
    * @memberOf Publisher
    */
    this.publishAudio = (value) => {
      properties.publishAudio = value;
      updateAudio();
      return this;
    };

    let updateVideoSenderParametersSentinel;

    const updateVideo = () => {
      const shouldSendVideo = haveWorkingTracks('video') && properties.publishVideo;
      if (env.name === 'Chrome' && env.version >= 69) {
        (async () => {
          if (updateVideoSenderParametersSentinel) {
            updateVideoSenderParametersSentinel.cancel();
          }
          updateVideoSenderParametersSentinel = new Cancellation();
          const executionSentinel = updateVideoSenderParametersSentinel;
          const peerConnections = await getAllPeerConnections();
          if (!executionSentinel.isCanceled()) {
            // only proceed if we weren't canceled during the async operation above
            peerConnections.forEach((peerConnection) => {
              setEncodersActiveState(peerConnection, shouldSendVideo);
            });
          }
        })();
      }

      if (webRTCStream) {
        webRTCStream.getVideoTracks().forEach((track) => {
          track.enabled = shouldSendVideo; // eslint-disable-line no-param-reassign
        });
      }

      refreshAudioVideoUI();
    };

    /**
    * Starts publishing video (if it is currently not being published)
    * when the <code>value</code> is <code>true</code>; stops publishing video
    * (if it is currently being published) when the <code>value</code> is <code>false</code>.
    *
    * @param {Boolean} value Whether to start publishing video (<code>true</code>)
    * or not (<code>false</code>).
    *
    * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
    * @see <a href="Stream.html#hasVideo">Stream.hasVideo</a>
    * @see StreamPropertyChangedEvent
    * @method #publishVideo
    * @memberOf Publisher
    */
    this.publishVideo = (value) => {
      properties.publishVideo = value;
      updateVideo();
      return this;
    };

    /**
    * Deletes the Publisher object and removes it from the HTML DOM.
    * <p>
    * The Publisher object dispatches a <code>destroyed</code> event when the DOM
    * element is removed.
    * </p>
    * @method #destroy
    * @memberOf Publisher
    * @return {Publisher} The Publisher.
    */

    this.destroy = function (/* unused */ reason, quiet) {
      // @todo OPENTOK-36652 this.session should not be needed here
      if (state.isAttemptingToPublish() && this.session) {
        logConnectivityEvent('Cancel', { reason: 'destroy' });
      }

      if (state.isDestroyed()) { return this; }
      state.set('Destroyed');

      reset();

      if (processedOptions) {
        processedOptions.off();
        processedOptions = null;
      }

      if (chromeMixin) {
        chromeMixin.destroy();
        chromeMixin = null;
      }

      if (privateEvents) {
        privateEvents.off();
        privateEvents = null;
      }

      if (quiet !== true) {
        this.dispatchEvent(new Events.DestroyedEvent(
          eventNames.PUBLISHER_DESTROYED,
          this,
          reason
        ));
      }

      this.off();

      return this;
    };

    /*
    * @methodOf Publisher
    * @private
    */
    this.disconnect = () => {
      Object.keys(peerConnectionsAsync)
        .forEach((peerConnectionId) => {
          const futurePeerConnection = getPeerConnectionById(peerConnectionId);
          delete peerConnectionsAsync[peerConnectionId];
          futurePeerConnection.then(peerConnection => this._removePeerConnection(peerConnection));
        });
    };

    this.processMessage = (type, fromConnectionId, message) => {
      const subscriberId = get(message, 'params.subscriber', fromConnectionId)
        .replace(/^INVALID-STREAM$/, fromConnectionId);
      const peerId = get(message, 'content.peerId');
      const peerPriority = Number(get(message, 'content.peerPriority'));

      // Symphony will not have a subscriberId so we'll fallback to using the connectionId for it.
      // Also fallback to the connectionId if it is equal to 'INVALID-STREAM' (See OPENTOK-30029).
      const peerConnectionId = `${subscriberId}~${peerId}~${peerPriority}`;

      logging.debug(`OT.Publisher.processMessage: Received ${type} from ${fromConnectionId} for ${peerConnectionId}`);
      logging.debug(message);

      const futurePeerConnection = getPeerConnectionById(peerConnectionId);

      const addPeerConnection = () => {
        if (peerPriority > currentPeerPriority) {
          logging.info(`PeerConnection escalation to ${peerId}:${peerPriority}`);
          getPeerConnectionsByPriority(currentPeerPriority).then((peerConnections) => {
            peerConnections.forEach((peerConnection) => {
              setTimeout(() => {
                logging.info('PeerConnection escalation removing old peer connection with force');
                this._removePeerConnection(peerConnection);
              }, FORCE_DISCONNECT_OLD_PEER_CONNECTIONS_DELAY);
            });
          });
          currentPeerPriority = peerPriority;
        }

        const send = createSendMethod({
          socket: this.session._.getSocket(),
          uri: message.uri,
          content: {
            peerPriority,
            peerId,
          },
        });

        const log = (action, variation, payload, logOptions = {}, throttle) => {
          const transformedOptions = { peerId, peerPriority, ...logOptions };
          return logAnalyticsEvent(action, variation, payload, transformedOptions, throttle);
        };

        const logQoS = (qos) => {
          recordQOS({
            ...qos,
            peerId,
            peerPriority,
            remoteConnectionId: fromConnectionId,
          });
        };

        createPeerConnection({
          peerConnectionId,
          send,
          log,
          logQoS,
        })
          .then((peerConnection) => {
            setPeerConnectionMeta(peerConnection, {
              remoteConnectionId: fromConnectionId,
              remoteSubscriberId: subscriberId,
              peerPriority,
              peerId,
              peerConnectionId,
            });

            peerConnection.processMessage(type, message);

            // Allow this runaway promise
            // http://bluebirdjs.com/docs/warning-explanations.html#warning-a-promise-was-created-in-a-handler-but-was-not-returned-from-it
            return null;
          })
          .catch((err) => {
            logging.error('OT.Publisher failed to create a peerConnection', err);
          });
      };

      if ((type === 'generateoffer' || type === 'offer') && peerPriority < currentPeerPriority) {
        logging.debug('Ignore offer from lower priority peer connection');
      }

      switch (type) {
        case 'unsubscribe':
          this._removeSubscriber(subscriberId);
          break;
        default:
          if (!futurePeerConnection) {
            addPeerConnection();
          } else {
            futurePeerConnection.then(
              peerConnection => peerConnection.processMessage(type, message)
            );
          }
          break;
      }
    };

    /**
    * Returns the base-64-encoded string of PNG data representing the Publisher video.
    *
    *   <p>You can use the string as the value for a data URL scheme passed to the src parameter of
    *   an image file, as in the following:</p>
    *
    * <pre>
    *  var imgData = publisher.getImgData();
    *
    *  var img = document.createElement("img");
    *  img.setAttribute("src", "data:image/png;base64," + imgData);
    *  var imgWin = window.open("about:blank", "Screenshot");
    *  imgWin.document.write("&lt;body&gt;&lt;/body&gt;");
    *  imgWin.document.body.appendChild(img);
    * </pre>
    *
    * @method #getImgData
    * @memberOf Publisher
    * @return {String} The base-64 encoded string. Returns an empty string if there is no video.
    */

    this.getImgData = function () {
      if (!loaded) {
        logging.error(
          'OT.Publisher.getImgData: Cannot getImgData before the Publisher is publishing.'
        );

        return null;
      }

      const video = widgetView && widgetView.video();
      return video ? video.imgData() : null;
    };

    const setNewStream = (newStream) => {
      cleanupLocalStream();
      webRTCStream = newStream;
      privateEvents.emit('streamChange');
      microphone = new Microphone(webRTCStream, !properties.publishAudio);
    };

    const defaultReplaceTrackLogic = (peerConnection) => {
      peerConnection.getSenders().forEach((sender) => {
        if (sender.track.kind === 'audio' && webRTCStream.getAudioTracks().length) {
          return sender.replaceTrack(webRTCStream.getAudioTracks()[0]);
        } else if (sender.track.kind === 'video' && webRTCStream.getVideoTracks().length) {
          return sender.replaceTrack(webRTCStream.getVideoTracks()[0]);
        }
        return undefined;
      });
    };

    const replaceTracks = (replaceTrackLogic = defaultReplaceTrackLogic) => (
      getAllPeerConnections().then((peerConnections) => {
        const tasks = [];
        peerConnections.map(replaceTrackLogic);
        return Promise.all(tasks);
      })
    );

    {
      let videoIndex = 0;
      /**
      * Switches the video input source used by the publisher to the next one in the list
      * of available devices.
      * <p>
      * This will result in an error (the Promise returned by the method is rejected) in the
      * following conditions:
      * <ul>
      *   <li>
      *     The user denied access to the video input device.
      *   </li>
      *   <li>
      *     The publisher is not using a camera video source. (The <code>videoSource</code>
      *     option of the <a href="OT.html#initPublisher">OT.initPublisher()</a> method was
      *     set to <code>null</code>, <code>false</code>, a MediaStreamTrack object, or
      *     <code>"screen"</code>).
      *   </li>
      *   <li>
      *     There are no video input devices (cameras) available.
      *   </li>
      *   <li>
      *     There was an error acquiring video from the video input device.
      *   </li>
      *  </ul>
      * </p>
      *
      * @method #cycleVideo
      * @memberOf Publisher
      *
      * @return {Promise} A promise that resolves when the operation completes
      * successfully. The promise resolves with an object that has a
      * <code>deviceId</code> property set to the device ID of the camera used:
      *
      * <pre>
      *   publisher.cycleVideo().then(console.log);
      *   // Output: {deviceId: "967a86e52..."}
      * </pre>
      *
      * If there is an error, the promise is rejected.
      */
      this.cycleVideo = blockCallsUntilComplete(async () => {
        if (OTHelpers.env.name === 'IE' || OTHelpers.env.name === 'Edge' || !windowMock.RTCRtpSender || typeof windowMock.RTCRtpSender.prototype.replaceTrack !== 'function') {
          throw otError(
            Errors.UNSUPPORTED_BROWSER,
            new Error('Publisher#cycleVideo is not supported in your browser.'),
            ExceptionCodes.UNABLE_TO_PUBLISH
          );
        }

        const oldTrack = webRTCStream.getVideoTracks().length > 0 &&
          webRTCStream.getVideoTracks()[0];

        if (!oldTrack) {
          // Cannot cycleVideo if you don't already have a video track
          throw otError(
            Errors.NOT_SUPPORTED,
            new Error('Publisher#cycleVideo cannot cycleVideo when you have no video source.')
          );
        }

        videoIndex += 1;

        const devices = await deviceHelpers.shouldAskForDevices();
        const vidDevices = devices.videoDevices;
        if (!devices.video || !vidDevices || !vidDevices.length) {
          throw otError(
            Errors.NO_DEVICES_FOUND,
            new Error('No video devices available'),
            ExceptionCodes.UNABLE_TO_PUBLISH
          );
        }

        if (OTHelpers.env.name === 'Chrome' &&
          OTHelpers.env.userAgent.toLowerCase().indexOf('android') > -1) {
          // On Chrome on Android you need to stop the previous video track OPENTOK-37206
          if (oldTrack && oldTrack.stop) {
            oldTrack.stop();
          }
        }
        privateEvents.emit('streamDestroy');

        if (videoIndex === 1 && oldTrack) {
          // Get a new device the first time
          for (let i = 0; i < vidDevices.length; i += 1) {
            if (vidDevices[i].label && vidDevices[i].label !== oldTrack.label) {
              videoIndex = i;
              break;
            }
          }
        }
        const newVideoDevice = vidDevices[videoIndex % vidDevices.length];
        const deviceId = newVideoDevice.deviceId;

        const newOptions = cloneDeep(options);
        newOptions.audioSource = null;
        newOptions.videoSource = deviceId;
        processedOptions = processPubOptions(
          newOptions,
          'OT.Publisher.cycleVideo',
          () => (state && state.isDestroyed())
        );
        processedOptions.on({
          accessDialogOpened: onAccessDialogOpened,
          accessDialogClosed: onAccessDialogClosed,
        });
        const {
          getUserMedia: getUserMediaHelper,
        } = processedOptions;
        const newVideoStream = await getUserMediaHelper().catch(userMediaError);
        const newTrack = newVideoStream.getVideoTracks()[0];

        const pcs = await getAllPeerConnections();
        await Promise.all(pcs.map(pc => pc.findAndReplaceTrack(oldTrack, newTrack)));
        webRTCStream.addTrack(newTrack);
        webRTCStream.removeTrack(oldTrack);
        if (oldTrack && oldTrack.stop) {
          oldTrack.stop();
        }

        if (OTHelpers.env.name === 'Firefox' || OTHelpers.env.name === 'Safari') {
          // Local video freezes on old stream without this for some reason
          this.videoElement().srcObject = null;
          this.videoElement().srcObject = webRTCStream;
        }

        const video = widgetView && widgetView.video();
        if (video) {
          video.refreshTracks();
        }

        privateEvents.emit('streamChange');
        updateVideo();

        return { deviceId };
      });
    }

    const replaceAudioTrack = (oldTrack, newTrack) => {
      if (newTrack) {
        webRTCStream.addTrack(newTrack);
      }
      if (oldTrack) {
        webRTCStream.removeTrack(oldTrack);
      }

      const video = widgetView && widgetView.video();
      if (video) {
        video.refreshTracks();
      }

      if (chromeMixin) {
        if (newTrack && !oldTrack) {
          chromeMixin.addAudioTrack();
        }
        if (oldTrack && !newTrack) {
          chromeMixin.removeAudioTrack();
        }
      }

      if (oldTrack && oldTrack.stop) {
        oldTrack.stop();
      }

      if (newTrack) {
        // Turn the audio back on if the audio track stopped because it was disconnected
        updateAudio();
        microphone = new Microphone(webRTCStream, !properties.publishAudio);
      }
      privateEvents.emit('streamChange');
      refreshAudioVideoUI();
    };

    /**
    * Switches the audio input source used by the publisher. You can set the
    * <code>audioSource</code> to a device ID (string) or audio MediaStreamTrack object.
    * <p>
    * This will result in an error (the Promise returned by the method is rejected) in the
    * following conditions:
    * <ul>
    *   <li>
    *     The browser does not support this method. This method is not supported in
    *     Internet Explorer or Edge.
    *   </li>
    *   <li>
    *     The publisher was not initiated with an audio source. (The <code>audioSource</code>
    *     option of the <a href="OT.html#initPublisher">OT.initPublisher()</a> method was
    *     set to <code>null</code> or <code>false</code>).
    *   </li>
    *   <li>
    *     The user denied access to the audio input device.
    *   </li>
    *   <li>
    *     There was an error acquiring audio from the audio input device or MediaStreamTrack
    *     object.
    *   </li>
    *   <li>
    *     The <code>audioSource</code> value is not a string or MediaStreamTrack object.
    *   </li>
    *   <li>
    *     The <code>audioSource</code> string is not a valid audio input device available
    *     to the browser.
    *   </li>
    *  </ul>
    * </p>
    *
    * @param {Object} audioSource The device ID (string) of an audio input device, or an audio
    * MediaStreamTrack object.
    *
    * @method #setAudioSource
    * @memberOf Publisher
    *
    * @see <a href="#getAudioSource">Publisher.getAudioSource()</a>
    *
    * @return {Promise} A promise that resolves when the operation completes successfully.
    * If there is an error, the promise is rejected.
    */
    let cancelPreviousSetAudioSourceSentinel;

    const setAudioSource = async (audioSource) => {
      const CANCEL_ERR_MSG = 'Operation did not succeed due to a new request.';
      if (cancelPreviousSetAudioSourceSentinel) {
        cancelPreviousSetAudioSourceSentinel.cancel();
      }
      cancelPreviousSetAudioSourceSentinel = new Cancellation();
      const currentCancelSentinel = cancelPreviousSetAudioSourceSentinel;

      const setStreamIfNotCancelled = (stream) => {
        if (currentCancelSentinel.isCanceled()) {
          stream.getTracks(track => track.stop());
          throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
        }
        return setAudioSource(stream.getAudioTracks()[0]);
      };

      if (OTHelpers.env.name === 'IE' || OTHelpers.env.name === 'Edge' || !windowMock.RTCRtpSender || typeof windowMock.RTCRtpSender.prototype.replaceTrack !== 'function') {
        throw otError(
          Errors.UNSUPPORTED_BROWSER,
          new Error('Publisher#setAudioSource is not supported in your browser.')
        );
      }
      const prevAudioSource = this.getAudioSource();
      if (!prevAudioSource) {
        // We are adding an audio track where there wasn't one before
        throw otError(
          Errors.NOT_SUPPORTED,
          new Error('Publisher#setAudioSource cannot add an audio source when you started without one.')
        );
      }
      if (audioSource instanceof MediaStreamTrack) {
        const pcs = await getAllPeerConnections();
        if (currentCancelSentinel.isCanceled()) {
          throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
        }
        await Promise.all(pcs.map(pc => pc.findAndReplaceTrack(prevAudioSource, audioSource)));
        // we don't cancel here on purpose, the next step is required.
        return replaceAudioTrack(prevAudioSource, audioSource);
      } else if (typeof audioSource === 'string') {
        // Must be a deviceId, call getUserMedia and get the MediaStreamTrack
        const newOptions = cloneDeep(options);
        newOptions.audioSource = audioSource;
        newOptions.videoSource = null;
        processedOptions = processPubOptions(
          newOptions,
          'OT.Publisher.setAudioSource',
          () => currentCancelSentinel.isCanceled() || (state && state.isDestroyed())
        );
        processedOptions.on({
          accessDialogOpened: onAccessDialogOpened,
          accessDialogClosed: onAccessDialogClosed,
        });
        const prevLabel = prevAudioSource.label;
        const prevDeviceId = (
          prevAudioSource.getConstraints && prevAudioSource.getSettings().deviceId
        ) || undefined;
        // In firefox we have to stop the previous track before we get a new one
        if (prevAudioSource) {
          prevAudioSource.stop();
        }
        const { getUserMedia: getUserMediaHelper } = processedOptions;
        try {
          return await setStreamIfNotCancelled(await getUserMediaHelper());
        } catch (err) {
          // oh no, the new stream did not work out, let's try to get back the old
          // audio device.
          if (currentCancelSentinel.isCanceled()) {
            throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
          }
          const prevOptions = cloneDeep(options);
          prevOptions.videoSource = null;
          prevOptions.audioSource = prevDeviceId;
          if (!prevOptions.audioSource && prevLabel) {
            const previousDevice = (await getMediaDevices()).filter(x => x.label === prevLabel)[0];

            if (currentCancelSentinel.isCanceled()) {
              throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
            }

            if (previousDevice) {
              prevOptions.audioSource = previousDevice.deviceId;
            }
          }

          if (!prevOptions.audioSource) {
            err.message += ' (could not determine previous audio device)';
            throw otError(Errors.NOT_FOUND, err);
          }

          processedOptions = processPubOptions(
            prevOptions,
            'OT.Publisher.setAudioSource',
            () => currentCancelSentinel.isCanceled() || (state && state.isDestroyed())
          );


          const stream = await processedOptions.getUserMedia().catch((error) => {
            // eslint-disable-next-line no-param-reassign
            error.message += ' (could not obtain previous audio device)';
            throw error;
          });

          await setStreamIfNotCancelled(stream);

          err.message += ' (reverted to previous audio device)';
          throw err;
        }
      } else {
        throw otError(
          Errors.INVALID_PARAMETER,
          new Error('Invalid parameter passed to OT.Publisher.setAudioSource(). Expected string or MediaStreamTrack.')
        );
      }
    };

    this.setAudioSource = setAudioSource;

    /**
    * Returns the MediaStreamTrack object used as the audio input source for the publisher.
    * If the publisher does not have an audio source, this method returns null.
    *
    * @method #getAudioSource
    * @memberOf Publisher
    * @see <a href="#setAudioSource">Publisher.setAudioSource()</a>
    *
    * @return {MediaStreamTrak} The audio source for the publisher (or null, if there is none).
    */
    this.getAudioSource = () => {
      if (webRTCStream && webRTCStream.getAudioTracks().length > 0) {
        return webRTCStream.getAudioTracks()[0];
      }
      return null;
    };

    // API Compatibility layer for Flash Publisher, this could do with some tidyup.

    this._ = {
      publishToSession: (session, analyticsReplacement) => {
        if (analyticsReplacement) {
          analytics = analyticsReplacement;
        }
        // Add session property to Publisher
        previousSession = session;
        this.session = session;

        const requestedStreamId = uuid();
        lastRequestedStreamId = requestedStreamId;
        this.streamId = requestedStreamId;

        logConnectivityEvent('Attempt', {
          dataChannels: properties.channels,
          properties: whitelistPublisherProperties(properties),
        });

        const loadedPromise = new Promise((resolve, reject) => {
          if (loaded) {
            resolve();
            return;
          }

          this.once('initSuccess', resolve);
          this.once('destroyed', ({ reason }) => {
            let reasonDescription = '';
            if (reason) {
              reasonDescription = ` Reason: ${reason}`;
            }
            reject(new Error(
              `Publisher destroyed before it finished loading.${reasonDescription}`
            ));
          });
        });

        logging.debug('publishToSession: waiting for publishComplete, which is triggered by ' +
          'stream#created from rumor');

        const completedPromise = new Promise((resolve, reject) => {
          this.once('publishComplete', (error) => {
            if (error) {
              reject(error);
              return;
            }

            logging.debug('publishToSession: got publishComplete');

            resolve();
          });
        });

        const processMessagingError = (error) => {
          // @todo Can we provide more specific errors for these codes? Are these still the only
          // codes that we expect?
          const expectedErrorCodes = [403, 404, 409];

          const publicError = (expectedErrorCodes.indexOf(error.code) > -1 ?
            otError(
              Errors.STREAM_CREATE_FAILED,
              new Error(`Failed to create stream in server model: ${error.message}`),
              ExceptionCodes.UNABLE_TO_PUBLISH
            ) :
            otError(
              Errors.UNEXPECTED_SERVER_RESPONSE,
              new Error(`Unexpected server response: ${error.message}`),
              ExceptionCodes.UNEXPECTED_SERVER_RESPONSE
            )
          );

          logConnectivityEvent('Failure', {}, {
            failureReason: 'Publish',
            failureCode: publicError.code,
            failureMessage: publicError.message,
          });
          if (state.isAttemptingToPublish()) {
            this.trigger('publishComplete', publicError);
          }

          OTErrorClass.handleJsException({
            errorMsg: error.message,
            code: publicError.code,
            target: this,
            error,
            analytics,
          });

          throw publicError;
        };

        logging.debug('publishToSession: waiting for loaded');

        const streamCreatedPromise = loadedPromise
          .then(() => session._.getVideoCodecsCompatible(webRTCStream))
          .then((videoCodecsCompatible) => {
            logging.debug('publishToSession: loaded');
            // Bail if this.session is gone, it means we were unpublished
            // before createStream could finish.
            if (!this.session) { return undefined; }

            // make sure we trigger an error if we are not getting any "ack" after a reasonable
            // amount of time
            const publishGuardingTo = setTimeout(() => {
              onPublishingTimeout(session);
            }, PUBLISH_MAX_DELAY);

            this.once('publishComplete', () => {
              clearTimeout(publishGuardingTo);
            });

            state.set('PublishingToSession');

            const streamChannels = [];

            const video = videoCodecsCompatible && widgetView && widgetView.video();
            const hasVideoTrack = webRTCStream.getVideoTracks().length > 0;
            const didRequestVideo = properties.videoSource !== null &&
              properties.videoSource !== false;
            if (video && hasVideoTrack && didRequestVideo) {
              streamChannels.push(new StreamChannel({
                id: 'video1',
                type: 'video',
                active: properties.publishVideo,
                orientation: VideoOrientation.ROTATED_NORMAL,
                frameRate: properties.frameRate,
                width: video.videoWidth(),
                height: video.videoHeight(),
                source: (() => {
                  if (isScreenSharing) {
                    return 'screen';
                  }
                  if (isCustomVideoTrack) {
                    return 'custom';
                  }
                  return 'camera';
                })(),
                fitMode: properties.fitMode,
              }));
            }

            const hasAudioTrack = webRTCStream.getAudioTracks().length > 0;
            // @todo I'm not sure why we use this logic instead of whether or not
            // the webRTCStream contains an audio track. I uncovered this with IE with it's
            // fake camera. It does not have audio, but we have requested audio.
            const didRequestAudio = properties.audioSource !== null &&
              properties.audioSource !== false;

            if (didRequestAudio && hasAudioTrack) {
              streamChannels.push(new StreamChannel({
                id: 'audio1',
                type: 'audio',
                active: properties.publishAudio,
              }));
            }

            logging.debug('publishToSession: creating rumor stream id');

            return new Promise((resolve, reject) => {
              session._.streamCreate(
                properties.name || '',
                requestedStreamId,
                properties.audioFallbackEnabled,
                streamChannels,
                properties.minVideoBitrate,
                (messagingError, streamId, message) => {
                  if (messagingError) {
                    reject(processMessagingError(messagingError));
                    return;
                  }
                  resolve({ streamId, message });
                }
              );
            });
          })
          .then((maybeStream) => {
            if (maybeStream === undefined) {
              return;
            }

            const { streamId, message } = maybeStream;

            logging.debug('publishToSession: rumor stream id created:', streamId,
              '(this is different from stream#created, which requires media to actually be ' +
              'flowing for mantis sessions)');

            if (streamId !== requestedStreamId) {
              throw new Error('streamId response does not match request');
            }

            this.streamId = streamId;
            rumorIceServers = parseIceServers(message);
          })
          .catch((err) => {
            this.trigger('publishComplete', err);
            throw err;
          });

        return Promise.all([streamCreatedPromise, completedPromise]);
      },

      unpublishFromSession: (session, reason) => {
        if (!this.session || session.id !== this.session.id) {
          if (reason === 'unpublished') {
            const selfSessionText = (this.session && this.session.id) || 'no session';

            logging.warn(
              `The publisher ${guid} is trying to unpublish from a session ${session.id} it is not ` +
              `attached to (it is attached to ${selfSessionText})`
            );
          }

          return this;
        }

        if (session.isConnected() && (this.stream || state.isAttemptingToPublish())) {
          session._.streamDestroy(this.streamId);
        }
        streamCleanupJobs.releaseAll();

        // Disconnect immediately, rather than wait for the WebSocket to
        // reply to our destroyStream message.
        this.disconnect();
        if (state.isAttemptingToPublish()) {
          logConnectivityEvent('Cancel', { reason: 'unpublish' });

          const createErrorFromReason = () => {
            switch (reason) {
              case 'mediaStopped':
                return 'The video element fired the ended event, indicating there is an issue with the media';
              case 'unpublished':
                return 'The publisher was unpublished before it could be published';
              case 'reset':
                return 'The publisher was reset';
              default:
                return `The publisher was destroyed due to ${reason}`;
            }
          };

          const err = new Error(createErrorFromReason());

          this.trigger(
            'publishComplete',
            otError(
              reason === 'mediaStopped' ? Errors.MEDIA_ENDED : Errors.CANCEL,
              err
            )
          );
        }
        this.session = null;

        logAnalyticsEvent('unpublish', 'Success');

        this._.streamDestroyed(reason);

        return this;
      },

      unpublishStreamFromSession: (stream, session, reason) => {
        if (!lastRequestedStreamId || stream.id !== lastRequestedStreamId) {
          logging.warn(`The publisher ${guid} is trying to destroy a stream ${
            stream.id} that is not attached to it (it has ${
            lastRequestedStreamId || 'no stream'} attached to it)`);
          return this;
        }

        return this._.unpublishFromSession(session, reason);
      },

      streamDestroyed: (reason) => {
        if (['reset'].indexOf(reason) < 0) {
          // We're back to being a stand-alone publisher again.
          if (!state.isDestroyed()) { state.set('MediaBound'); }
        }

        const event = new Events.StreamEvent('streamDestroyed', this.stream, reason, true);

        this.dispatchEvent(event);
        if (!event.isDefaultPrevented()) {
          this.destroy();
        }
      },

      archivingStatus(status) {
        if (chromeMixin) {
          chromeMixin.setArchivingStatus(status);
        }
        return status;
      },

      webRtcStream() {
        return webRTCStream;
      },

      async switchTracks() {
        let stream;

        try {
          stream = await getUserMedia().catch(userMediaError);
        } catch (err) {
          logging.error(`OT.Publisher.switchTracks failed to getUserMedia: ${err}`);
          throw err;
        }

        setNewStream(stream);

        try {
          bindVideo();
        } catch (err) {
          if (err instanceof CancellationError) {
            return;
          }
          logging.error('Error while binding video', err);
          throw err;
        }

        try {
          replaceTracks();
        } catch (err) {
          logging.error('Error replacing tracks', err);
          throw err;
        }
      },

      getDataChannel(label, getOptions, completion) {
        const pc = getPeerConnectionById(Object.keys(peerConnectionsAsync)[0]);

        // @fixme this will fail if it's called before we have a PublisherPeerConnection.
        // I.e. before we have a subscriber.
        if (!pc) {
          completion(new OTHelpers.Error('Cannot create a DataChannel before there is a subscriber.'));
          return;
        }

        pc.then((peerConnection) => {
          peerConnection.getDataChannel(label, getOptions, completion);
        });
      },

      iceRestart() {
        getAllPeerConnections().then((peerConnections) => {
          peerConnections.forEach((peerConnection) => {
            const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
            logRepublish('Attempt', { remoteConnectionId });
            logging.debug('Publisher: ice restart attempt');
            peerConnection.iceRestart();
          });
        });
      },

      getState() { return state; },

      demoOnlyCycleVideo: this.cycleVideo,

      async testOnlyGetFramesEncoded() {
        // This is for an integration test only
        // Not robust as it'll only get framesEncoded for the first Peer Connection

        const peerConnections = await getAllPeerConnections();

        if (!peerConnections.length) {
          throw new Error('No established PeerConnections yet');
        }

        return peerConnections[0]._testOnlyGetFramesEncoded();
      },

      onStreamAvailable,
    };

    this.detectDevices = function () {
      logging.warn('Publisher.detectDevices() is not implemented.');
    };

    this.detectMicActivity = function () {
      logging.warn('Publisher.detectMicActivity() is not implemented.');
    };

    this.getEchoCancellationMode = function () {
      logging.warn('Publisher.getEchoCancellationMode() is not implemented.');
      return 'fullDuplex';
    };

    this.setMicrophoneGain = function () {
      logging.warn('Publisher.setMicrophoneGain() is not implemented.');
    };

    this.getMicrophoneGain = function () {
      logging.warn('Publisher.getMicrophoneGain() is not implemented.');
      return 0.5;
    };

    this.setCamera = function () {
      logging.warn('Publisher.setCamera() is not implemented.');
    };

    this.setMicrophone = function () {
      logging.warn('Publisher.setMicrophone() is not implemented.');
    };

    // Platform methods:

    this.guid = function () {
      return guid;
    };

    this.videoElement = function () {
      const video = widgetView && widgetView.video();
      return video ? video.domElement() : null;
    };

    this.setStream = assignStream;

    this.isWebRTC = true;

    this.isLoading = function () {
      return widgetView && widgetView.loading();
    };

    /**
    * Returns the width, in pixels, of the Publisher video. This may differ from the
    * <code>resolution</code> property passed in as the <code>properties</code> property
    * the options passed into the <code>OT.initPublisher()</code> method, if the browser
    * does not support the requested resolution.
    *
    * @method #videoWidth
    * @memberOf Publisher
    * @return {Number} the width, in pixels, of the Publisher video.
    */
    this.videoWidth = function () {
      const video = widgetView && widgetView.video();
      return video ? video.videoWidth() : undefined;
    };

    /**
    * Returns the height, in pixels, of the Publisher video. This may differ from the
    * <code>resolution</code> property passed in as the <code>properties</code> property
    * the options passed into the <code>OT.initPublisher()</code> method, if the browser
    * does not support the requested resolution.
    *
    * @method #videoHeight
    * @memberOf Publisher
    * @return {Number} the height, in pixels, of the Publisher video.
    */
    this.videoHeight = function () {
      const video = widgetView && widgetView.video();
      return video ? video.videoHeight() : undefined;
    };

    /**
    *  Returns the details on the publisher's stream quality, including the following:
    *
    * <ul>
    *
    *   <li>The total number of audio and video packets lost</li>
    *   <li>The total number of audio and video packets sent</li>
    *   <li>The total number of audio and video bytes sent</li>
    *   <li>The current video frame rate</li>
    *
    * </ul>
    *
    * You can use these stats to assess the quality of the publisher's audio-video stream.
    *
    * @param {Function} completionHandler A function that takes the following
    * parameters:
    *
    * <ul>
    *
    *   <li><code>error</code> (<a href="Error.html">Error</a>) &mdash; Upon successful completion
    *   the method, this is undefined. An error results if the publisher is not connected to a
    *   session or if it is not publishing audio or video.</li>
    *
    *   <li><code>statsArray</code> (Array) &mdash; An array of objects defining the current
    *   audio-video statistics for the publisher. For a publisher in a routed session (one that
    *   uses the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">OpenTok
    *   Media Router</a>), this array includes one object, defining the statistics for the single
    *   audio-media stream that is sent to the OpenTok Media Router. In a relayed session, the
    *   array includes an object for each subscriber to the published stream. Each object in the
    *   array contains a <code>stats</code> property that includes the following properties:
    *
    *     <p>
    *     <ul>
    *       <li><code>audio.bytesSent</code> (Number) &mdash; The total number of audio bytes
    *         sent to the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>audio.packetsLost</code> (Number) &mdash; The total number audio packets
    *        that did not reach the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>audio.packetsSent</code> (Number) &mdash; The total number of audio
    *        packets sent to the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>timestamp</code> (Number) &mdash; The timestamp, in milliseconds since
    *         the Unix epoch, for when these stats were gathered</li>
    *
    *       <li><code>video.bytesSent</code> (Number) &mdash; The total video bytes sent to
    *         the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>video.packetsLost</code> (Number) &mdash; The total number of video packets
    *         that did not reach the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>video.packetsSent</code> (Number) &mdash; The total number of video
    *         packets sent to the subscriber</li>
    *
    *       <li><code>video.frameRate</code> (Number) &mdash; The current video frame rate</li>
    *     </ul>
    *
    *     <p>Additionally, for a publisher in a relayed session, each object in the array contains
    *     the following two properties:
    *
    *     <ul>
    *       <li><code>connectionId</code> (String) &mdash; The unique ID of the client's
    *       connection, which matches the <code>id</code> property of the <code>connection</code>
    *       property of the <a href="Session.html##.event:connectionCreated">connectionCreated</a>
    *       event that the Session object dispatched for the remote client.</li>
    *
    *       <li><code>subscriberId</code> (String) &mdash; The unique ID of the subscriber, which
    *       matches the <code>id</code> property of the Subscriber object in the subscribing
    *       client's app.</li>
    *     </ul>
    *
    *     <p>These two properties are undefined for a publisher in a routed session.
    *
    *   </li>
    * </ul>
    *
    * @see <a href="Subscriber.html#getStats">Subscriber.getStats()</a>
    *
    * @method #getStats
    * @memberOf Publisher
    */
    this.getStats = function getStats(callback) {
      notifyGetStatsCalled();
      getAllPeerConnections()
        .then(peerConnections =>
          Promise.all(peerConnections.map(
            peerConnection => promisify(::peerConnection.getStats)()
              .then(stats => ({ pc: peerConnection, stats }))
          ))
        )
        .then((pcsAndStats) => {
          // @todo this publishStartTime is going to be so wrong in P2P
          const startTimestamp = publishStartTime ? publishStartTime.getTime() : Date.now();
          const ret = pcsAndStats.map(({ pc, stats }) => {
            const { remoteConnectionId, remoteSubscriberId } = getPeerConnectionMeta(pc);
            return assign(
              remoteConnectionId.match(/^symphony\./) ? {} : {
                subscriberId: remoteSubscriberId,
                connectionId: remoteConnectionId,
              },
              { stats: getStatsHelpers.normalizeStats(stats, false, startTimestamp) }
            );
          });
          callback(null, ret);
        })
        .catch(callback);
    };

    // Make read-only: element, guid, _.webRtcStream

    state = new PublishingState(stateChangeFailed);

    this.accessAllowed = false;
  };

  /**
  * Dispatched when the user has clicked the Allow button, granting the
  * app access to the camera and microphone. The Publisher object has an
  * <code>accessAllowed</code> property which indicates whether the user
  * has granted access to the camera and microphone.
  * @see Event
  * @name accessAllowed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the user has clicked the Deny button, preventing the
  * app from having access to the camera and microphone.
  * @see Event
  * @name accessDenied
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny dialog box is opened. (This is the dialog box in which
  * the user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogOpened
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny box is closed. (This is the dialog box in which the
  * user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogClosed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched periodically to indicate the publisher's audio level. The event is dispatched
  * up to 60 times per second, depending on the browser. The <code>audioLevel</code> property
  * of the event is audio level, from 0 to 1.0. See {@link AudioLevelUpdatedEvent} for more
  * information.
  * <p>
  * The following example adjusts the value of a meter element that shows volume of the
  * publisher. Note that the audio level is adjusted logarithmically and a moving average
  * is applied:
  * <p>
  * <pre>
  * var movingAvg = null;
  * publisher.on('audioLevelUpdated', function(event) {
  *   if (movingAvg === null || movingAvg &lt;= event.audioLevel) {
  *     movingAvg = event.audioLevel;
  *   } else {
  *     movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
  *   }
  *
  *   // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
  *   var logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
  *   logLevel = Math.min(Math.max(logLevel, 0), 1);
  *   document.getElementById('publisherMeter').value = logLevel;
  * });
  * </pre>
  * <p>This example shows the algorithm used by the default audio level indicator displayed
  * in an audio-only Publisher.
  *
  * @name audioLevelUpdated
  * @event
  * @memberof Publisher
  * @see AudioLevelUpdatedEvent
  */

  /**
   * The publisher has started streaming to the session.
   * @name streamCreated
   * @event
   * @memberof Publisher
   * @see StreamEvent
   * @see <a href="Session.html#publish">Session.publish()</a>
   */

  /**
   * The publisher has stopped streaming to the session. The default behavior is that
   * the Publisher object is removed from the HTML DOM. The Publisher object dispatches a
   * <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
   * <code>preventDefault()</code> method of the event object in the event listener, the default
   * behavior is prevented, and you can, optionally, retain the Publisher for reuse or clean it up
   * using your own code.
   * @name streamDestroyed
   * @event
   * @memberof Publisher
   * @see StreamEvent
   */

  /**
  * Dispatched when the Publisher element is removed from the HTML DOM. When this event
  * is dispatched, you may choose to adjust or remove HTML DOM elements related to the publisher.
  * @name destroyed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the video dimensions of the video change. This can only occur in when the
  * <code>stream.videoType</code> property is set to <code>"screen"</code> (for a screen-sharing
  * video stream), when the user resizes the window being captured. This event object has a
  * <code>newValue</code> property and an <code>oldValue</code> property, representing the new and
  * old dimensions of the video. Each of these has a <code>height</code> property and a
  * <code>width</code> property, representing the height and width, in pixels.
  * @name videoDimensionsChanged
  * @event
  * @memberof Publisher
  * @see VideoDimensionsChangedEvent
*/

  /**
  * Dispatched when the Publisher's video element is created. Add a listener for this event when
  * you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method. The <code>element</code>
  * property of the event object is a reference to the Publisher's <code>video</code> element
  * (or in Internet Explorer the <code>object</code> element containing the video). Add it to
  * the HTML DOM to display the video. When you set the <code>insertDefaultUI</code> option to
  * <code>false</code>, the <code>video</code> (or <code>object</code>) element is not
  * automatically inserted into the DOM.
  * <p>
  * Add a listener for this event only if you have set the <code>insertDefaultUI</code> option to
  * <code>false</code>. If you have not set <code>insertDefaultUI</code> option to
  * <code>false</code>, do not move the <code>video</code> (or <code>object</code>) element in
  * in the HTML DOM. Doing so causes the Publisher object to be destroyed.
  *
  * @name videoElementCreated
  * @event
  * @memberof Publisher
  * @see VideoElementCreatedEvent
  */

  /**
   * The user publishing the stream has stopped sharing one or all media
   * types (video, audio and/or screen). This can occur when a user disconnects a camera or
   * microphone used as a media source for the Publisher. Or it can occur when a user closes
   * a when the video and audio sources of the stream are MediaStreamTrack elements and
   * tracks are stopped or destroyed.
   *
   * @name mediaStopped
   * @event
   * @memberof Publisher
   * @see MediaStoppedEvent
   */
  return Publisher;
};

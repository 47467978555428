// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void, no-use-before-define, one-var */
/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-underscore-dangle */

const OTPlugin = require('../../../otplugin/otplugin.js');
const OTHelpers = require('../../../common-js-helpers/OTHelpers.js');
const logging = require('../../../helpers/log')('QoS');
const sdpHelpers = require('../sdp_helpers.js');

const requiredPublisherKeys = ['audioCodec', 'audioSentBytes', 'audioSentPackets',
  'audioSentPacketsLost', 'videoCodec', 'videoHeight', 'videoHeightInput', 'videoSentBytes',
  'videoFrameRateSent', 'videoSentPackets', 'videoRtt', 'videoSentPacketsLost', 'videoWidthInput',
  'videoWidth'];

const requiredSubscriberKeys = ['audioCodec', 'audioRecvBytes', 'audioRecvPackets',
  'audioRecvPacketsLost', 'videoCodec', 'videoHeight', 'videoRecvBytes', 'videoRecvPackets',
  'videoRecvPacketsLost', 'videoFrameRateReceived', 'videoRtt', 'videoWidth'];

//
// There are three implementations of stats parsing in this file.
// 1. For Chrome: Chrome is currently using an older version of the API
// 2. For OTPlugin: The plugin is using a newer version of the API that
//    exists in the latest WebRTC codebase
// 3. For Firefox: FF is using a version that looks a lot closer to the
//    current spec.
//
// I've attempted to keep the three implementations from sharing any code,
// accordingly you'll notice a bunch of duplication between the three.
//
// This is acceptable as the goal is to be able to remove each implementation
// as it's no longer needed without any risk of affecting the others. If there
// was shared code between them then each removal would require an audit of
// all the others.
//
//

const rawStatsHandler = (pc, stats) => logging.debug(`Raw stats for peer conn ${pc.id}:`, stats);

// /
// Get Stats using the older API. Used by all current versions
// of Chrome.
//
export function parseStatsOldAPI(
  peerConnection,
  prevStats,
  currentStats,
  isPublisher,
  completion
) {
  /* this parses a result if there it contains the video bitrate */
  const parseVideoStats = (result) => {
    if (Number(result.stat('googFrameRateSent')) > 0) {
      currentStats.videoSentBytes = Number(result.stat('bytesSent'));
      currentStats.videoSentPackets = Number(result.stat('packetsSent'));
      currentStats.videoSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoRtt = Number(result.stat('googRtt'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateInput'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthSent'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightSent'));
      currentStats.videoFrameRateSent = Number(result.stat('googFrameRateSent'));
      currentStats.videoWidthInput = Number(result.stat('googFrameWidthInput'));
      currentStats.videoHeightInput = Number(result.stat('googFrameHeightInput'));
      currentStats.videoCodec = result.stat('googCodecName');
    } else if (Number(result.stat('googFrameRateReceived')) > 0) {
      currentStats.videoRecvBytes = Number(result.stat('bytesReceived'));
      currentStats.videoRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.videoRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateOutput'));
      currentStats.videoFrameRateReceived = Number(result.stat('googFrameRateReceived'));
      currentStats.videoFrameRateDecoded = Number(result.stat('googFrameRateDecoded'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthReceived'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightReceived'));
      currentStats.videoCodec = result.stat('googCodecName');
    }

    return null;
  };

  const parseAudioStats = (result) => {
    if (Number(result.stat('audioInputLevel')) > 0) {
      currentStats.audioSentPackets = Number(result.stat('packetsSent'));
      currentStats.audioSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioSentBytes = Number(result.stat('bytesSent'));
      currentStats.audioCodec = result.stat('googCodecName');
      currentStats.audioRtt = Number(result.stat('googRtt'));
    } else if (Number(result.stat('audioOutputLevel')) > 0) {
      currentStats.audioRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.audioRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioRecvBytes = Number(result.stat('bytesReceived'));
      currentStats.audioCodec = result.stat('googCodecName');
    }
  };

  function getProtocolFromPriority(priority) {
    switch (priority >> 24) { // eslint-disable-line no-bitwise
      case 0:
        return 'TURN/TLS';
      case 1:
        return 'TURN/TCP';
      case 2:
        return 'TURN/UDP';
      default:
        return '';
    }
  }

  const parseStatsReports = (stats) => {
    if (stats.result) {
      const resultList = stats.result();

      rawStatsHandler(peerConnection, resultList);

      const getCandidate = (type, fromStat) =>
        resultList.filter(x => x.id === fromStat.stat(type))[0];
      const getLocalCandidate = fromStat => getCandidate('localCandidateId', fromStat);
      const getRemoteCandidate = fromStat => getCandidate('remoteCandidateId', fromStat);

      for (let resultIndex = 0; resultIndex < resultList.length; resultIndex++) {
        const result = resultList[resultIndex];

        if (result.stat) {
          if (result.stat('googActiveConnection') === 'true') {
            if (result.stat('googChannelId').indexOf('audio') > -1) {
              currentStats.audioLocalAddress = result.stat('googLocalAddress');
              currentStats.audioRemoteAddress = result.stat('googRemoteAddress');
              currentStats.audioLocalCandidateType = result.stat('googLocalCandidateType');
              currentStats.audioRemoteCandidateType = result.stat('googRemoteCandidateType');
              currentStats.audioTransportType = result.stat('googTransportType');
              currentStats.audioLocalRelayProtocol = getProtocolFromPriority(
                getLocalCandidate(result).stat('priority')
              );
              currentStats.audioRemoteRelayProtocol = getProtocolFromPriority(
                getRemoteCandidate(result).stat('priority')
              );
            } else if (result.stat('googChannelId').indexOf('video') > -1) {
              currentStats.videoLocalAddress = result.stat('googLocalAddress');
              currentStats.videoRemoteAddress = result.stat('googRemoteAddress');
              currentStats.videoLocalCandidateType = result.stat('googLocalCandidateType');
              currentStats.videoRemoteCandidateType = result.stat('googRemoteCandidateType');
              currentStats.videoTransportType = result.stat('googTransportType');
              currentStats.videoLocalRelayProtocol = getProtocolFromPriority(
                getLocalCandidate(result).stat('priority')
              );
              currentStats.videoRemoteRelayProtocol = getProtocolFromPriority(
                getRemoteCandidate(result).stat('priority')
              );
            }
          }

          parseAudioStats(result);
          parseVideoStats(result);
        }
      }

      // For audio-video publishers in Chrome, there are no corresponding video reports for these

      if ('videoCodec' in currentStats && !currentStats.videoLocalAddress) {
        [
          'LocalAddress',
          'RemoteAddress',
          'LocalCandidateType',
          'RemoteCandidateType',
          'TransportType',
          'LocalRelayProtocol',
          'RemoteRelayProtocol',
        ].forEach((keySuffix) => {
          currentStats[`video${keySuffix}`] = currentStats[`audio${keySuffix}`];
        });
      }
    }

    completion(null, currentStats);
  };

  peerConnection.getStats(parseStatsReports);
}

// /
// Get Stats for the OT Plugin, newer than Chromes version, but
// still not in sync with the spec.
//
export function parseStatsOTPlugin(peerConnection,
  prevStats,
  currentStats,
  isPublisher,
  completion) {
  const onStatsError = function onStatsError(error) {
    completion(error);
  };

  // /
  // From the Audio Tracks
  // * audioBytesTransferred
  //
  const parseAudioStats = (statsReport) => {
    if (Number(statsReport.audioInputLevel) > 0) {
      currentStats.audioSentBytes = Number(statsReport.bytesSent);
      currentStats.audioSentPackets = Number(statsReport.packetsSent);
      currentStats.audioSentPacketsLost = Number(statsReport.packetsLost);
      currentStats.audioRtt = Number(statsReport.googRtt);
      currentStats.audioCodec = statsReport.googCodecName;
    } else if (Number(statsReport.audioOutputLevel) > 0) {
      currentStats.audioRecvBytes = Number(statsReport.bytesReceived);
      currentStats.audioRecvPackets = Number(statsReport.packetsReceived);
      currentStats.audioRecvPacketsLost = Number(statsReport.packetsLost);
      currentStats.audioCodec = statsReport.googCodecName;
    }
  };

  // /
  // From the Video Tracks
  // * frameRate
  // * videoBytesTransferred
  //
  const parseVideoStats = (statsReport) => {
    if (Number(statsReport.googFrameHeightSent) > 0) {
      currentStats.videoSentBytes = Number(statsReport.bytesSent);
      currentStats.videoSentPackets = Number(statsReport.packetsSent);
      currentStats.videoSentPacketsLost = Number(statsReport.packetsLost);
      currentStats.videoRtt = Number(statsReport.googRtt);
      currentStats.videoCodec = statsReport.googCodecName;
      currentStats.videoWidth = Number(statsReport.googFrameWidthSent);
      currentStats.videoHeight = Number(statsReport.googFrameHeightSent);
      currentStats.videoFrameRateSent = Number(statsReport.googFrameRateSent);
      currentStats.videoWidthInput = Number(statsReport.googFrameWidthInput);
      currentStats.videoHeightInput = Number(statsReport.googFrameHeightInput);
    } else if (Number(statsReport.googFrameHeightReceived) > 0) {
      currentStats.videoRecvBytes = Number(statsReport.bytesReceived);
      currentStats.videoRecvPackets = Number(statsReport.packetsReceived);
      currentStats.videoRecvPacketsLost = Number(statsReport.packetsLost);
      currentStats.videoRtt = Number(statsReport.googRtt);
      currentStats.videoCodec = statsReport.googCodecName;
      currentStats.videoFrameRateReceived = Number(statsReport.googFrameRateReceived);
      currentStats.videoFrameRateDecoded = Number(statsReport.googFrameRateDecoded);
      currentStats.videoWidth = Number(statsReport.googFrameWidthReceived);
      currentStats.videoHeight = Number(statsReport.googFrameHeightReceived);
    }

    if (Number(statsReport.googFrameRateInput) > 0) {
      currentStats.videoFrameRate = Number(statsReport.googFrameRateInput);
    } else if (Number(statsReport.googFrameRateOutput) > 0) {
      currentStats.videoFrameRate = Number(statsReport.googFrameRateOutput);
    }
  };

  const isStatsForVideoTrack = statsReport => (
    statsReport.googFrameHeightSent !== void 0 ||
    statsReport.googFrameHeightReceived !== void 0 ||
    currentStats.videoBytesTransferred !== void 0 ||
    statsReport.googFrameRateSent !== void 0
  );

  const isStatsForIceCandidate = statsReport => (
    statsReport.googActiveConnection === 'true'
  );

  peerConnection.getStats(null).then((statsReports) => {
    rawStatsHandler(peerConnection, statsReports);
    statsReports.forEach((statsReport) => {
      if (isStatsForIceCandidate(statsReport)) {
        if (statsReport.googChannelId.indexOf('audio') > -1) {
          currentStats.audioLocalAddress = statsReport.googLocalAddress;
          currentStats.audioRemoteAddress = statsReport.googRemoteAddress;
          currentStats.audioLocalCandidateType = statsReport.googLocalCandidateType;
          currentStats.audioRemoteCandidateType = statsReport.googRemoteCandidateType;
          currentStats.audioTransportType = statsReport.googTransportType;
        } else if (statsReport.googChannelId.indexOf('video') > -1) {
          currentStats.videoLocalAddress = statsReport.googLocalAddress;
          currentStats.videoRemoteAddress = statsReport.googRemoteAddress;
          currentStats.videoLocalCandidateType = statsReport.googLocalCandidateType;
          currentStats.videoRemoteCandidateType = statsReport.googRemoteCandidateType;
          currentStats.videoTransportType = statsReport.googTransportType;
        }
      } else if (isStatsForVideoTrack(statsReport)) {
        parseVideoStats(statsReport);
      } else {
        parseAudioStats(statsReport);
      }
    });

    // For publishers with audio, there are no corresponding video reports for these
    if (currentStats.videoFrameRateSent && !currentStats.videoLocalAddress) {
      currentStats.videoLocalAddress = currentStats.audioLocalAddress;
      currentStats.videoRemoteAddress = currentStats.audioRemoteAddress;
      currentStats.videoLocalCandidateType = currentStats.audioLocalCandidateType;
      currentStats.videoRemoteCandidateType = currentStats.audioLocalCandidateType;
      currentStats.videoTransportType = currentStats.audioTransportType;
    }

    extendStats(currentStats, isPublisher);
    completion(null, currentStats);
  }, onStatsError);
}

// /
// Get Stats using the newer API.
//
export function parseStatsNewAPI(peerConnection,
  prevStats,
  currentStats,
  isPublisher,
  completion) {
  const onStatsError = function onStatsError(error) {
    completion(error);
  };

  const parseAudioStats = (result, strippedType) => {
    if (strippedType === 'outboundrtp') {
      currentStats.audioSentPackets = result.packetsSent;
      currentStats.audioSentPacketsLost = result.packetsLost;
      currentStats.audioSentBytes = result.bytesSent;
    } else if (strippedType === 'inboundrtp') {
      currentStats.audioRecvPackets = result.packetsReceived;
      currentStats.audioRecvPacketsLost = result.packetsLost;
      currentStats.audioRecvBytes = result.bytesReceived;
    }
    // OPENTOK-36308 Safari doesn't have audioCodec but on the publisher side it can be mapped
    // to the SDP.  On the subscriber side, currentStats.audioCodec will remain undefined, and
    // will be set (possibly incorrectly) by picking the first rtpmap line out of the SDP.
    if ((undefined === currentStats.audioCodec) &&
    (['Safari'].indexOf(OTHelpers.env.name) !== -1) && (undefined !== result.codecId)) {
      const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
        peerConnection.remoteDescription : peerConnection.localDescription;
      const lastIndex = result.codecId.lastIndexOf('_');
      const codecsAndCodecMap = sdpHelpers.getCodecsAndCodecMap(sdp, 'audio');
      if ((lastIndex !== -1) && (codecsAndCodecMap !== undefined)) {
        currentStats.audioCodec =
        codecsAndCodecMap.codecMap[result.codecId.substring(lastIndex + 1)];
      }
    }
  };

  const parseVideoStats = (result, strippedType) => {
    let haveBytes = false;
    if (strippedType === 'outboundrtp') {
      currentStats.videoSentPackets = result.packetsSent;
      currentStats.videoSentPacketsLost = result.packetsLost;
      currentStats.videoSentBytes = result.bytesSent;
      haveBytes = (currentStats.videoSentBytes > 0);
    } else if (strippedType === 'inboundrtp') {
      currentStats.videoRecvPackets = result.packetsReceived;
      currentStats.videoRecvPacketsLost = result.packetsLost;
      currentStats.videoRecvBytes = result.bytesReceived;
      haveBytes = (currentStats.videoRecvBytes > 0);
    }
    if (typeof result.framerateMean === 'number') {
      if (haveBytes) {
        currentStats.videoFrameRate = Number(result.framerateMean);
      } else {
        currentStats.videoFrameRate = 0;
      }
    }
    // OPENTOK-36308 Safari doesn't have videoCodec but on the publisher side it can be mapped
    // to the SDP.  On the subscriber side, currentStats.videoCodec will remain undefined, and
    // will be set (possibly incorrectly) by picking the first rtpmap line out of the SDP.
    if ((undefined === currentStats.videoCodec) &&
    (['Safari'].indexOf(OTHelpers.env.name) !== -1) && (undefined !== result.codecId)) {
      const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
        peerConnection.remoteDescription : peerConnection.localDescription;
      const lastIndex = result.codecId.lastIndexOf('_');
      const codecsAndCodecMap = sdpHelpers.getCodecsAndCodecMap(sdp, 'video');
      if ((lastIndex !== -1) && (codecsAndCodecMap !== undefined)) {
        currentStats.videoCodec =
        codecsAndCodecMap.codecMap[result.codecId.substring(lastIndex + 1)];
      }
    }
  };

  peerConnection.getStats(null).then((stats) => {
    let localCandidateType,
      remoteCandidateType,
      localAddress,
      remoteAddress,
      transportType,
      frameWidth,
      frameHeight;

    const statsLoop = (res) => {
      const strippedType = res.type.toLowerCase().replace(/[^a-z]/g, '');
      const lowercaseId = res.id.toLowerCase();
      if (strippedType === 'outboundrtp' || strippedType === 'inboundrtp') {
        if (lowercaseId.indexOf('rtp') !== -1) {
          if (lowercaseId.indexOf('audio') !== -1) {
            parseAudioStats(res, strippedType);
          } else if (lowercaseId.indexOf('video') !== -1) {
            parseVideoStats(res, strippedType);
          }
        }
        if (res.hasOwnProperty('roundTripTime')) {
          // roundTripTime comes on the rtcp stats and so won't be caught above
          if (res.mediaType === 'video') {
            currentStats.videoRtt = res.roundTripTime;
          } else if (res.mediaType === 'audio') {
            currentStats.audioRtt = res.roundTripTime;
          }
        }
      } else if (strippedType === 'localcandidate') {
        localCandidateType = res.candidateType;
        localAddress = `${res.ipAddress}:${res.portNumber}`;
        transportType = res.protocol || res.transport;
      } else if (strippedType === 'remotecandidate') {
        remoteCandidateType = res.candidateType;
        remoteAddress = `${res.ipAddress}:${res.portNumber}`;
      } else if (strippedType === 'track' && lowercaseId.indexOf('video') !== 0) {
        frameWidth = res.frameWidth;
        frameHeight = res.frameHeight;

        if (typeof res.framesPerSecond === 'number') {
          currentStats.videoFrameRate = res.framesPerSecond;
        }
      }
    };

    // Firefox <= 45 can't use for of loop OPENTOK-32755
    if (typeof stats[Symbol.iterator] === 'function') {
      rawStatsHandler(peerConnection, Array.from(stats));
      for (const el of stats) {
        const res = Array.isArray(el) ? el[1] : el;
        statsLoop(res);
      }
    } else {
      rawStatsHandler(peerConnection, stats);
      for (const key in stats) {
        if (stats.hasOwnProperty(key)) {
          statsLoop(stats[key]);
        }
      }
    }

    if (currentStats.audioRecvBytes || currentStats.audioSentBytes) {
      currentStats.audioLocalCandidateType = localCandidateType;
      currentStats.audioLocalAddress = localAddress;
      currentStats.audioRemoteCandidateType = remoteCandidateType;
      currentStats.audioRemoteAddress = remoteAddress;
      currentStats.audioTransportType = transportType;
      if (currentStats.audioCodec === undefined) {
        const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
          peerConnection.remoteDescription : peerConnection.localDescription;
        const codecs = sdpHelpers.getCodecs(sdp, 'audio');
        currentStats.audioCodec = codecs[0];
      }
    }

    if (currentStats.videoRecvBytes || currentStats.videoSentBytes) {
      currentStats.videoLocalCandidateType = localCandidateType;
      currentStats.videoLocalAddress = localAddress;
      currentStats.videoRemoteCandidateType = remoteCandidateType;
      currentStats.videoRemoteAddress = remoteAddress;
      currentStats.videoTransportType = transportType;
      if (currentStats.videoCodec === undefined) {
        const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
          peerConnection.remoteDescription : peerConnection.localDescription;
        const codecs = sdpHelpers.getCodecs(sdp, 'video');
        currentStats.videoCodec = codecs[0];
      }
    }

    extendStats(currentStats, isPublisher);
    currentStats.videoWidth = currentStats.videoWidth || frameWidth;
    currentStats.videoHeight = currentStats.videoHeight || frameHeight;
    completion(null, currentStats);
  }).catch(onStatsError);
}

export default function parseQOS(peerConnection, prevStats, currentStats, isPublisher, completion) {
  if (OTPlugin.isInstalled()) {
    return parseStatsOTPlugin(peerConnection, prevStats, currentStats, isPublisher, completion);
  } else if (['Firefox', 'Edge', 'Safari'].indexOf(OTHelpers.env.name) !== -1) {
    return parseStatsNewAPI(peerConnection, prevStats, currentStats, isPublisher, completion);
  }

  return parseStatsOldAPI(peerConnection, prevStats, currentStats, isPublisher, completion);
}

function extendStats(stats, isPublisher) {
  const requiredKeys = isPublisher ? requiredPublisherKeys : requiredSubscriberKeys;
  requiredKeys.forEach((key) => {
    if (!stats.hasOwnProperty(key)) {
      stats[key] = null;
    }
  });
}

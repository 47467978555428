module.exports = function electronExtensionHelperFactory(deps = {}) {
  let desktopCapturer = null;

  try {
    // Using a dynamic require here because that's the way we get desktopCapturer in electron, and
    // that's not something to bundle in.

    // We are requiring electron here to see if it's available, it's intentionally not packaged
    // and not listed in the package.json for this repository. It is expected to be found only if
    // we are running inside an Electron application.

    const req = (() => {
      // We need to search for the require function either in the current
      // global or, if its a window, one of its parents. This is necessary
      // in case OT is used from within an iframe (like our integration
      // tests).
      let current = global;
      while (
        typeof current.require !== 'function' &&
        current.parent &&
        current.parent !== current
      ) {
        current = current.parent;
      }
      return current.require;
    })();

    desktopCapturer = deps.desktopCapturer || req('electron').desktopCapturer;
  } catch (e) {
    // Ignore
  }

  return {
    isSupportedInThisBrowser: desktopCapturer !== null,
    autoRegisters: true,
    extensionRequired: false,
    getConstraintsShowsPermissionUI: false,
    sources: {
      screen: true,
      application: true,
      window: true,
      browser: true,
    },

    register: () => ({
      isInstalled: callback => callback(true),
      getConstraints(source, constraints, callback) {
        desktopCapturer.getSources({ types: [source] }, (error, sources) => {
          if (error) {
            callback(error);
            return;
          }

          // For now just pick the first source. In future we need to implement a selection
          // dialog.
          const foundSource = sources[0];

          if (!foundSource) {
            callback(new Error('Could not find Electron source for screensharing'));
            return;
          }

          callback(undefined, {
            audio: false,
            video: {
              mandatory: {
                chromeMediaSource: source,
                chromeMediaSourceId: foundSource.id,
              },
            },
          });
        });
      },
    }),
  };
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-shadow */

const eventing = require('../../../src/js/helpers/eventing');

module.exports = function MediaStreamTrack(mediaStreamId, options, plugin) {
  const Proto = function MediaStreamTrack() {};
  const mediaStreamTrack = new Proto();
  eventing(mediaStreamTrack);

  mediaStreamTrack.id = options.id;
  mediaStreamTrack.kind = options.kind;
  mediaStreamTrack.label = options.label;
  mediaStreamTrack.streamId = mediaStreamId;
  mediaStreamTrack.readyState = 'live';

  let enabled = options.enabled === true || options.enabled === 'true';

  Object.defineProperty(mediaStreamTrack, 'enabled', {
    get() { return enabled; },
    set(newEnabledValue) {
      enabled = newEnabledValue === true || newEnabledValue === 'true';

      if (enabled) {
        plugin._.enableMediaStreamTrack(mediaStreamId, mediaStreamTrack.id);
      } else {
        plugin._.disableMediaStreamTrack(mediaStreamId, mediaStreamTrack.id);
      }
    },
  });

  // Deprecated: use .enabled property directly
  mediaStreamTrack.setEnabled = (newEnabledValue) => {
    mediaStreamTrack.enabled = newEnabledValue;
  };

  mediaStreamTrack.stop = () => {
    mediaStreamTrack.readyState = 'ended';
    mediaStreamTrack.dispatchEvent({ type: 'ended' });
  };

  mediaStreamTrack.getAudioVolume = () => (
    plugin._.getAudioVolume(
      mediaStreamTrack.streamId,
      mediaStreamTrack.id
    )
  );

  mediaStreamTrack.setAudioVolume = (value) => {
    plugin._.setAudioVolume(
      mediaStreamTrack.streamId,
      mediaStreamTrack.id,
      value
    );
  };

  return mediaStreamTrack;
};

/* eslint-disable no-underscore-dangle */

const MediaStreamTrack = require('./media_stream_track.js');
const VideoContainer = require('./video_container.js');

class MediaStream {
  static streams = {};
  static fromJson(json, plugin) {
    if (!json) {
      return null; // TODO: This seems odd. Consider removing/throwing/logging.
    }
    const mediaStream = new MediaStream(JSON.parse(json), plugin);
    MediaStream.streams[mediaStream.id] = mediaStream;
    return mediaStream;
  }
  static getOrCreate(json, plugin) {
    if (!json) {
      return null; // TODO: This seems odd. Consider removing/throwing/logging.
    }
    const { id } = JSON.parse(json);
    return MediaStream.streams[id] || MediaStream.fromJson(json, plugin);
  }
  _audioTracks = [];
  _videoTracks = [];
  constructor({ id, videoTracks, audioTracks }, plugin) {
    this.id = id;
    plugin.refCounter.add(this);

    const createMediaStreamTrack = track => new MediaStreamTrack(id, track, plugin);

    if (videoTracks) {
      this._videoTracks.push(...videoTracks.map(createMediaStreamTrack));
    }

    if (audioTracks) {
      this._audioTracks.push(...audioTracks.map(createMediaStreamTrack));
    }

    this._ = {
      plugin,
      render: () => new VideoContainer(plugin, this),
    };
  }
  getVideoTracks() {
    return this._videoTracks;
  }
  getAudioTracks() {
    return this._audioTracks;
  }
  getTracks() {
    return [...this._videoTracks, ...this._audioTracks];
  }
  getTrackById(id) {
    return this.getTracks().filter(track => track.id === id)[0] || null;
  }
  hasVideo() {
    return this._videoTracks.some(track => track.enabled);
  }
  hasAudio() {
    return this._audioTracks.some(track => track.enabled);
  }
  stop() {
    this._.plugin._.stopMediaStream(this.id);
    this._.plugin.refCounter.remove(this);
  }
  destroy() {
    this.stop();
  }
}

module.exports = MediaStream;

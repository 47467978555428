const { name, version } = require('../../helpers/env');

module.exports = function usingOptionalMandatoryStyleFactory(
  { navigator = window.navigator } = {}
) {
  return function usingOptionalMandatoryStyle(isScreenSharing) {
    return (
      isScreenSharing ||

      // Internet Explorer
      !(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||

      // Chrome <=60 doesn't accept ideal constraints and advanced
      // constraints are ignored by Firefox and broken by adapter.js
      // so we revert to optional/mandatory style. This has been
      // updated in Chrome 61.
      // See OPENTOK-32149, OPENTOK-32129 and OPENTOK-32655
      // Fixed in adapter.js: https://github.com/webrtc/adapter/issues/553
      (name === 'Chrome' && version < 61)
    );
  };
};

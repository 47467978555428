/* eslint-disable no-underscore-dangle */
const eventing = require('./eventing');
const hasPassiveCapability = require('./hasPassiveCapability');

const defaultLogging = require('./log')('WidgetView');
const DefaultOTHelpers = require('../common-js-helpers/OTHelpers');
const DefaultOTPlugin = require('../otplugin/otplugin');
const defaultIsiOS = require('./isiOS');
const defaultiOSVersion = require('./iOSVersion');
const defaultVersionGreaterThan = require('../otplugin/version_greater_than');
const defaultEnv = require('./env');
const { default: Cancellation, CancellationError } = require('cancel');
const unblockAudio = require('../ot/unblockAudio');
const waitForVideoResolution = require('./waitForVideoResolution');
const waitForVideoToBePlaying = require('./waitForVideoToBePlaying');
const fixMini = require('./widget_view/fixMini');
const getOrCreateContainer = require('./widget_view/getOrCreateContainer');
const ensureCSSUnit = require('./widget_view/ensureCSSUnit');
const eventHelper = require('./eventHelper');

const DefaultVideoElementFacadeFactory = require('./video_element');

// eslint-disable-next-line no-unused-vars
const typeVideoElementFacade = DefaultVideoElementFacadeFactory();

/**
 * WidgetViewFactory - DI factory for WidgetView
 *
 * @package
 * @param {Object} deps
 * @param {DefaultVideoElementFacadeFactory} deps.VideoElementFacade
 * @return WidgetView
 */
function WidgetViewFactory({
  logging = defaultLogging,
  OTHelpers = DefaultOTHelpers,
  OTPlugin = DefaultOTPlugin,
  isiOS = defaultIsiOS,
  iOSVersion = defaultiOSVersion,
  versionGreaterThan = defaultVersionGreaterThan,
  VideoElementFacade = DefaultVideoElementFacadeFactory(),
  env = defaultEnv,
} = {}) {
  // We pass in the real window rather than a mock because hasPassiveCapability
  // needs to attach/remove real event listeners on the window which won't work
  // using the mock window.
  const supportsPassive = hasPassiveCapability({ window: global });

  /**
   * WidgetView - A standard abstraction used by Subscriber and Publisher for
   * displaying the video and it's UI.
   *
   * @package
   * @class
   * @param {Element} targetElement the element to attach this WidgetView to
   * @param {Object} properties
   */
  class WidgetView {
    /** @type {typeVideoElementFacade} */
    _videoElementFacade;
    /** @type {HTMLElement} */
    _container = undefined;

    _posterContainer = document.createElement('div');
    _widgetContainer = document.createElement('div');
    _loading = true;
    _audioOnly = false;
    _showPoster = undefined;
    _poster = undefined;

    _cancelBind = undefined;

    constructor(
      /** @type {HTMLElement} */
      targetElement,
      {
        insertDefaultUI = true,
        width = 264,
        height = 198,
        fitMode = 'cover',
        mirror = false,
        insertMode,
        classNames,
        style,
      } = {}
    ) {
      eventing(this);

      if (/^(contain|cover)$/.test(fitMode) === false) {
        logging.warn(`Invalid fit value "${fitMode}" passed. Only "contain" and "cover" can be used.`);
      }

      this._fitMode = fitMode;
      this._insertDefaultUI = insertDefaultUI;

      const userInteractionEventHandlers = eventHelper(this._widgetContainer);
      userInteractionEventHandlers.on('click', this.userGesture.bind(this));
      userInteractionEventHandlers.on('touchstart', this.userGesture.bind(this), supportsPassive ? { passive: true } : false);

      this.once('destroy', () => userInteractionEventHandlers.removeAll());

      this._widgetContainer.classList.add('OT_widget-container');

      this._widgetContainer.style.width = '100%'; // container.style.width;
      this._widgetContainer.style.height = '100%'; // container.style.height;

      if (insertDefaultUI !== false) {
        this._container = getOrCreateContainer(targetElement, insertMode);
        if (env.name === 'Safari' && isiOS() && iOSVersion() && versionGreaterThan('11.2', iOSVersion())) {
          this._container.classList.add('OT_ForceContain');
        }
        this._container.style.width = ensureCSSUnit(width);
        this._container.style.height = ensureCSSUnit(height);
        this._container.style.overflow = 'hidden';
        fixMini(this._container);

        if (mirror) {
          OTHelpers.addClass(this._container, 'OT_mirrored');
        }

        if (classNames) {
          // @todo Refactor to avoid passing classNames to widgetView
          classNames.trim().split(/\s+/).forEach(className => this._container.classList.add(className));
        }

        this._container.classList.add('OT_loading');
        this._container.classList.add(`OT_fit-mode-${fitMode}`);
        this._container.appendChild(this._widgetContainer);

        if (!OTPlugin.isInstalled()) {
          // Observe changes to the width and height and update the aspect ratio
          const [sizeObserver] = OTHelpers(this._container).observeSize(
            () => fixMini(this._container)
          );

          // @todo observe if the video container or the video element get removed
          // if they do we should do some cleanup
          const videoObserver = OTHelpers.observeNodeOrChildNodeRemoval(
            this._container,
            (removedNodes) => {
              if (!this._videoElementFacade) {
                return;
              }
              // check if our widget container / video element was removed
              const videoRemoved = removedNodes.some(
                node => node === this._widgetContainer ||
                node === this._videoElementFacade.domElement()
              );
              if (videoRemoved) {
                this.destroyVideo();
              }
            }
          );

          this.once('destroy', () => {
            logging.debug('disconnecting observers');
            sizeObserver.disconnect();
            videoObserver.disconnect();
          });
        }
      }

      this._posterContainer.classList.add('OT_video-poster');
      this._widgetContainer.appendChild(this._posterContainer);

      const loadingContainer = document.createElement('div');
      loadingContainer.classList.add('OT_video-loading');
      const loadingSpinner = document.createElement('div');
      loadingSpinner.classList.add('OT_video-loading-spinner');
      loadingContainer.appendChild(loadingSpinner);
      this._widgetContainer.appendChild(loadingContainer);

      if (style && style.backgroundImageURI) {
        this.setBackgroundImageURI(style.backgroundImageURI);
      }
    }
    get domElement() {
      return this._container;
    }
    addError(errorMsg, helpMsg, classNames) {
      if (this._container) {
        this._container.innerHTML = `<p>${errorMsg}${helpMsg ? ` <span class="ot-help-message">${helpMsg}</span>` : ''}</p>`;
        OTHelpers.addClass(this._container, classNames || 'OT_subscriber_error');
        if (this._container.querySelector('p').offsetHeight > this._container.offsetHeight) {
          this._container.querySelector('span').style.display = 'none';
        }
      }
    }
    /**
     * Destroy the video
     */
    destroy() {
      this.emit('destroy');

      this.destroyVideo();
      if (this._container) {
        OTHelpers.removeElement(this._container);
        this._container = null;
      }
    }
    setBackgroundImageURI(bgImgURI) {
      OTHelpers.css(this._posterContainer, 'backgroundImage', `url(${bgImgURI})`);
      OTHelpers.css(this._posterContainer, 'backgroundSize', 'contain');
      OTHelpers.css(this._posterContainer, 'opacity', '1.0');
    }
    isAudioBlocked() {
      return Boolean(
        this._videoElementFacade &&
        this._videoElementFacade.isAudioBlocked()
      );
    }
    unblockAudio() {
      return this._videoElementFacade.unblockAudio();
    }
    userGesture() {
      if (this.isAudioBlocked()) {
        unblockAudio().then(
          () => logging.debug('Successfully unblocked audio'),
          err => logging.error('Error retrying audio on user interaction:', err)
        );
      }
    }
    setAudioBlockedUi(audioBlocked) {
      if (!this._container) {
        return;
      }
      if (audioBlocked) {
        this._container.classList.add('OT_container-audio-blocked');
      } else {
        this._container.classList.remove('OT_container-audio-blocked');
      }
    }
    /**
     * Bind a video to a VideoElementFacade
     *
     * @param {MediaStream} webRTCStream the MediaStream to bind
     * @param {Object} options
     * @param {Function} options.error Error callback
     * @param {Float} options.audioVolume The initial audioVolume
     * @param {Boolean} options.muted The initial mute state
     * @param {String} [options.fallbackText] The default fallbackText
     *
     * @return {Promise<undefined>}
     */
    async bindVideo(webRTCStream, { audioVolume, muted, fallbackText, _inject }) {
      logging.debug('bindVideo ', { webRTCStream });

      const oldVideoFacade = this._videoElementFacade;

      const tempContainer = document.createElement('div');

      if (this._cancelBind) {
        logging.debug('Cancelling last bindVideo request');
        this._cancelBind.cancel();
      }

      this._cancelBind = new Cancellation();
      const cancellation = this._cancelBind;

      this.once('destroy', () => cancellation.cancel());

      this._videoElementFacade = new WidgetView.VideoElementFacade({
        defaultAudioVolume: parseFloat(audioVolume, 100),
        fallbackText,
        fitMode: this._fitMode,
        _inject,
        muted,
      });

      const videoFacadeEvents = eventHelper(this._videoElementFacade);

      if (this._videoFacadeEvents) {
        logging.debug('Remove event listeners from old video facade');
        this._videoFacadeEvents.removeAll();
      }

      this._videoFacadeEvents = videoFacadeEvents;

      videoFacadeEvents.on('error', () => {
        this.trigger('error');
      });

      videoFacadeEvents.on('videoDimensionsChanged', (oldValue, newValue) => {
        this.trigger('videoDimensionsChanged', oldValue, newValue);
      });

      videoFacadeEvents.on('mediaStopped', (track) => {
        this.trigger('mediaStopped', track);
      });

      videoFacadeEvents.on('audioBlocked', () => this.trigger('audioBlocked'));
      videoFacadeEvents.on('audioUnblocked', () => this.trigger('audioUnblocked'));

      // Initialize the audio volume
      if (typeof audioVolume !== 'undefined') {
        try {
          this._videoElementFacade.setAudioVolume(audioVolume);
        } catch (e) {
          logging.warn(`bindVideo ${e}`);
        }
      }

      // makes the incoming audio streams take priority (will impact only FF OS for now)
      this._videoElementFacade.audioChannelType('telephony');

      if (!oldVideoFacade) {
        // @todo if using plugin, we might actually need to append it to the dom
        logging.debug('Appending the video facade');
        this._videoElementFacade.appendTo(this._widgetContainer);
      } else {
        this._videoElementFacade.appendTo(tempContainer);
      }

      try {
        await this._videoElementFacade.bindToStream(webRTCStream);
      } catch (err) {
        if (cancellation.isCanceled()) {
          logging.debug('Refusing to destroyVideo as bindVideo was cancelled');
          throw new CancellationError('CANCEL');
        } else {
          this.destroyVideo();
          throw err;
        }
      }

      if (!oldVideoFacade) {
        if (this._videoElementFacade.domElement()) {
          this.trigger('videoElementCreated', this._videoElementFacade.domElement());
        }

        videoFacadeEvents.on('videoElementCreated', (element) => {
          this.trigger('videoElementCreated', element);
        });
      }

      if (cancellation.isCanceled()) {
        logging.debug('bindVideo bailing due to cancellation');
        throw new CancellationError('CANCEL');
      }

      const whenVideoPlaying = waitForVideoToBePlaying(this._videoElementFacade, 5000);

      if (webRTCStream.getVideoTracks().length > 0) {
        logging.debug('Waiting for correct resolution');
        await waitForVideoResolution(this._videoElementFacade, 5000);
        logging.debug(`Resolution: ${this._videoElementFacade.videoWidth()}x${this._videoElementFacade.videoHeight()}`);
      }

      logging.debug('Waiting for video to be playing');
      await whenVideoPlaying;
      logging.debug('Video is playing');

      if (cancellation.isCanceled()) {
        logging.debug('bindVideo bailing due to cancellation');
        throw new CancellationError('CANCEL');
      }

      if (oldVideoFacade) {
        // overview of the transition
        // add new one to dom, give it a second
        // remove old one
        if (this._videoElementFacade.domElement()) {
          this.trigger('videoElementCreated', this._videoElementFacade.domElement());
        }

        videoFacadeEvents.on('videoElementCreated', (element) => {
          this.trigger('videoElementCreated', element);
        });

        logging.debug('Destroy the old video facade');
        oldVideoFacade.destroy();
        logging.debug('Insert the new video facade');
        this._videoElementFacade.appendTo(this._widgetContainer);
      }

      if (this._insertDefaultUI !== false) {
        OTHelpers.addClass(this._videoElementFacade.domElement(), 'OT_video-element');
      }
    }
    destroyVideo() {
      if (this._videoElementFacade) {
        this._videoElementFacade.destroy();
        this._videoElementFacade = null;
      }
    }
    video() {
      return this._videoElementFacade;
    }
    showPoster(showPoster) {
      if (showPoster === undefined) {
        return !OTHelpers.isDisplayNone(this._posterContainer);
      }
      this._showPoster = showPoster;
      OTHelpers[showPoster ? 'show' : 'hide'](this._posterContainer);
      return this.showPoster();
    }
    poster(src) {
      if (src === undefined) {
        return OTHelpers.css(this._posterContainer, 'backgroundImage');
      }
      this._poster = src;
      OTHelpers.css(this._posterContainer, 'backgroundImage', `url(${src})`);
      return this.poster();
    }
    loading(isLoading) {
      if (isLoading === undefined) {
        return this._loading;
      }
      this._loading = Boolean(isLoading);
      if (this._container) {
        this._container.classList[isLoading ? 'add' : 'remove']('OT_loading');
      }
      return this.loading();
    }
    audioOnly(isAudioOnly) {
      if (isAudioOnly === undefined) {
        return this._audioOnly;
      }
      this._audioOnly = isAudioOnly;
      if (this._container) {
        this._container.classList[isAudioOnly ? 'add' : 'remove']('OT_audio-only');
      }
      return this.audioOnly();
    }
    domId() {
      return this._container && this._container.getAttribute('id');
    }
    /** @return {HTMLVideoElement} */
    get videoElement() {
      return (this._videoElementFacade && this._videoElementFacade.domElement()) || undefined;
    }
    /**
     * The width of the video element in pixels
     * @return {Number}
     */
    get width() {
      return this.videoElement && this.videoElement.offsetWidth;
    }
    /**
     * The height of the video element in pixels
     * @return {Number}
     */
    get height() {
      return this.videoElement && this.videoElement.offsetHeight;
    }
  }

  // This is bound here so that it can be mocked in testing. Feels like a smell that's a symptom of
  // larger problems to me, but I'm just maintaining existing behaviour right now.
  WidgetView.VideoElementFacade = VideoElementFacade;

  return WidgetView;
}

module.exports = WidgetViewFactory;

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-use-before-define */

const logging = require('../helpers/log')('EnvironmentLoader');
const OTPlugin = require('../otplugin/otplugin.js');
const domState = require('../helpers/domState.js');
const eventNames = require('../helpers/eventNames');
const eventing = require('../helpers/eventing');
const Events = require('./events.js')();

// Helper to synchronise several startup tasks and then dispatch a unified
// 'envLoaded' event.

function EnvironmentLoader() {
  const environmentLoader = this;
  eventing(environmentLoader);

  // If the plugin is installed, then we should wait for it to be ready as well.
  let _pluginLoadAttemptComplete = OTPlugin.isSupported() ? OTPlugin.isReady() : true;

  function isReady() {
    return (
      domState.isDomLoaded() &&
      !domState.isDomUnloaded() &&
      _pluginLoadAttemptComplete
    );
  }

  function onLoaded() {
    if (isReady()) {
      environmentLoader.dispatchEvent(
        new Events.EnvLoadedEvent(eventNames.ENV_LOADED)
      );
    }
  }

  function onDomReady() {
    domState.whenUnloaded.then(onDomUnload);
    onLoaded();
  }

  function onDomUnload() {
    environmentLoader.dispatchEvent(
      new Events.EnvLoadedEvent(eventNames.ENV_UNLOADED)
    );
  }

  function onPluginReady(err) {
    // We mark the plugin as ready so as not to stall the environment
    // loader. In this case though, OTPlugin is not supported.
    _pluginLoadAttemptComplete = true;

    if (err) {
      // Just logging this as info because it is not necessarily an error
      logging.info('OTPlugin failed to load or was not installed:', err);
    }

    onLoaded();
  }

  domState.whenLoaded.then(onDomReady);

  // If the plugin should work on this platform then
  // see if it loads.
  if (OTPlugin.isSupported()) { OTPlugin.ready(onPluginReady); }

  this.onLoad = function (cb, context) {
    if (isReady()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(eventNames.ENV_LOADED, cb, context);
  };

  this.onUnload = function (cb, context) {
    if (this.isUnloaded()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(eventNames.ENV_UNLOADED, cb, context);
  };

  this.isUnloaded = function () {
    return domState.isDomUnloaded();
  };
}

module.exports = new EnvironmentLoader();

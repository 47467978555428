// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, one-var, no-underscore-dangle */
/* eslint-disable no-cond-assign,  max-len, no-void, prefer-const */

const eventing = require('../../../helpers/eventing');
const eventHelper = require('../../../helpers/eventHelper');
const destroyObj = require('../../../helpers/destroyObj');
const patchSrcObject = require('../../../helpers/patchSrcObject');

const env = require('../../../helpers/env');

function createDomVideoElement(fallbackText, muted) {
  const videoElement = document.createElement('video');
  patchSrcObject(videoElement);
  videoElement.autoplay = true;
  videoElement.playsinline = true;
  videoElement.innerHTML = fallbackText;

  // Safari on iOS requires setAttribute OPENTOK-37229
  videoElement.autoPlay = true;
  videoElement.setAttribute('autoplay', true);
  videoElement.playsinline = true;
  videoElement.setAttribute('playsinline', true);

  if (muted === true) {
    videoElement.muted = 'true';
  }

  return videoElement;
}

/**
 * NativeVideoElementWrapperFactory DI container
 *
 * @package
 * @param {any} [deps={}]
 * @return {typeof NativeVideoElementWrapper}
 */
function NativeVideoElementWrapperFactory(deps = {}) {
  const audioContextProvider = deps.audioContext || require('../../audio_context')();
  const canBeOrientatedMixin = deps.canBeOrientatedMixin || require('../can_be_oriented_mixin.js');
  /** @type {Document} */
  const document = deps.document || global.document;
  const listenForTracksEnded = deps.listenForTracksEnded || require('../listenForTracksEnded.js')();
  const createLog = deps.logging || require('../../../helpers/log');
  const OTHelpers = deps.OTHelpers || require('../../../common-js-helpers/OTHelpers.js');
  const videoElementErrorMap = deps.videoElementErrorMap || require('../videoElementErrorMap.js')();
  const WebAudioLevelSampler = deps.WebaudioAudioLevelSampler || require('../../audio_level_samplers/webaudio_audio_level_sampler');
  const windowMock = deps.global || global;

  let id = 1;

  /**
   * NativeVideoElementWrapper
   *
   * @package
   * @class
   * @param {Object} options
   * @param {Object} [options._inject] injected variables @todo move to DI
   * @param {Function} [options._inject.createVideoElement] function used to create video element
   * @param {Boolean} [options.muted] initial mute state
   * @param {String} [options.fallbackText] text to display when not supported
   * @param {Function} errorHandler callback for when there are errors
   * @param {Number} defaultAudioVolume the initial audio volume
   */

  class NativeVideoElementWrapper {
    /** @type {HTMLVideoElement|undefined} */
    _domElement;
    /** @type {number|undefined} */
    _blockedVolume;
    _mediaStoppedListener;
    _audioLevelSampler;
    _playInterrupts = 0;
    constructor(
      {
        _inject: {
          createVideoElement: _createVideoElement = createDomVideoElement,
        } = {},
        muted,
        fallbackText,
      },
      defaultAudioVolume
    ) {
      this.logging = createLog(`NativeVideoElementWrapper:${id}`);
      id += 1;
      eventing(this);
      this._defaultAudioVolume = defaultAudioVolume;

      let _videoElementMovedWarning = false;
      // / Private API

      // The video element pauses itself when it's reparented, this is
      // unfortunate. This function plays the video again and is triggered
      // on the pause event.
      const _playVideoOnPause = () => {
        if (!_videoElementMovedWarning) {
          this.logging.warn('Video element paused, auto-resuming. If you intended to do this, ' +
            'use publishVideo(false) or subscribeToVideo(false) instead.');
          _videoElementMovedWarning = true;
        }
        this._domElement.play();
      };

      this._domElement = _createVideoElement(fallbackText, muted);

      this.trigger('videoElementCreated', this._domElement);

      this._domElementEvents = eventHelper(this._domElement);
      this._domElementEvents.on('timeupdate', (...args) => this.trigger('timeupdate', ...args));
      this._domElementEvents.on('loadedmetadata', (...args) => this.trigger('loadedmetadata', ...args));

      const onError = (event) => {
        this.trigger('error', videoElementErrorMap(event.target.error));
      };

      this._domElementEvents.on('error', onError, false);
      this._domElementEvents.on('pause', _playVideoOnPause);

      this.on('destroyed', () => {
        this._domElementEvents.removeAll();
      });

      canBeOrientatedMixin(this, () => this._domElement);
    }
    whenTimeIncrements(callback, context) {
      this.once('timeupdate', () => {
        callback.call(context, this);
      });
    }
    /**
     * Get the underlying DOM element
     * @return {Element}
    */
    domElement() {
      return this._domElement;
    }
    videoWidth() {
      return this._domElement ?
        Number(this._domElement[`video${this.isRotated() ? 'Height' : 'Width'}`]) : 0;
    }
    videoHeight() {
      return this.domElement ?
        Number(this._domElement[`video${this.isRotated() ? 'Width' : 'Height'}`]) : 0;
    }
    aspectRatio() {
      return this.videoWidth() / this.videoHeight();
    }
    imgData() {
      const canvas = OTHelpers.createElement('canvas', {
        width: this.videoWidth(),
        height: this.videoHeight(),
        style: { display: 'none' },
      });
      document.body.appendChild(canvas);
      let imgData = null;
      try {
        canvas.getContext('2d').drawImage(this._domElement, 0, 0, canvas.width, canvas.height);
        imgData = canvas.toDataURL('image/png');
      } catch (err) {
        // Warning emitted for imgData === null below
      }
      OTHelpers.removeElement(canvas);
      if (imgData === null || imgData === 'data:,') {
        // 'data:,' is sometimes returned by canvas.toDataURL when one cannot be
        // generated.
        this.logging.warn('Cannot get image data yet');
        return null;
      }
      return imgData.replace('data:image/png;base64,', '').trim();
    }
    // Append the Video DOM element to a parent node
    appendTo(parentDomElement) {
      parentDomElement.appendChild(this._domElement);
      return this;
    }
    isAudioBlocked() {
      return this._blockedVolume !== undefined;
    }
    async unblockAudio() {
      if (!this.isAudioBlocked()) {
        this.logging.warn('Unexpected call to unblockAudio() without blocked audio');
        return;
      }

      const blockedVolume = this._blockedVolume;
      this._blockedVolume = undefined;

      this.setAudioVolume(blockedVolume);

      try {
        await this.play();
      } catch (err) {
        this._blockedVolume = blockedVolume;
        this._domElement.muted = true;

        throw err;
      }

      this.trigger('audioUnblocked');
    }
    // useful for some browsers (old chrome) that show black when a peer connection
    // is renegotiated
    async rebind() {
      if (!this._domElement) {
        throw new Error('Can\'t rebind because _domElement no longer exists');
      }
      this._playInterrupts++;
      this._domElement.srcObject = this._domElement.srcObject;
    }
    _createAudioLevelSampler() {
      this._removeAudioLevelSampler();
      if (this._stream.getAudioTracks().length > 0) {
        try {
          this._audioContext = audioContextProvider();
        } catch (e) {
          this.logging.warn('Failed to get AudioContext(), audio level visualisation will not work', e);
        }
        if (this._audioContext) {
          this._audioLevelSampler = new WebAudioLevelSampler(this._audioContext);
          this._audioLevelSampler.webRTCStream(this._stream);
        }
      }
    }
    _removeAudioLevelSampler() {
      if (this._audioContext) {
        delete this._audioContext;
        delete this._audioLevelSampler;
      }
    }
    async play() {
      const playInterruptsOnStart = this._playInterrupts;

      try {
        return await this._domElement.play();
      } catch (err) {
        if (this._playInterrupts > playInterruptsOnStart) {
          // Play was interrupted - ignore error and try again
          return this.play();
        }

        throw err;
      }
    }
    async bindToStream(webRtcStream) {
      if (!this._domElement) {
        throw new Error('Can\'t bind because _domElement no longer exists');
      }

      this._stream = webRtcStream;
      this._domElement.srcObject = webRtcStream;

      (async () => {
        try {
          await this.play();
        } catch (err) {
          if (webRtcStream.getAudioTracks().length === 0) {
            throw err;
          }

          this._blockedVolume = this.getAudioVolume();
          this._domElement.muted = true;

          if (env.name === 'Safari') {
            // Safari can have issues recognizing .play is allowed due to .muted without a delay
            // in between.
            await new Promise(resolve => setTimeout(resolve));
          }

          await this.play();
          this.trigger('audioBlocked');
        }
      })().catch((err) => {
        this.logging.debug('.play() failed: ', err);
      });

      const currentVideoSize = {
        width: this._domElement.videoWidth,
        height: this._domElement.videoHeight,
      };
      this.trigger('videoDimensionsChanged', { ...currentVideoSize }, { ...currentVideoSize });
      this._domElementEvents.on('resize', () => {
        const { videoWidth: width, videoHeight: height } = this._domElement;
        const widthChanged = width !== currentVideoSize.width;
        const heightChanged = height !== currentVideoSize.height;
        if (widthChanged || heightChanged) {
          this.trigger('videoDimensionsChanged', { ...currentVideoSize }, { width, height });
          currentVideoSize.width = width;
          currentVideoSize.height = height;
        }
      });
      const onAllEnded = () => {
        this._mediaStoppedListener.stop();
        if (this._domElement) {
          this._domElement.onended = null;
        }
        this.trigger('mediaStopped');
      };
      const onSingleEnded = (track) => {
        this.trigger('mediaStopped', track);
      };

      // OPENTOK-22428: Firefox doesn't emit the ended event on the webRtcStream when the user
      // stops sharing their camera, but we do get the ended event on the video element.
      this._domElement.onended = () => onAllEnded();

      this._mediaStoppedListener = listenForTracksEnded(webRtcStream, onAllEnded, onSingleEnded);
      this._createAudioLevelSampler();
      return undefined;
    }
    // Unbind the currently bound stream from the video element.
    unbindStream() {
      if (this._domElement) {
        this._domElement.srcObject = null;
      }
      this._removeAudioLevelSampler();
      return this;
    }
    setAudioVolume(rawValue) {
      if (this.isAudioBlocked()) {
        this._blockedVolume = rawValue;
        return;
      }

      const value = parseFloat(rawValue) / 100;

      if (this._domElement) {
        this._domElement.volume = value;
        // In Safari on iOS setting the volume does not work but setting muted does so at
        // least this will mute when you click the mute icon
        // https://bugs.webkit.org/show_bug.cgi?id=176045
        if (value === 0) {
          this._domElement.muted = true;
        } else {
          this._domElement.muted = false;
        }
      }
    }
    getAudioVolume() {
      if (this.isAudioBlocked()) {
        return this._blockedVolume;
      }

      // Return the actual volume of the DOM element
      if (this._domElement) {
        return this._domElement.muted ? 0 : this._domElement.volume * 100;
      }
      return this._defaultAudioVolume;
    }
    // see https://wiki.mozilla.org/WebAPI/AudioChannels
    // The audioChannelType is currently only available in Firefox. This property returns
    // "unknown" in other browser. The related HTML tag attribute is "mozaudiochannel"
    audioChannelType(type) {
      if (type !== void 0) {
        this._domElement.mozAudioChannelType = type;
      }
      if ('mozAudioChannelType' in this._domElement) {
        return this._domElement.mozAudioChannelType;
      }
      return 'unknown';
    }
    getAudioInputLevel() {
      return this._audioLevelSampler.sample();
    }
    refreshTracks() {
      if (this._mediaStoppedListener) {
        this._mediaStoppedListener.refresh();
      }
      this._createAudioLevelSampler();
      if (this._stream && this._stream.getTracks().length > 0) {
        this._playInterrupts++;
        this._domElement.srcObject = new windowMock.MediaStream(this._stream.getTracks());
      }
    }
    destroy() {
      // Unbind events first, otherwise 'pause' will trigger when the
      // video element is removed from the DOM.
      if (this._mediaStoppedListener) {
        this._mediaStoppedListener.stop();
      }
      this.logging.debug('removing domElementEvents');
      this._domElementEvents.removeAll();
      this.unbindStream();
      if (this._domElement) {
        OTHelpers.removeElement(this._domElement);
        this._domElement = null;
      }
      this.trigger('destroyed');
      destroyObj('NativeVideoElementWrapper', this);
      return void 0;
    }
  }
  return NativeVideoElementWrapper;
}

module.exports = NativeVideoElementWrapperFactory;
